import React from 'react';
import { version } from '../../../package.json';
function Footer(props) {


   return (
      <div>
         <footer className="footer footer-default">
            <div className="container">
               <div className="copyright float-right">
                  <div className="row mb-5 text-center text-md-left">
                     <div className="col-md-5 col-lg-4 mb-3">
                        Avisos
                     <p></p>
                        <div id="secondary" className="widget-area" role="complementary">
                           <ul className="tc_upcoming_events_ul">
                             <li id="tc_upcoming_event"><a href="https://blog.reservarcannabis.com/dos-nuevas-variedades-de-cannabis-salen-al-mercado/">Dos nuevas variedades de cannabis aqui!</a></li>
                              <li id="tc_upcoming_event"><a href="https://blog.reservarcannabis.com/nuevas-farmacias/">Hay nuevas farmacias</a></li>
                              <li id="tc_upcoming_event"><a href="https://blog.reservarcannabis.com/nuevo-precio-cannabis/">Nuevo precio del cannabis</a></li>
                              <li id="tc_upcoming_event"><a href="https://blog.reservarcannabis.com/buscando-un-club-canabico/">Buscando un club cannabico?</a></li>
                              <li id="tc_upcoming_event"><a href="https://blog.reservarcannabis.com/ayudenos-a-mejorar-nuestro-servicio/">Ayúdenos a mejorar!</a></li>
                              <li id="tc_upcoming_event"><a href="https://blog.reservarcannabis.com/el-algoritmo-del-cannabis-como-surgio-el-reservar-cannabis/">El algoritmo del cannabis: Como surgió el Reservar Cannabis?</a></li>
                              <li id="tc_upcoming_event"><a href="https://blog.reservarcannabis.com/como-es-el-cannabis-de-el-estado/">Como es el Cannabis de el Estado?</a></li>
                              <li id="tc_upcoming_event"><a href="https://blog.reservarcannabis.com/preguntas-frecuentes-realizadas-en-reservar-cannabis-com/">Preguntas frecuentes realizadas en Reservar Cannabis.com</a></li>
                           </ul>
                           <div className="tc-clearfix"></div>
                        </div>
                     </div>
                     <div className="col-md-3 offset-lg-2 col-lg-2 pt-2">
                        <h5>Applicación</h5>
                        <ul className="nav-footer">
                           <li className="nav-item"><a className="nav-link" href="/#numeros">Hay stock?</a></li>
                           <li className="nav-item"><a className="nav-link" href="/#como">Como Funciona?</a></li>
                           <li className="nav-item"><a className="nav-link" href="https://blog.reservarcannabis.com/reglas-y-condiciones-de-uso/">Reglas y Condiciones de Uso</a></li>
                        </ul>
                     </div>
                     <div className="col-md-3 col-lg-2 pt-2">
                        <h5>Soporte</h5>
                        <ul className="nav-footer">
                           <li className="nav-item"><a className="nav-link" href="https://reservarcannabis.com/cancelar-reserva/">Cancelar Reserva</a></li>
                           <li className="nav-item"><a className="nav-link" href="https://blog.reservarcannabis.com/politica-de-privacidad/">Politica de Privacidad</a></li>
                           <li className="nav-item"><a className="nav-link" href="https://blog.reservarcannabis.com//contacto">Enviá tu Opinion</a></li>
                           <li className="nav-item"><a className="nav-link" href="https://blog.reservarcannabis.com//contacto">Notificar Error</a></li>
                        </ul>
                     </div>
                     <div className="col-md-3 col-lg-2 pt-2">
                        <h5>Ayuda</h5>
                        <ul className="nav-footer">
                        <li className="nav-item"><a className="nav-link" href="https://blog.reservarcannabis.com/preguntas-frecuentes/" rel="noopener noreferrer">Preguntas Frecuentes</a></li>
                        <li className="nav-item"><a className="nav-link" href="https://farmacias.reservarcannabis.com/locales-registro/" target="_blank" rel="noopener noreferrer">Locales para el registro</a></li>
                           <li className="nav-item"><a className="nav-link" href="https://budmaps.com.br/es/sugestoes/farmacias-maconha/" target="_blank" rel="noopener noreferrer">Ubicación Farmacias</a></li>
                           <li><a className="nav-link" href="https://www.clubescannabicos.com/" target="_blank" rel="noopener noreferrer">Clubes Cannábicos</a></li>
                           <li className="nav-item"><a className="nav-link" href="https://www.ircca.gub.uy/" target="_blank" rel="noopener noreferrer">IRRCA</a></li>
                        </ul>
                     </div>
                     <nav className="float-left">
                        <ul>
                           <li>
                              <a href="https://cannabis-app.com">
                                 © Reservar Cannabis.com Es una aplicacíon Registrado y Patenteado por Cannabis-APP.com - Version {version}
                              </a>
                           </li>
                        </ul>
                     </nav>
                  </div>
               </div>
            </div>
         </footer>
      </div>
   );
}
export default (Footer);
