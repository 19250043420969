import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import axios from 'axios';
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import { logoutUser } from "../../../../actions";
import MenuAppBar from '../Appbar';
import Copyright from '../Copyright';
import useStyles from '../../Styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import { myFirebase, db } from "../../../../firebase/firebase";
import format from 'date-fns/format';
import { IconButton, Paper } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import TabelaReservas from '../TabelaReservas';
import BCReader from './BCReader';
import TableFirstResult from './TableFirstResult';

const BarCodeScreen = ({ user }) => {
  const fbUser = myFirebase.auth().currentUser.uid;
  const sendEmailPermission = true;
  const classes = useStyles();
  const [farmaciaNome, setFarmaciaNome] = useState('');
  const [rows, setRows] = useState([]);
  const [background, setBackground] = useState('#eeeeee');
  const [agendamento, setAgendamento] = useState();
  let code = null;

  let [alert, setAlert] = useState({
    severity: 'error',
    title: 'Teste',
    message: 'Teste1',
    visiblity: false
  });

  useEffect(() => {
    // setLoading(true);
    db.collection("reservas")
      .where("farmaciaID", "==", fbUser)
      .where("status", "==", 'retirado')
      .orderBy("horacadastro", "desc")
      .limit(1)
      .onSnapshot(function (doc) {
        var datas = [];
        doc.docs.forEach(function (documents) {
          // console.log(documents.data());
          datas.push(documents);
        });
        handleRows(datas);

      });

  }, []);

  useEffect(() => {
    console.log('USEEFFECT => ');
    setTimeout(() => {
      setBackground('#eeeeee');
      setAlert({ ...alert, visiblity: false });
    }, 10000);
  }, [background]);

  const handleRows = (data) => {
    const tempDoc = data.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });
    console.log('TEMPDOC => ', tempDoc);
    setRows(tempDoc);
  };

  useEffect(() => {

    let docRef = db.collection("usuarios").doc(fbUser);

    docRef.get().then(function (doc) {
      if (doc.exists) {
        setFarmaciaNome(doc.data().nome);
      }
    }).catch(function (error) {
      console.log({ "Error :": error });
    });

  }, []);

  const handleScan = async (data) => {
    setAlert({ ...alert, visiblity: false });
    if (data) {
      const response = await db.collection("reservas").doc(data).get()
        .then(async (querySnapshot) => {
          console.log("Esta reserva es inválida");

          if (!querySnapshot.exists) {
            setAlert({
              severity: 'error',
              title: 'Problema en la Reserva',
              message: 'Esta reserva es inválida.',
              visiblity: true
            });
            setBackground('#f44336');

          } else {
            if (querySnapshot.data().farmaciaID === fbUser) {

              if (querySnapshot.data().status === 'reservado') {
                const diaRetirada = querySnapshot.data().dataRetirar.toDate().getUTCDate();
                const mesRetirada = querySnapshot.data().dataRetirar.toDate().getUTCMonth();
                const anoRetirada = querySnapshot.data().dataRetirar.toDate().getUTCFullYear();

                const diadeReserva = querySnapshot.data().horacadastro.toDate().getUTCDate();
                const mesdeReserva = querySnapshot.data().horacadastro.toDate().getUTCMonth();
                const anodeReserva = querySnapshot.data().horacadastro.toDate().getUTCFullYear();

                const dataAgora = new Date();
                const diaAgora = dataAgora.getDate();
                const mesAgora = dataAgora.getUTCMonth();
                const anoAgora = dataAgora.getUTCFullYear();
                const horaRetirada = new Date();
                if (mesRetirada === mesAgora && anoRetirada === anoAgora && diaAgora === diaRetirada) {
                  await db.collection("reservas").doc(data).update({
                    status: "retirado",
                    horaretirada: horaRetirada
                  });
                  triggerUpdateAgendamento(querySnapshot.data());

                  console.log("BAIXOU");
                  setBackground('yellowgreen');
                  setAlert({
                    severity: 'success',
                    title: 'Retirada',
                    message: 'Listo, ahora reserva esta escaneada y marcada como retirada!.',
                    visiblity: true
                  });
                  const obj = { email: querySnapshot.data().email, type: 'read', title: 'Reserva Escaneada/Usada', nome: farmaciaNome, hora: format(horaRetirada, "HH:mm") };
                  sendEmail(obj);
                } else if (mesRetirada === mesAgora && anoRetirada === anoAgora && diaAgora <= diaRetirada) {
                  await db.collection("reservas").doc(data).update({
                    status: "retirado",
                    horaretirada: horaRetirada
                  });
                  triggerUpdateAgendamento(querySnapshot.data());

                  console.log("BAIXOU");
                  setBackground('yellowgreen');
                  setAlert({
                    severity: 'success',
                    title: 'Retirada',
                    message: 'Listo, ahora reserva esta escaneada y marcada como retirada!.',
                    visiblity: true
                  });
                  const obj = { email: data.email, type: 'read', title: 'Reserva Escaneada/Usada', nome: farmaciaNome, hora: format(horaRetirada, "HH:mm") };
                  sendEmail(obj);
                } else {
                  console.log("Esta reserva es de otra fecha");
                  setBackground('#f44336');
                  setAlert({
                    severity: 'error',
                    title: 'Reserva inválida',
                    message: 'Esta reserva es de otra fecha.',
                    visiblity: true
                  });
                }
              } else {
                console.log("Reserva usada");
                setBackground('#f44336');
                if (querySnapshot.data().status === 'retirado') {
                  setAlert({
                    severity: 'error',
                    title: 'Reserva usada!',
                    message: 'Esta reserva es inválida.',
                    visiblity: true
                  });
                } else {
                  console.log("Esta reserva ha sido cancelada anteriormente");
                  setAlert({
                    severity: 'error',
                    title: 'Problema en la Reserva',
                    message: 'Esta reserva ha sido cancelada anteriormente.',
                    visiblity: true
                  });
                }
                setBackground('#f44336');
              }
            } else {
              console.log("Esta reserva es para otra farmácia.");
              setBackground('#f44336');
              setAlert({
                severity: 'error',
                title: 'Problema en la Reserva',
                message: 'Esta reserva es para otra farmácia.',
                visiblity: true
              });
              setBackground('#f44336');
            }
          }
        });
      code = null;
      document.getElementById("barcode").value = '';
    }
  };
  const handleError = err => {
    console.error({ "SCAN ERROR": err });
  };

  const triggerUpdateAgendamento = async (data) => {
    try {
      const agendamentoRef = db
        .collection("usuarios")
        .doc(fbUser)
        .collection("agendamentos")
        .doc(data.reservaID);

      const agendamentoDoc = await agendamentoRef.get();
      const agendamentoData = agendamentoDoc.data();

      const products = agendamentoData.products;

      const filteredProducts = products.map(product => {
        const matchingReservaProduct = data.products.find(reservaProduct => reservaProduct.id === product.id);

        if (matchingReservaProduct) {
          const qtdeProduct = parseInt(product.qtde);
          const qtdeReserva = parseInt(matchingReservaProduct.qtde);

          if (qtdeProduct > 0 && qtdeReserva > 0) {
            product.qtde = qtdeProduct - qtdeReserva;
          }
        }

        return product;
      });

      await agendamentoRef.set({ "uuid": uuid() }, { merge: true });

      const agendamentoSnapshot = await agendamentoRef.get();
      const updatedAgendamentoData = agendamentoSnapshot.data();

      setAgendamento(updatedAgendamentoData);
    } catch (error) {
      console.error("Error updating agendamento", error);
    }
  };

  const sendEmail = (data) => {
    if (sendEmailPermission) {
      axios({
        method: "POST",
        url: "https://us-central1-reservar-cannabis-uru.cloudfunctions.net/widgets/statusReservaEmail",
        data: data
      }).then((result) => {
        console.log(result);
      }).catch((err) => {
        console.log(err);
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    code = e?.target[0]?.value;
    if (code) {
      await handleScan(code.trim());
      code = null;
    }
  };

  const AlertMessage = ({ alert }) => (
    <Alert severity={alert.severity}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => {
            setAlert({ ...alert, visiblity: false });
          }}
        >
          <Close fontSize="inherit" />
        </IconButton>
      }
    >
      <AlertTitle>{alert.title}</AlertTitle>
      {alert.message}
    </Alert>
  );



  return (
    <div>
      <MenuAppBar />
      <div style={{ background: background, paddingTop: 60, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <h1 style={{ textAlign: 'center', padding: 50 }} className={classes.headerTitle}>Escaneá tu reserva</h1>
        {alert.visiblity && <AlertMessage alert={alert} />}
      </div>

      <div style={{
        background: 'transparent',
        height: '70vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around'
      }}>
        <div style={{ background: 'transparent', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}>
          <Grid item xs={6}>
            <div style={{ background: 'transparent', display: 'flex', justifyContent: 'center' }}>
              <div style={{ width: '100%' }} className={classes.scannerBox}>
                <BCReader label={''} onSubmit={handleSubmit} />
              </div>
            </div>
          </Grid>
        </div>
        <div style={{ background: 'transparent' }}>
          <TableFirstResult rows={rows} />
        </div>
      </div>
      <div style={{ background: 'transparent', marginTop: 30 }}>
        <Grid item xs={12} className={classes.gridCustom}>
          <Paper className={classes.paper}>
            <h5 style={{ fontWeight: '500', fontSize: 18 }}>
              Buscar una reserva
            </h5>
            <div style={{ background: 'transparent', }}>
              <TabelaReservas limit={3000} />
            </div>
          </Paper>
        </Grid>
      </div>
      <div style={{ background: 'transparent', }}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div style={{ background: 'transparent' }}>
              <Copyright />
            </div>
          </Paper>
        </Grid>
      </div>
    </div>
  );

};

function mapStateToProps(state) {
  console.log('STATE => ', state);
  return {
    isLoggingOut: state.auth.isLoggingOut,
    logoutError: state.auth.logoutError,
    user: state.auth.user
  };
}

const mapDispatchToProps = dispatch => ({
  Deslogar() {
    dispatch(logoutUser());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(BarCodeScreen);
