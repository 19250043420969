import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import { db, myFirebase } from "../firebase/firebase";
import { logoutUser } from "../actions";
import CssBaseline from '@material-ui/core/CssBaseline';
import MenuAppBar from './dashboard/components/Appbar';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import '../css/styles.css';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function EnhancedTable(props) {

  const classes = useStyles();
  const farmaciaID = myFirebase.auth().currentUser.uid;
  const [list, setList] = useState([]);
  const [nroEmails, setNroEmails] = useState(0);
  const [aviso, setAviso] = useState([]);
  const [avisoID, setAvisoID] = useState(0);
  const [farmaciaData, setFarmaciaData] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogDescription, setDialogDescription] = useState('');
  const [open, setOpen] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [disabledRadio, setDisabledRadio] = useState(false);
  const [showRadioAviso, setShowRadioAviso] = useState(false);
  const [semResultado, setSemResultado] = useState(false);
  const [dataIniRetirar, setDataIniRetirar] = useState('');
  const scrollToSubmit = useRef();
  const scrollToRadio = useRef();
  const [invalidDateMessage, setInvalidDateMessage] = useState('');

  useEffect(() => {
    db.collection("usuarios")
      .doc(farmaciaID)
      .get().then(function (doc) {
        // console.log(doc.data());
        if (!doc.empty) {
          let obj = {
            farmaciaID: doc.id,
            farmaciaNome: doc.data().nome,
            farmaciaLogo: doc.data().logo,
            farmaciaRua: doc.data().endereco.rua,
            farmaciaCidade: doc.data().endereco.cidade
          };
          setFarmaciaData(obj);
        }
      });
  }, [farmaciaID]);

  function getReservas(date) {
    const diaHoje = new Date(date);
    const diaAmanha = new Date(date);
    diaHoje.setHours(0, 0, 0, 0);
    diaAmanha.setHours(0, 0, 0, 0);
    diaHoje.setDate(diaHoje.getDate() - 1);
    diaAmanha.setDate(diaAmanha.getDate() + 1);

    db.collection('reservas')
      .where("farmaciaID", "==", farmaciaID)
      .where("horacadastro", ">=", diaHoje)
      .where("horacadastro", "<=", diaAmanha)
      .onSnapshot((querySnapshot) => {
        let docs = querySnapshot.docs;
        let obj = [];
        let dataIniRetirar = '';
        docs.forEach(function (d) {
          obj.push(d.data().email);
          dataIniRetirar = format(new Date(d.data().dataRetirar.seconds * 1000), 'dd/MM/yyyy');
        });
        if (obj.length) {
          setDataIniRetirar(dataIniRetirar);
          setList(obj);
          setNroEmails(obj.length);
          setSemResultado(false)
          setShowRadioAviso(true);
        } else {
          resetReservas();
          setSemResultado(true);
        }
      });

  }

  function resetReservas() {
    setList([]);
    setDisabledSubmit(true);
    setDisabledRadio(true);
    setShowRadioAviso(false);
    setNroEmails(0);

  }

  function chunk(array, size) {
    const chunked_arr = [];
    for (let i = 0; i < array.length; i++) {
      const last = chunked_arr[chunked_arr.length - 1];
      if (!last || last.length === size) {
        chunked_arr.push([array[i]]);
      } else {
        last.push(array[i]);
      }
    }
    return chunked_arr;
  }

  const handleSendEmail = async () => {
    setOpen(true);
    setDisabledSubmit(true);
    setDisabledRadio(true);
    let chunked = chunk(list, 99);
    const lista = chunked.map(e => {
      return e.join(', ');
    })
    sendEmail(lista);

  };

  const sendEmail = (emails) => {
    // console.log('EMAILS => ', emails);
    // return
    axios({
      method: "POST",
      url: "https://us-central1-reservar-cannabis-uru.cloudfunctions.net/widgets/inconvenientEmail",
      data: { emails: emails, aviso: aviso }
    }).then((result) => {

      // console.log(result);
      setOpen(false);
      setDisabledSubmit(false);
      setDialogTitle("Listo!");
      setDialogDescription("Notificamos todas las personas con el informe que elegiste. " + result.data[0].message);
      setOpenDialog(true);
      resetReservas();

    }).catch((err) => {
      setOpen(false);
      setDisabledSubmit(false);
      setDialogTitle("Error");
      setDialogDescription(err.response.data.message);
      setOpenDialog(true);

    })
  };


  const hoje = new Date();
  const [selectedDate, setSelectedDate] = useState(hoje);

  const handleDateChange = (date) => {
    if (isValid(date)) {
      setSelectedDate(date);
      setDisabledSubmit(true);
      setDisabledRadio(false);

      setList([]);
      getReservas(date);
      setTimeout(() => {
        scrollToRadio.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 1000);
    }
    // window.scrollTo({ behavior: 'smooth', top: scrollToRadio.current.offsetTop })
  };

  const listAvisos = [
    { value: 1, title: 'Sistema Caído', desc: '' },
    { value: 2, title: 'Sistema Volvió', desc: '' },
    { value: 3, title: 'Feriado', desc: '' },
    { value: 4, title: 'Farmacia Cerrada', desc: '' },
    { value: 5, title: 'Interromper Entrega', desc: '' },
    { value: 6, title: 'Interromper Entrega/Cancelar las reservas', desc: '' },
    { value: 7, title: 'Correr el dia de Entrega para el Lunes', desc: '' }
  ];

  function handleRadioChange(event) {
    setAvisoID(event.target.value);
    let option = parseInt(event.target.value);

    let resultado = listAvisos.filter(listAviso => {
      return listAviso.value === option;
    });
    resultado[0].desc = document.getElementById(`aviso_${option}`).innerHTML;
    resultado[0].logo = farmaciaData.farmaciaLogo;
    resultado[0].nome = farmaciaData.farmaciaNome;
    resultado[0].rua = farmaciaData.farmaciaRua;
    resultado[0].cidade = farmaciaData.farmaciaCidade;

    console.log(resultado[0]);
    setAviso(resultado[0]);
    setDisabledSubmit(false);
    scrollToSubmit.current.scrollIntoView({ behavior: 'smooth' });
  }

  function handleCloseDialog() {
    setOpenDialog(false);
    window.location.reload(false);
  }

  return (

    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="primary" />
      </Backdrop>
      <CssBaseline />
      <MenuAppBar />
      <main className={classes.content}>

        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>

          <Grid container spacing={2} direction="row">

            <Grid item xs align="center">

              <h5 style={{ paddingTop: 80, textAlign: "center" }}> Elija la fecha de reserva que estas teniendo algún inconveniente:</h5>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label=""
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  autoOk={true}
                  onError={(error, value) => { error ? setInvalidDateMessage('Fecha inválida') : setInvalidDateMessage('') }}
                  invalidDateMessage={invalidDateMessage}
                  helperText={invalidDateMessage}

                />
              </MuiPickersUtilsProvider>
              <p>(Ejemplo para problemas del martes, elija la fecha del lunes)</p>
              <br />
              <div ref={scrollToRadio}>
                {nroEmails > 0 &&
                  <div className={nroEmails > 0 ? 'fadeIn' : 'fadeOut'} style={{ marginTop: 30, background: '#dbfbd1', padding: 20, borderRadius: 5 }}>
                    <h5>Enviaremos un correo electrónico a las {nroEmails} reservas para este día</h5>
                  </div>
                }
              </div>
              {semResultado &&
                <div style={{ marginTop: 30, background: '#fdf6c4', padding: 20, borderRadius: 5 }}>
                  <h5>No se encontraron reservas para esta fecha.</h5>
                </div>
              }
            </Grid>


            <Grid item xs={12} >

              {showRadioAviso &&
                <div className={showRadioAviso ? 'fadeIn' : 'fadeOut'}>
                  <h5 style={{ padding: 10, textAlign: "center" }}>Que deseas avisar:</h5>

                  <FormControl component="fieldset" style={{ paddingTop: 0, paddingRight: 30, paddingBottom: 30, paddingLeft: 20 }}>

                    <RadioGroup aria-label="motivo" name="motivo" value={avisoID} onChange={handleRadioChange}>
                      <FormControlLabel className="labelRadioBold" disabled={disabledRadio} value="1" control={<Radio color="primary" />} label="Sistema Caído" />
                      <p id='aviso_1'>Informamos que el sistema del IRCCA se encuentra con dificultades técnicas y avisamos por este mail cuando volver.</p>

                      <FormControlLabel className="labelRadioBold" disabled={disabledRadio} value="2" control={<Radio color="primary" />} label="Sistema Volvió" />
                      <p id='aviso_2'>El sistema de dispensación volvió, te esperamos. <br /> Att, {farmaciaData.farmaciaNome}.</p>

                      <FormControlLabel className="labelRadioBold" disabled={disabledRadio} value="3" control={<Radio color="primary" />} label="Feriado" style={{ fontWeight: "bold" }} />
                      <p id='aviso_3'>Avisamos que por el tema del próximo feriado la {farmaciaData.farmaciaNome} - ha cambiado los horários de atención. Aconsejamos que mires el dia y horario que tienes en su casilla de email.</p>

                      <FormControlLabel className="labelRadioBold" disabled={disabledRadio} value="4" control={<Radio color="primary" />} label="Farmacia Cerrada" />
                      <p id='aviso_4'>Avisamos que la farmacia {farmaciaData.farmaciaNome} estará cerrada {dataIniRetirar}. No te preocupes, podrás utilizar su reserva en el dia seguinte.</p>

                      <FormControlLabel className="labelRadioBold" disabled={disabledRadio} value="5" control={<Radio color="primary" />} label="Interromper Entrega" />
                      <p id='aviso_5'>Interrompemos las entregas en el dia de su reserva por problemas tecnicos. Su reserva ha sido cancelada. Pedimos disculpas por la molestia.
                  <br /> Gracias por su comprensión!<br />
                  Att, ReservarCannabis.com</p>

                      <FormControlLabel className="labelRadioBold" disabled={disabledRadio} value="6" control={<Radio color="primary" />} label="Interromper Entrega/Cancelar las reservas" />
                      <p id='aviso_6'>Pedimos disculpas por la molestia pero la farmacia tuvo un inconveniente y las reservas ha sido canceladas. Si seguis interesado en comprar cannabis debes entrar en el sítio y hacer otra reserva.</p>

                      <FormControlLabel className="labelRadioBold" disabled={disabledRadio} value="7" control={<Radio color="primary" />} label="Correr el dia de Entrega para el Lunes" />
                      <p id='aviso_7'>Estimado(a),<br />
                  La central del IRCCA esta con problemas técnicos están por solucionarlo.<br />
                  Mientras tanto y para no entorpecer la entrega en el día de mañana,
                  vamos a correr la distribución un día {dataIniRetirar} mientras damos tiempo a la solución.
                  No te preocupes, ahora podes usar su reserva en el dia seguinte,
                          en el mismo horario de atención de la farmácia</p>
                    </RadioGroup>

                  </FormControl>
                </div>
              }
            </Grid>


            <Grid item xs align="center" ref={scrollToSubmit}>
              <div style={{ marginBottom: 30 }}>
                <small id="emailHelp" className="form-text text-muted">&nbsp;</small>
                <button onClick={handleSendEmail} className="btn btn-primary" disabled={disabledSubmit}>Enviar mail</button>
              </div>
            </Grid>

          </Grid>
          <Dialog
            open={openDialog}
            onClose={() => handleCloseDialog()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {dialogDescription}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleCloseDialog()} color="primary" autoFocus>
                Cerrar
          					</Button>
            </DialogActions>
          </Dialog>
        </Container>
      </main>

    </div>


  );
}
function mapStateToProps(state) {
  return {
    isLoggingOut: state.auth.isLoggingOut,
    logoutError: state.auth.logoutError
  };
}

const mapDispatchToProps = dispatch => ({
  Deslogar() {
    dispatch(logoutUser());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(EnhancedTable);
