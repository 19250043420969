import React from 'react';
import { Card, CardActionArea, CardActions, CardContent, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/styles';
import { ColorLens, Delete, Edit } from '@mui/icons-material';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        width: 200,
        margin: 10,
        borderLeftWidth: 5,
        borderLeftStyle: 'inset'
    },
    media: {
        height: 5,
    },
    actions: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#e9e9e9'
    }
});

const ProductCard = ({ item, onEdit, onDelete, noActions = true }) => {
    const classes = useStyles();

    return (
        <Card className={classes.root} style={{ borderLeftColor: item.color }}>

            <CardContent>
                <Typography gutterBottom variant="h5" component="h3">
                    {item.name}
                </Typography>
                <Typography variant="body1" color="textSecondary" component="p">
                    {item.description}
                </Typography>
                <Divider light />
                <Typography variant="body2" color="textSecondary" component="p">
                    Total: {item.total}
                </Typography>
                <Divider light />
                <Typography variant="body2" color="textSecondary" component="p">
                    Mín: {item.minimun} | Máx: {item.maximun}
                </Typography>
            </CardContent>
            {noActions && (
                <CardActions className={classes.actions}>
                    <IconButton edge="start" aria-label="edit" onClick={() => onEdit(item)}>
                        <Edit color='primary' />
                    </IconButton>
                    <IconButton edge="end" aria-label="delete" onClick={() => onDelete(item.id)}>
                        <Delete color='error' />
                    </IconButton>
                </CardActions>
            )}
        </Card>
    );
};
export default ProductCard;