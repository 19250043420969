import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Footer from './parts/footer';
import Header from './parts/header';
import TextField from '@material-ui/core/TextField';
import ReCAPTCHA from 'react-google-recaptcha';

import { db, analytics } from '../firebase/firebase';
import firebase from 'firebase';
import '../css/material-kit.css?v=2020';
import Alert from '@material-ui/lab/Alert';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import QRGenerator from './QRGenerator';
import html2canvas from 'html2canvas';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { FormatDateQRCode } from './dashboard/components/Helpers';
import useEmailAutocomplete from 'use-email-autocomplete';
import '../css/styles.css';
import { Box, Grid, Typography } from '@material-ui/core';
import { Add, Remove } from '@mui/icons-material';
import { version, version_app } from '../../package.json';
import axios from 'axios';

export const EmailTextField = ({ onChange, ...props }) => {
	const {
		email,
		onChange: handleEmailChange,
		bind,
	} = useEmailAutocomplete({
		domains: ['vera.com.uy', 'antel.com.uy'],
		validation: true,
	});

	const handleChange = (e) => {
		handleEmailChange(e);
		onChange && onChange(email.address);
	};

	return <TextField {...bind} {...props} onChange={handleChange} />;
};

const PreencherReserva = ({ location }) => {
	const history = useHistory();
	const farmaciaID = location.state.idfarmacia;
	const agendamentoID = location.state.agendamentoID;
	const farmaciaNome = location.state.farmaciaNome;
	const iframe = location.state.iframe || false;
	const [reservaID, setReservaID] = useState('');

	const recaptchaRef = useRef();
	const [suaVez, setSuaVez] = useState(false);
	// console.log('SUAVEZ => ', suaVez);
	const [lotado, setLotado] = useState(false);
	const [esgotado, setEsgotado] = useState(false);
	const [farmacia, setFarmacia] = useState([]);

	const [nroReservas, setNroReservas] = useState(0);
	const [desativado, setDesativado] = useState(true);
	const [agendamento, setAgendamento] = useState([]);
	const [reservasLivre, setReservasLivre] = useState(0);
	const [pessoasReservando, setPessoasReservando] = useState(0);
	const [showQRCode, setShowQRCode] = useState(false);
	const [timeCount, setTimeCount] = useState(120000);
	const [openDialog, setOpenDialog] = useState(false);

	const [nome, setNome] = useState('');
	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState('');
	const [nameError, setNameError] = useState('');
	const [showEmailError, setShowEmailError] = useState(false);
	const [showNameError, setShowNameError] = useState(false);
	const [showError, setShowError] = useState('');
	const [showErrorMessage, setShowErrorMessage] = useState('');
	const [refreshPage, setRefreshPage] = useState(true);
	const [disableSubmit, setDisableSubmit] = useState(false);
	const [products, setProducts] = useState([]);
	const [cart, setCart] = useState(0);
	const [cartItems, setCartItems] = useState([]);
	const [totalPacotes, setTotalPacotes] = useState(2);
	const [totalProdutosAgendamento, setTotalProdutosAgendamento] = useState(0);
	const [totalProdutosReserva, setTotalProdutosReserva] = useState(0);
	// console.log('CART ITEMS=> ', cartItems);

	useEffect(() => {
		// console.log('USE EFFECT AGENDAMENTOS=> ');
		let isCancelled = false;
		db.collection('usuarios')
			.doc(farmaciaID)
			.collection('agendamentos')
			.doc(agendamentoID)
			.onSnapshot((querySnapshot) => {
				let doc = querySnapshot.data();
				let total = 0;
				let obj = {
					farmaciaID: farmaciaID,
					agendamentoID: agendamentoID,
					horaretirar: new Date(doc.dataInicioRet.seconds * 1000),
					horafimretirar: new Date(doc.dataFimRet.seconds * 1000),
					horaFim: new Date(doc.dataInicioRet.seconds * 1000),
					numeros: doc.numerosRes,
					numerosPacotes: doc.numerosPacotes,
					totalPacotes: doc.totalPacotes,
					horaInicioFim: FormatDateQRCode(doc.dataInicioRet, doc.dataFimRet),
					observacao: doc.observacao,
					resAtiva: doc.resAtiva,
					horaInicioReserva: doc.startDate.toDate(),
					products: doc.products,
				};
				if (!isCancelled) {
					setAgendamento(obj);
					setNroReservas(obj.numeros);
					setTotalPacotes(doc.totalPacotes);
					total =
						total +
						doc.products.reduce((acumulador, produto) => {
							return acumulador + parseInt(produto.qtde);
						}, 0);
					// console.log('TOTAL PRODUTOS AGENDAMENTO => ', total);
					setTotalProdutosAgendamento(total);
					// handleProducts(doc.products);
				}
			});

		return () => (isCancelled = true);
	}, []);

	const handleProducts = (prods) => {
		// console.log('HANDLE PRODUCTS => ', prods);
		const newProds = prods.map((item) => {
			item.estoque = item.qtde;
			item.qtde = 0;
			return item;
		});
		// console.log('NEW PRODS => ', newProds);
		setCartItems(newProds);
	};

	const somarQtde = (reservasData) => {
		// console.log('AGENDAMENTO => ', agendamento);
		// console.log('SOMAR QTDE => ', reservasData);

		if (agendamento?.products && reservasData) {
			// const reservas = reservasData?.products;
			const resultadoSomaReservas = [].concat(...reservasData).reduce((acc, obj) => {
				// console.log('OBJ => ', obj);
				const index = acc.findIndex((item) => item.id === obj.id);
				if (index !== -1) {
					acc[index].qtde += obj.qtde;
				} else {
					acc.push({ ...obj });
				}
				return acc;
			}, []);
			// console.log('RESULTADO SOMA RESERVAS => ', resultadoSomaReservas);

			const updatedProducts = agendamento.products.map((product) => {
				const cartItem = resultadoSomaReservas.find((item) => item.id === product.id);

				if (cartItem) {
					const updatedQtde = parseInt(product.qtde);
					const cartQtde = parseInt(cartItem.qtde);

					if (!isNaN(updatedQtde) && !isNaN(cartQtde)) {
						const updatedQtdeResult = updatedQtde - cartQtde;

						if (!isNaN(updatedQtdeResult)) {
							return { ...product, qtde: updatedQtdeResult };
						}
					}
				}

				return product;
			});

			// console.log('UPDATED PRODUCTS => ', updatedProducts);
			setProducts(updatedProducts);
			handleProducts(updatedProducts);
		}
	};

	useEffect(() => {
		// console.log('USE EFFECT RESERVAS=> ');
		let isCancelled = false;
		db.collection('reservas')
			.where('reservaID', '==', agendamentoID)
			.onSnapshot(async (querySnapshot) => {
				let docs = querySnapshot.docs;
				let can = 0;
				let res = 0;
				let ret = 0;
				let livres = 0;
				let prods = [];
				let total = 0;
				docs.forEach(function (d) {
					if (d.data().status === 'reservado') {
						res++;
						prods.push(d.data().products);
					}

					if (d.data().status === 'cancelado') {
						let cancelados = d.data().products.reduce((acumulador, produto) => {
							return acumulador + produto.qtde;
						}, 0);
						// console.log('CANCELADOS => ', cancelados);
						const objetosComQtdeZerada = d.data().products.map((objeto) => {
							return { ...objeto, qtde: 0 };
						});
						prods.push(objetosComQtdeZerada);
						can += cancelados;
					}

					if (d.data().status === 'retirado') {
						ret++;
						prods.push(d.data().products);
					}

					total =
						total +
						d.data().products.reduce((acumulador, produto) => {
							return acumulador + produto.qtde;
						}, 0);
				});
				// console.log('TOTAL DE PRODUTOS DAS RESERVAS => ', total);
				// setTotalProdutosReserva(total);
				somarQtde(prods);
				livres = totalProdutosAgendamento + can - total;
				// console.log('LIVRES => ', livres);
				// livres = nroReservas - res - ret;
				if (!isCancelled) setReservasLivre(livres);
			});
		return () => (isCancelled = true);
	}, [totalProdutosAgendamento]);

	useEffect(() => {
		if (!refreshPage) return;

		if (!showQRCode) {
			const timer = setTimeout(function () {
				// console.log('--------------------SETTIMEOUT PREENCHER RESERVA------------------ => ');
				setOpenDialog(true);
				disconnectForm('incomplete');
				analytics.logEvent('form_incomplete_' + farmaciaNome);
				handleClose();
			}, timeCount);
			return () => clearTimeout(timer);
		}
	}, [refreshPage]);

	const handleClose = () => {
		setOpenDialog(false);
		history.replace({
			pathname: '/Disconnect',
			state: false,
			iframe: iframe,
			farmaciaID: farmaciaID,
		});
	};

	useEffect(() => {
		let isCancelled = false;
		db.collection('usuarios')
			.doc(farmaciaID)
			.get()
			.then(function (doc) {
				// console.log(doc.data());
				if (!doc.empty) {
					let obj = {
						farmaciaID: doc.id,
						farmaciaNome: doc.data().nome,
						farmaciaLogo: doc.data().logo,
						farmaciaRua: doc.data().endereco.rua,
						farmaciaCidade: doc.data().endereco.cidade,
						farm: doc.data(),
						farmaciaValida: true,
					};
					if (!isCancelled) setFarmacia(obj);
				} else {
					setFarmacia([]);
				}
			});

		return () => (isCancelled = true);
	}, [farmaciaID]);

	useEffect(() => {
		let isCancelled = false;
		db.collection('status')
			.where('farmacia', '==', farmaciaNome)
			.where('state', '==', 'online')
			.where('preenchendo', '==', true)
			.orderBy('last_changed')
			.onSnapshot(function (doc) {
				let pessoas = 0;
				doc.docs.forEach(function (documents, index) {
					pessoas++;
				});
				if (!isCancelled) setPessoasReservando(pessoas);
			});
		if (!isCancelled) setPessoasReservando(0);
		return () => (isCancelled = true);
	}, [farmaciaNome]);

	useEffect(() => {
		// console.log('USEEFFECT => ');
		setEsgotado(false);
		setDesativado(false);
		setLotado(false);
		setSuaVez(false);
		if (agendamento.resAtiva) {
			if (reservasLivre > 0) {
				if (pessoasReservando <= reservasLivre) {
					setLotado(false);
					setSuaVez(true);
				} else {
					setLotado(true);
				}
			} else {
				setEsgotado(true);
			}
		} else {
			setDesativado(true);
		}
	}, [agendamento, pessoasReservando, reservasLivre]);

	useEffect(() => {
		setTimeout(() => {
			disconnectForm('timeout');
		}, 65000);
	}, []);

	const disconnectForm = (where) => {
		// console.log("-----------DISCONNECT FORM-------------");
		var userStatusFirestoreRef = db.collection('status').doc(localStorage.getItem('key' + farmaciaNome));
		var userStatusDatabaseRef = firebase.database().ref('/status/' + localStorage.getItem('key' + farmaciaNome));

		const dateDatabase = firebase.database.ServerValue.TIMESTAMP;
		const dateFirestore = firebase.firestore.FieldValue.serverTimestamp();

		var isOfflineForFirestore = {
			state: 'offline',
			last_changed: dateFirestore,
			preenchendo: false,
			local_disconnect: where,
			data_disconnect: new Date().toLocaleString('es'),
		};
		var isOfflineForDatabase = {
			state: 'offline',
			last_changed: dateDatabase,
			preenchendo: false,
			local_disconnect: where,
			data_disconnect: new Date().toLocaleString('es'),
		};

		userStatusFirestoreRef.set(isOfflineForFirestore, { merge: true });
		userStatusDatabaseRef.set(isOfflineForDatabase);
	};

	const renderTime = (value) => {
		let umavez = 0;

		if (value > 10 && value < 20) {
			return (
				<div className="timer">
					<div className="value">{value}</div>
					<div className="text">Mas rápido...</div>
				</div>
			);
		}
		if (value <= 10 && value > 0) {
			return (
				<div className="timer">
					<div className="value">{value}</div>
					<div className="text">Finalizando...</div>
				</div>
			);
		}
		if (value === 0) {
			if (umavez === 0) {
				umavez = 1;
			}
			return (
				<div className="timer">
					<div className="text">Fin del tiempo!</div>
				</div>
			);
		} else {
			return (
				<div className="timer">
					<div className="value">{value}</div>
				</div>
			);
		}
	};

	const validateEmail = (e_mail) => {
		var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(e_mail);
	};

	const validateRecaptcha = () => {
		// return true;
		if (['localhost'].includes(window.location.hostname)) {
			return true;
		} else {
			return recaptchaRef.current.getValue() !== '' ? true : false;
		}
	};

	const validateCart = () => {
		const qtde = cartItems.reduce((total, item) => total + parseInt(item.qtde), 0);
		if (qtde > 0) return true;
		setShowError(!showError);
		setShowErrorMessage('Necesitas elegir al menos un tipo de variedad');
		setTimeout(() => setShowError(false), 5000);
		setDisableSubmit(true);
		return false;
	};

	const validateNombre = (nombre) => {
		const words = nombre.trim().split(' ');
		const nome = words[0] || '';
		const sobrenome = words[1] || '';
		return nome.length >= 2 && sobrenome.length >= 2;
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!validateCart()) return;

		setDisableSubmit(true);

		if (!validateRecaptcha()) {
			setShowError(true);
			setShowErrorMessage('¿Eres un robot? Seleccione arriba si no.');
			setTimeout(() => setShowError(false), 3000);
			return;
		}

		if (!validateNombre(nome)) {
			setNameError('Para realizar la reserva, es necesario llenar tu nombre y apellido.');
			setShowNameError(true);
			setDisableSubmit(false);
			return;
		}

		if (!validateEmail(email)) {
			setEmailError('E-mail inválido!');
			setShowEmailError(true);
			setDisableSubmit(false);
			return;
		}

		try {
			const reservaExists = await checkReservaExistence();
			if (reservaExists) {
				setEmailError('Ya existe una reserva con este correo electrónico.');
				setShowEmailError(true);
				setDisableSubmit(false);
				return;
			}

			const hasAvailableReservas = await checkAvailableReservas();
			if (hasAvailableReservas) {
				const reserveObj = await createReserveObject();
				const docRef = await saveReserva(reserveObj);
				disconnectForm('complete');
				analytics.logEvent('form_success_' + farmaciaNome);
				redirectAfterSuccess(docRef);
			} else {
				console.log('----- SEM PRODUTOS DISPONIVEIS -----');
			}
		} catch (error) {
			handleReservaError(error);
		}
	};

	const checkReservaExistence = async () => {
		const reservaSnapshot = await db.collection('reservas').where('farmaciaID', '==', farmaciaID).where('reservaID', '==', agendamentoID).where('email', '==', email).where('status', '==', 'reservado').get();

		return !reservaSnapshot.empty;
	};

	const checkAvailableReservas = async () => {
		return reservasLivre > 0;
	};

	const createReserveObject = async () => {
		const dt = new Date();
		const dataReserva = ('0' + dt.getDate()).slice(-2) + '-' + ('0' + (dt.getMonth() + 1)).slice(-2);
		const timestamp = await fetchTimestampFromServer();
		return {
			email: email,
			nome: nome,
			horacadastro: new Date(timestamp),
			status: 'reservado',
			farmaciaID: farmaciaID,
			tipo: '',
			dataRetirar: agendamento.horaretirar,
			dataFimRetirar: agendamento.horafimretirar,
			agendamentoID: agendamentoID,
			reservaID: agendamentoID,
			observacao: agendamento.observacao,
			data: dataReserva,
			products: cartItems,
			versionNow: {
				version: version,
				versionApp: version_app,
			},
		};
	};

	const fetchTimestampFromServer = async () => {
		try {
			const result = await axios({
				method: 'GET',
				url: 'https://us-central1-reservar-cannabis-uru.cloudfunctions.net/widgets/getTimeStamp',
			});

			console.log('RESULT => ', result.data.timestamp);

			return result.data.timestamp;
		} catch (err) {
			console.log('ERR => ', err);
		}
	};

	const saveReserva = async (reserveObj) => {
		const docRef = await db.collection('reservas').add(reserveObj);
		return docRef;
	};

	const redirectAfterSuccess = (docRef) => {
		history.replace({
			pathname: '/qrcodereserva',
			state: {
				reservaID: docRef.id,
				nome: nome,
				farmaciaNome: farmaciaNome,
				farmaciaRua: farmacia.farmaciaRua,
				farmaciaCidade: farmacia.farmaciaCidade,
				horaInicioFim: agendamento.horaInicioFim,
				observacao: agendamento.observacao,
				iframe: iframe,
				products: cartItems,
			},
		});
	};

	const handleReservaError = (error) => {
		console.error('Erro ao criar reserva:', error);
		const errorMessage = '¡Ups! Ocurrió un error.\nTu aplicación no está actualizada. Por favor, elimina el caché para que puedas continuar haciendo reservas.';
		if (window.confirm(errorMessage)) {
			history.replace({ pathname: '/' });
		} else {
			history.replace({ pathname: '/' });
		}
	};

	const updateProductsScheduled = (products, cart) => {
		// console.log('UPDATE PRODUCTS SCHEDULED => ', products, cart);

		const produtosAtualizados = atualizarEstoque(products, cart);
		// console.log('PRODUTOS ATUALIZADOS => ', produtosAtualizados);

		db.collection('usuarios').doc(farmaciaID).collection('agendamentos').doc(agendamentoID).set({ products: produtosAtualizados }, { merge: true });
	};

	function atualizarEstoque(products, cart) {
		// Crie uma cópia profunda dos produtos para evitar modificar o array original.
		const updatedProducts = JSON.parse(JSON.stringify(products));

		// Crie um mapa dos produtos pelo ID para facilitar as atualizações.
		const productMap = updatedProducts.reduce((map, product) => {
			map[product.id] = product;
			return map;
		}, {});

		// Percorra o carrinho e atualize o estoque dos produtos correspondentes.
		for (const item of cart) {
			if (item.qtde > 0) {
				if (productMap[item.id]) {
					const estoqueDisponivel = parseInt(productMap[item.id].qtde, 10);
					productMap[item.id].qtde = Math.max(0, estoqueDisponivel - item.qtde).toString();
				}
			}
		}

		updatedProducts.forEach((product) => {
			product.qtde = parseInt(product.qtde, 10);
		});

		return updatedProducts;
	}

	function handleOperation(arrayA, arrayB, tipoOperacao) {
		const resultado = [];

		const mapaArrayB = new Map(arrayB.map((item) => [item.id, item]));

		for (const itemA of arrayA) {
			const id = itemA.id;
			const qtdeA = itemA.qtde;
			const itemB = mapaArrayB.get(id);

			if (itemB) {
				const qtdeB = itemB.qtde;
				let novaQtde;

				if (tipoOperacao === 'sum') {
					novaQtde = qtdeA + qtdeB;
				} else if (tipoOperacao === 'subtract') {
					novaQtde = qtdeA - qtdeB;
				} else {
					throw new Error('Tipo de operação não reconhecido');
				}

				resultado.push({
					...itemA,
					qtde: novaQtde,
				});
			} else {
				resultado.push(itemA);
			}
		}

		return resultado;
	}

	const obtainFreeReserves = async (agendamentoID) => {
		const timeout = Math.floor(Math.random() * 6000) + 1;
		setTimeout(async () => {
			const querySnapshot = await db.collection('reservas').where('reservaID', '==', agendamentoID).get();

			const docs = querySnapshot.docs;
			let can = 0;
			let res = 0;
			let ret = 0;
			let livres = 0;
			let prods = [];
			let total = 0;

			for (const d of docs) {
				switch (d.data().status) {
					case 'reservado':
						res++;
						prods.push(d.data().products);
						break;

					case 'cancelado':
						const cancelados = d.data().products.reduce((acumulador, produto) => {
							return acumulador + produto.qtde;
						}, 0);
						// console.log('CANCELADOS => ', cancelados);

						const objetosComQtdeZerada = d.data().products.map((objeto) => {
							return { ...objeto, qtde: 0 };
						});

						prods.push(objetosComQtdeZerada);
						can += cancelados;
						break;

					case 'retirado':
						ret++;
						prods.push(d.data().products);
						break;

					default:
				}

				total += d.data().products.reduce((acumulador, produto) => {
					return acumulador + produto.qtde;
				}, 0);
			}

			livres = totalProdutosAgendamento + can - total;
			// console.log('LIVRES => ', livres);

			return livres;
		}, timeout);
	};

	const checkIfExistFreeReserve = async () => {
		try {
			const livres = await obtainFreeReserves(agendamentoID);
			// console.log('Livres obtidos:', livres);
			return livres;
		} catch (error) {
			console.error('Erro:', error);
		}
	};

	const downloadURI = (uri, name) => {
		var link = document.createElement('a');
		link.download = name;
		link.href = uri;
		document.body.appendChild(link);
		link.click();
	};

	const printToFile = (div) => {
		html2canvas(document.querySelector('#pdf-get'), {
			width: 600,
			height: 900,
		}).then((canvas) => {
			var myImage = canvas.toDataURL('image/png');
			downloadURI(myImage, 'reserva_' + reservaID + '.png');
		});
	};

	const addToCart = (prod) => {
		if (prod.estoque > 0) {
			const qtde = cartItems.reduce(function (acc, obj) {
				return acc + obj.qtde;
			}, 0);
			// console.log('QTDE => ', qtde);
			const PACKAGES_LIMIT = totalPacotes;
			const qtdeEstoque = parseInt(prod.estoque);

			if (qtde < PACKAGES_LIMIT) {
				// console.log('ADD TO CART => ', prod);
				if (prod.qtde == PACKAGES_LIMIT) {
					return;
				}
				if (prod.qtde == qtdeEstoque) {
					return;
				}
				prod.qtde++;
				const qtdeLimit = prod.qtde;
				const filtered = cartItems.filter((item) => {
					return item.id !== prod.id;
				});
				// console.log('FILTERED => ', filtered);

				const newfiltered = filtered.map((item) => {
					if (item.qtde > 0) {
						if (qtdeLimit == PACKAGES_LIMIT) {
							item.qtde = 0;
						}
						if (qtdeLimit == 1 && qtde < PACKAGES_LIMIT) {
							item.qtde = 1;
						}
					}
					return item;
				});
				// console.log('NEW FILTERED => ', newfiltered);
				setCartItems([...newfiltered, prod]);
			} else if (qtde == PACKAGES_LIMIT) {
				// console.log('EQUAL 2 => ', qtde, prod.qtde);

				if (prod.qtde == qtdeEstoque) {
					return;
				}

				if (prod.qtde == 1) {
					prod.qtde++;
					const filtered = cartItems.filter((item) => item.id != prod.id);
					// console.log('FILTERED => ', filtered);
					const newfiltered = filtered.map((item) => {
						if (item.qtde > 0) {
							item.qtde = 0;
						}
						return item;
					});
					setCartItems([...newfiltered, prod]);
				}
				if (prod.qtde == 0) {
					prod.qtde++;
					const filtered = cartItems.filter((item) => item.id != prod.id);
					// console.log('FILTERED => ', filtered);
					const newfiltered = filtered.map((item) => {
						if (item.qtde == 1) {
							item.qtde = 0;
						}
						if (item.qtde == PACKAGES_LIMIT) {
							item.qtde = 1;
						}
						return item;
					});
					setCartItems([...newfiltered, prod]);
				}
			}
		}
	};

	const removeToCart = (prod) => {
		if (prod.estoque > 0) {
			if (prod.qtde == 0) {
				return;
			}
			prod.qtde--;
			const filtered = cartItems.filter((item) => {
				return item.id !== prod.id;
			});
			// console.log('FILTERED => ', filtered);
			setCartItems([...filtered, prod]);
		}
	};

	const handleFiltereds = (newprod, newcart) => {
		let filterProducts = products.filter((item) => item.id !== newprod.id);
		let filtereds = [...filterProducts, newprod];
		const sorted = handleSortProducts(filtereds);
		setCart(newcart);
		// setProducts(sorted);
	};

	const handleSortProducts = (data) => {
		const sorted = data.sort((a, b) => {
			if (a.text < b.text) return -1;
			if (a.text > b.text) return 1;
			return 0;
		});
		return sorted;
	};

	const ProductItem = ({ item }) => {
		// console.log('ITEM => ', item);
		const disabled = item?.estoque > 0 ?? 0;
		return (
			<Grid
				style={{
					marginTop: 10,
					borderWidth: 1,
					borderStyle: 'solid',
					borderColor: !disabled ? `#e9e9e9` : `${item.color}`,
					borderRadius: 50,
				}}
			>
				<Grid container spacing={3} direction="row" justifyContent="space-evenly" alignItems="center" style={{ backgroundColor: 'transparent', marginTop: 0, marginBottom: 0 }}>
					<Grid
						item
						style={{
							display: 'flex',
							flex: 1,
							flexDirection: 'row',
							justifyContent: 'center',
							alignContent: 'center',
						}}
					>
						<Typography
							style={{
								fontWeight: 600,
								marginLeft: '20px',
								color: !disabled ? `#e9e9e9` : `${item.color}`,
							}}
						>
							{item.text}
						</Typography>
					</Grid>

					<Grid
						item
						style={{
							display: 'flex',
							flex: 2,
							flexDirection: 'row',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Remove
							className="selectedButton"
							onClick={() => removeToCart(item)}
							style={{
								color: !disabled ? `#e9e9e9` : `${item.color}`,
								boxShadow: !disabled ? `#e9e9e9 0px 0px 0px 1px` : `${item.color} 0px 1px 2px 1px`,
							}}
						/>

						<Box
							style={{
								width: 20,
								color: !disabled ? `#e9e9e9` : `${item.color}`,
								fontWeight: 'bold',
								marginInline: 10,
							}}
						>
							{parseInt(item.qtde) || 0}
						</Box>

						<Add
							className="selectedButton"
							onClick={() => addToCart(item)}
							style={{
								fontSize: 40,
								padding: '5px',
								marginLeft: '2px',
								color: !disabled ? `#e9e9e9` : `${item.color}`,
								boxShadow: !disabled ? `#e9e9e9 0px 0px 0px 1px` : `${item.color} 0px 1px 2px 1px`,
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
		);
	};

	return (
		<div>
			{!iframe && <Header />}
			<div className="page- header-filter" style={{ backgroundImage: `url('/img/chamada-teste-1.jpg'` }}>
				<div className="container">
					<div className="row">
						<div className="col-sm-12 col-md-8 col-lg-7 col-xl-6 ml-auto mr-auto farmacia">
							<div className="card">
								<div className="card-header-3 text-center ">
									<p className="dias">{farmacia.farmaciaNome} - Formulario de Reserva</p>
									<img width="200px" alt="Logo Farmácia" src={farmacia.farmaciaLogo} />
									<br />
								</div>
								<div className="card-header-description text-center">
									{!showQRCode ? (
										<div>
											<center style={{ margin: 30, color: '#808080' }}>Acá tienes dos minuto para completar tu resereva, si no pasamos para el próximo que esta esperando allá en la fila.</center>
											<CountdownCircleTimer isPlaying size={120} durationSeconds={timeCount / 1000} colors={[['#004777', 0.33], ['#F7B801', 0.33], ['#A30000']]} renderTime={renderTime} onComplete={() => [false, 1000]} />

											<form onSubmit={handleSubmit} autoComplete="on">
												<TextField className={'muilabel'} value={nome} onChange={(e) => setNome(e.target.value)} autoFocus required style={{ width: '60%' }} id="nome" label="Nombre y Apellido" name="name" error={showNameError} helperText={nameError} autoComplete="name" />

												<br></br>
												<br></br>

												<EmailTextField className={'muilabel'} error={showEmailError} value={email} required={true} name="email" style={{ width: '60%' }} id="email" label="E-mail" helperText={emailError} onChange={setEmail} />
												<br />
												<br />
												<h6 style={{ color: '#c1c1c1' }}>* Puedes llevar hasta {totalPacotes} paquetes.</h6>
												<div style={{ display: 'inline-block', width: '75%', marginBottom: 20 }}>
													{/* {agendamento && agendamento.products && agendamento.products.map((item, idx) => ( */}
													{products && products.map((item, idx) => <ProductItem key={idx} item={item} />)}
												</div>
												{/* <ProductItem listProducts={agendamento.products} /> */}
												<br></br>

												<ReCAPTCHA className="recaptcha" sitekey="6LeiZOUUAAAAAEYn0gzirG4g30wMwaMrLJ8f6wzm" size="normal" ref={recaptchaRef} />
												{showError && <Alert severity="error">{showErrorMessage}</Alert>}

												<br></br>
												{suaVez && (
													<button disabled={disableSubmit} className="btn btn-block btn-primary" type="submit">
														Enviar
													</button>
												)}

												<div style={{ width: '80%', textAlign: 'center', marginLeft: '13%' }}>
													<center style={{ color: '#000', fontSize: 12, paddingTop: 20 }}>
														Las variedades Alfa y Beta tienen menos THC (de 6% a un máximo de 9%), mientras que la variedad Gamma tiene más THC (de 12% a 15%).
														<br />
													</center>
													<center style={{ color: '#999', paddingTop: 20 }}>
														<strong>Aviso</strong>
														<br />
														Si estas teniendo algún inconveniente, probá recargar la página ó borrar el cacheo de su navegador.
														<br />
													</center>
												</div>
												<br></br>

												{desativado && (
													<div className="card-header-secundary text-center nohay">
														<h3>
															<i className="material-icons">warning</i> Desactivado!
														</h3>
														La reserva fue desactivada por la farmacia.
													</div>
												)}

												{esgotado && (
													<div className="card-header-secundary text-center nohay">
														<h3>
															<i className="material-icons">warning</i> No hay más números!
														</h3>
														Lamentablemente no tenemos más números, disculpe las molestias ocasionadas. Estamos en proceso de continua mejora para brindar a los usuarios de cannabis un acceso rápido y democrático.
													</div>
												)}
											</form>
											<br></br>
										</div>
									) : (
										<div>
											<div id="pdf-get">
												<div>
													<QRGenerator value={reservaID} />
													<br></br>
													<strong style={{ color: '#000' }}>{nome}</strong>
													<br></br>
													<strong style={{ color: '#0d95de' }}>
														{farmaciaNome} – Retirar <br />
														{agendamento.horaInicioFim}
														<br />
														{agendamento.observacao}
													</strong>

													<br></br>
													<br></br>

													<strong style={{ color: '#000' }}>
														⚠️ La reserva no da prioridad en la fila!
														<br></br>
														Antes de venir a la farmacia baje la reserva para facilitar la atención.
													</strong>

													<br></br>
													<br></br>

													<img src="/img/reservarcannabis-logo_preto.png" alt="Reserve Cannabis" width="130" />
												</div>
											</div>
											<br></br>

											<button className="btn btn-block btn-primary" onClick={printToFile}>
												Bajar Reserva
											</button>

											<br></br>
										</div>
									)}

									<button className="btn btn-block btn" data-toggle="modal" data-target="#direccion">
										<i className="material-icons">directions</i> Donde Queda?
										<div className="ripple-container"></div>
									</button>
									<button className="btn btn-block btn-info aviso" data-toggle="modal" data-target="#reglas">
										<i className="material-icons">library_books</i> Reglas
										<div className="ripple-container"></div>
									</button>
									<div className="card-header-primary text-center hogar">
										<strong>Aviso: Verifique la ubicación. La reserva es únicamente para esta farmacia!</strong>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="modal fade" id="direccion" tabIndex="-1" role="dialog" style={{ display: 'none' }} aria-hidden="true">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h2 className="modal-title">Donde queda?</h2>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<i className="material-icons">clear</i>
								</button>
							</div>
							<div className="modal-body">
								<p>
									Esta farmacia esta ubicada en {farmacia.farmaciaRua} - {farmacia.farmaciaCidade}
									<br></br>Fijate si estas cerca de la farmacia donde vas a reservar.
								</p>
								<div className="embed-responsive embed-responsive-16by9">
									<iframe title={farmacia.farmaciaNome} width="350" height="250" src={'https://maps.google.com/maps?q=' + farmacia.farmaciaNome + ', ' + farmacia.farmaciaRua + ', ' + farmacia.farmaciaCidade + '&t=&z=13&ie=UTF8&iwloc=&output=embed'} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" />
								</div>
								<p></p>
								Auspiciado por{' '}
								<a href="https://bit.ly/mapacannabis" target="_blank">
									<img src="https://prfvr.com/wp-content/uploads/2016/02/thumb_budmaps.png" width="120" />
								</a>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-link">
									<div className="ripple-container"></div>
								</button>
								<button type="button" className="btn btn-danger btn-link" data-dismiss="modal">
									Cerrar
									<div className="ripple-container">
										<div className="ripple-decorator ripple-on ripple-out" style={{ left: '35.4821px', top: '21.05px', backgroundColor: 'rgb(244, 67, 54)', transform: 'scale(8.50976)' }}></div>
										<div className="ripple-decorator ripple-on ripple-out" style={{ left: '35.4821px', top: '21.05px', backgroundColor: 'rgb(244, 67, 54)', transform: 'scale(8.50976)' }}></div>
									</div>
								</button>
							</div>
						</div>
					</div>
				</div>

				<div className="modal fade" id="reglas" tabIndex="1" role="dialog">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h2 className="modal-title">Reglas de la Reserva</h2>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<i className="material-icons">clear</i>
								</button>
							</div>
							<div className="modal-body">
								<p>
									<strong>Cuando haces una reserva significa que te involucraste y tomaste una decisión!</strong>
								</p>
								<p>
									<strong>Reglas&nbsp; de Suspensión</strong> (Reserva sin retirar)<br></br>
									Si hiciste una reserva,
									<strong>
										{' '}
										no te olvides de venir hasta el plazo para retirar!<br></br>
									</strong>
									Esta aplicación fue hecha para las personas que <strong>necesitan y quieren</strong>,<br></br>
									si no logras venir <a href="https://reservarcannabis.com/cancelar-reserva/">haga la cancelación de la reserva para evitar ser suspendido.</a>
								</p>
								<ul>
									<li>
										<strong>1 reserva sin venir;</strong> Suspendido una semana;
									</li>
									<li>
										<strong>2 reserva sin venir;</strong> Suspendido un mês;
									</li>
								</ul>
								<p>
									<strong>
										Usamos QRCODE!<br></br>
									</strong>
									Si tienes el ticket con la información de la reserva, significa que su reservación está garantizada! Si te quedaste sin bateria también podes retirar con tu nombre completo.
								</p>
								<p>
									<strong>Convertir o cambiar reservas</strong>
									<br></br>
									Las reservas no pueden ser intercambiados por dinero real. Las reservas para cannabis, por lo general, son intransferibles a otras personas y no se pueden cambiar por nada. Si se descubre estos casos pueden ser penados por ley. Fijate si estas cerca de la farmacia donde vas a reservar; Si no vas a venir a retirar, no haga su reserva; La reserva online no da prioridad en la fila; Cuando haces la reserva, vos aceptas las reglas establecidas aquí.
								</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-danger btn-link" data-dismiss="modal">
									Cerrar
									<div className="ripple-container"></div>
									<div className="ripple-decorator ripple-on ripple-out" style={{ left: '35.4821px', top: '21.05px', backgroundColor: 'rgb(244, 67, 54)', transform: 'scale(8.50976)' }}></div>
									<div className="ripple-decorator ripple-on ripple-out" style={{ left: '35.4821px', top: '21.05px', backgroundColor: 'rgb(244, 67, 54)', transform: 'scale(8.50976)' }}></div>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<Dialog open={openDialog} onClose={() => handleClose()} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title">{'Tiempo Excedido!'}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">No llenaste en el formulário en el tiempo requerido de 60 segundos. Podes volver a acceder a la fila para volver a hacer una reserva.</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => handleClose()} color="primary" autoFocus>
							OK
						</Button>
					</DialogActions>
				</Dialog>
			</div>
			{!iframe && <Footer />}
		</div>
	);
};

export default PreencherReserva;
