import React from 'react';
import { useHistory } from 'react-router-dom';

import Header from './parts/header';
import '../css/material-kit.css?v=2020';
import '../css/styles.css';

function Disconnect({ location }) {
    const state = location;
    const history = useHistory();

    const iframe = state.iframe ?? false
    const farmaciaID = state.farmaciaID

    const redirectToHome = () => {
        history.replace({
            pathname: '/',
            state: null
        });
    }

    const redirectToIframe = () => {
        window.location.href = `/iframe?id=${farmaciaID}`
    }

    return (
        <div>
            {!iframe && <Header />}
            <div className="page- header-filter" style={{ backgroundImage: `url('/img/chamada-teste-1.jpg'` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 ml-auto mr-auto farmacia">

                            <div className="card">
                                <div className="card-header-3 text-center ">
                                    <p className="dias">Desconectado</p>
                                    <br />
                                </div>
                                <div className="card-header-description">
                                    <div className="container">
                                        <div className="col-12 text-center">
                                            <h5>
                                            No llenaste en el formulário en el tiempo requerido. Pruebá volver a acceder a la fecha de la farmácia para hacer la reserva.
                                            </h5>
                                            <div className="img-disconnect">
                                                <img alt="Reservar Cannabis" title="Reservar Cannabis" src="img/apple-touch-icon-ipad-retina.png" />
                                            </div>
                                            <br />
                                            <a href="https://blog.reservarcannabis.com/contacto/" title="contacto" alt="contacto">
                                            Ayúdanos a mejorar, deja tu comentario sobre el sistema aqui!
                                            </a>
                                        </div>
                                    </div>
                                    <br />
                                    {iframe ?
                                        <button onClick={() => redirectToIframe()} className="btn btn-block btn btn-primary" data-toggle="modal" data-target="#direccion">
                                            <i className="material-icons">home</i> De regreso al comienzo
									    <div className="ripple-container"></div>
                                        </button>
                                        :
                                        <button onClick={() => redirectToHome()} className="btn btn-block btn btn-primary" data-toggle="modal" data-target="#direccion">
                                            <i className="material-icons">home</i> Regresar a la página principal
									    <div className="ripple-container"></div>
                                        </button>
                                    }
                                    <br />
                                    <div className="card-header-primary text-center hogar">
                                        <strong>&nbsp;</strong>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                {/* <Footer /> */}
            </div>
        </div>
    );

}

export default Disconnect;