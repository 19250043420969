import {camelCase} from 'lodash';

const createReducerFor = (prefix, initial_state) => {
  let methods = {};
  let actions = {};
  for (let attribute in initial_state) {
    let typeDecorator = `${prefix}_set_${attribute}`.toUpperCase();
    let actionDecorator = `set${capitalize(camelCase(attribute))}`;

    methods[typeDecorator] = (state, action) => {
      return {...state, [attribute]: action.payload};
    };

    actions[actionDecorator] = value => {
      return {type: typeDecorator, payload: value};
    };
  }

  const reducer = (state = initial_state, action) => {
    if (typeof methods[action.type] === 'function') {
      return methods[action.type](state, action);
    }
    return state;
  };

  return [reducer, actions];
};

export default createReducerFor;

const capitalize = string => {
  if (typeof string !== 'string') {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};
