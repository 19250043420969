import React from 'react';
import { connect } from "react-redux";
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { logoutUser } from "../../actions";
import TabelaReservas from "./components/TabelaReservas";
import MenuAppBar from './components/Appbar';
import Copyright from './components/Copyright';
import useStyles from './Styles';



function ListaReservas(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <MenuAppBar />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h3 style={{ padding: 20 }}>Listado de reservas</h3>
          </Grid>
          <Grid item xs={12}>
            <TabelaReservas limit={3000} />
          </Grid>
        </Grid>
        <Copyright />
      </main>
    </div>
  );
}
function mapStateToProps(state) {
  return {
    isLoggingOut: state.auth.isLoggingOut,
    logoutError: state.auth.logoutError
  };
}

const mapDispatchToProps = dispatch => ({
  Deslogar() {
    dispatch(logoutUser());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(ListaReservas);
