import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, Divider, ImageList, ImageListItem, Paper } from '@material-ui/core';
import cx from 'clsx';
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';
import { useGutterBorderedGridStyles } from '@mui-treasury/styles/grid/gutterBordered';
import { useHistory } from 'react-router-dom';
import useStyles from './Styles';
import CardScheduled from '../CardScheduled';
import { Grid } from '@mui/material';

const Cards = ({ farm, agend }) => {
  const history = useHistory();
  const classes = useStyles();
  const shadowStyles = useFadedShadowStyles();
  const borderedGridStyles = useGutterBorderedGridStyles({
    borderColor: 'rgba(0, 0, 0, 0.08)',
    height: '50%',
  });
  const [agendamento, setAgendamento] = useState([]);
  const [scheduledFixed, setScheduledFixed] = useState();

  useEffect(() => {
    checkReservasCard(agend);
  }, [agend]);

  const navigateReservar = (params) => {
    history.replace(params);
  };

  function checkReservasCard(dados) {
    let dataFarmacia = [];
    dados.forEach(function (data, idx) {

      var btn = false;
      var green = false;
      var red = false;
      var gray = false;
      var orange = false;

      var horaAtual = parseInt((new Date().getTime() / 1000).toFixed(0));
      var horaReserva = parseInt((data.data.getTime() / 1000).toFixed(0));

      var dataAtual = parseInt((new Date().getTime() / 1000).toFixed(0));
      var dataReserva = parseInt((data.data.getTime() / 1000).toFixed(0));

      var today = new Date();
      var otherDate = data.data;
      var isToday = (today.toDateString() === otherDate.toDateString());

      let numeros = data.numeros;
      let reservas = data.reservas;

      // let horaExata = horaCerta;

      if (!data.ativa) {
        red = true;
      } else {
        if (isToday) {
          if (horaAtual >= horaReserva) {
            if (numeros > reservas) {
              btn = true;
              green = true;
            } else if (numeros <= reservas) {
              red = true;
            }
          } else {
            orange = true;
          }
        } else if (dataReserva > dataAtual) {
          gray = true;
        } else if (dataReserva < dataAtual) {
          if (numeros > reservas) {
            btn = true;
            green = true;
          } else if (numeros <= reservas) {
            red = true;
          }
        }
      }

      if (!!data.fixed) {
        setScheduledFixed(data);
      }

      dataFarmacia.push({
        btn: btn,
        green: green,
        red: red,
        gray: gray,
        orange: orange,
        _id: data._id,
        dataInicioRet: data.dataInicioRet,
        dataFimRet: data.dataFimRet,
        reservaID: data.reservaID,
        reservas: data.reservas,
        numeros: parseInt(data.numeros),
        horarioFim: data.horarioFim,
        data: data.data,
        products: data.products,
        fixed: data.fixed
      });
    });
    setAgendamento(dataFarmacia);

  }

  const BoxProducts = ({ item }) => (
    <Grid container flexDirection={'column'}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {item.products && item.products.map((item, idx) => (
          <div key={idx} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flexWrap: 'wrap', alignItems: 'center', marginLeft: 5, marginRight: 5 }}>
            <span style={{ background: item.color, width: 15, height: 15, borderRadius: 50 }}></span>
            {/* <span style={{ fontSize: 11, fontWeight: '400', marginLeft: 3, textAlign: 'center', }}>{item.qtde}</span> */}
            <span style={{ fontSize: 10, fontWeight: '400', marginLeft: 5, lineHeight: '13px', textAlign: 'center', marginTop: 5 }}>{item.text}</span>
          </div>
        ))}
      </div>
    </Grid>
  );

  return (
<div>
    <Paper square elevation={0} className={classes.resetContainer}>
      <div className={classes.gridroot} >
        <p className={classes.pos} color="textPrimary">
          <img style={{
            borderRadius: '25px',
          }
          } width="180px" alt="logo" src={farm.farmaciaLogo} />
        </p>
        <ImageList actionposition="left" id="gridList" className={classes.gridList} cols={5} >
          {!agendamento.length && (
            <Card key={1} className={cx(classes.cardroot, shadowStyles.root, classes.parahoje)}>

              <CardContent>
                <Button className={classes.avatar} variant="contained" color="primary" size="small" disabled>INDISPONIBLE</Button>
                <h3 className={classes.heading}>No hay reservas aún</h3>
                {farm.farmaciaCidade !== undefined && (
                  <span className={classes.subheader}></span>
                )}
              </CardContent>
              <Divider light />
              <Box display={'flex'}>
                <Box p={2} flex={'auto'} className={borderedGridStyles.item}>
                  <p className={classes.statLabel}>Indisponible</p>

                </Box>
                <Box p={2} flex={'auto'} className={borderedGridStyles.item}>
                  <p className={classes.statLabel}>Indisponible</p>
                </Box>
              </Box>
            </Card>
          )
          }

          <CardScheduled scheduledFixed={scheduledFixed} dataPharm={farm} />

          {
            agendamento.map((item, index) => (
              <ImageListItem style={{ minWidth: '250px', height: '35em !important', paddingLeft: '5px', overflow: 'unset' }} key={index}>
                {item.green ? (
                  <Card key={index} className={cx(classes.cardroot, shadowStyles.root, classes.aindaTem)} style={{marginLeft:'12px'}}>
                    <CardContent>
                      {index < 2 && item.btn ? (
                        <Button className={classes.avatar} variant="contained" color="primary" size="small"
                          onClick={() => navigateReservar({
                            pathname: '/reserva',
                            // pathname: '/reserva/' + dadosFarm.nome + '/' + ("0" + item.data.getDate()).slice(-2) + "-" + ("0" + (item.data.getMonth() + 1)).slice(-2),
                            state: {
                              agendamentoID: item.reservaID,
                              idfarmacia: farm.farmaciaID,
                              farmaciaNome: farm.farmaciaNome,
                              date: new Date(),
                              iframe: true
                            }
                          })}
                        >RESERVAR</Button>

                      ) : (
                        <Button className={classes.avatar} variant="contained" color="primary" size="small" disabled>RESERVAR</Button>
                      )
                      }

                      <h3 className={classes.heading}>{farm.farmaciaNome}</h3>

                      {farm.farmaciaCidade !== undefined && (
                        <div>
                          <span className={classes.subheader}>{farm.farmaciaCidade}</span>
                          <span className={classes.bairro}>{farm.farmaciaBairro}</span>
                        </div>
                      )}

                      <BoxProducts item={item} />

                    </CardContent>
                    <Divider light />
                    <Box display={'flex'}>
                      <Box p={2} flex={'auto'} className={borderedGridStyles.item}>
                        <p className={classes.statLabel}>Disponible</p>
                        <p className={classes.statValue1}>
                          {("0" + item.data.getDate()).slice(-2) + "/" + ("0" + (item.data.getMonth() + 1)).slice(-2)
                            + "\n" + item.data.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' })}
                        </p>
                      </Box>
                      <Box p={2} flex={'auto'} className={borderedGridStyles.item}>
                        <p className={classes.statLabel}>Retirada</p>
                        <p className={classes.statValue1}>{item.horarioFim}</p>
                      </Box>
                    </Box>
                  </Card>

                ) : ('')
                }
                {/* {proximosDias acabouNumeros aindaTem parahoje} */}
                {item.orange ? (
                  <Card key={index} className={cx(classes.cardroot, shadowStyles.root, classes.parahoje)}>

                    {/* {console.log("OPT 3")} */}

                    <CardContent>
                      <Button className={classes.avatar} variant="contained" color="primary" size="small" disabled>RESERVAR</Button>
                      <h3 className={classes.heading}>{farm.farmaciaNome}</h3>
                      {farm.farmaciaCidade !== undefined && (
                        <div>
                          <span className={classes.subheader}>{farm.farmaciaCidade}</span>
                          <span className={classes.bairro}>{farm.farmaciaBairro}</span>
                        </div>
                      )}

                      <BoxProducts item={item} />
                    </CardContent>
                    <Divider light />
                    <Box display={'flex'}>
                      <Box p={2} flex={'auto'} className={borderedGridStyles.item}>
                        <p className={classes.statLabel}>Disponible</p>
                        <p className={classes.statValue1}>
                          {("0" + item.data.getDate()).slice(-2) + "/" + ("0" + (item.data.getMonth() + 1)).slice(-2)
                            + "\n" + item.data.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' })}
                        </p>
                      </Box>
                      <Box p={2} flex={'auto'} className={borderedGridStyles.item}>
                        <p className={classes.statLabel}>Retirada</p>
                        <p className={classes.statValue1}>{item.horarioFim}</p>
                      </Box>
                    </Box>
                  </Card>
                ) : ('')}

                {item.red ? (
                  <Card key={index} className={cx(classes.cardroot, shadowStyles.root, classes.acabouNumeros)}>
                    <CardContent>
                      <Button className={classes.avatar} variant="contained" color="primary" size="small" disabled>RESERVAR</Button>
                      <h3 className={classes.heading}>{farm.farmaciaNome}</h3>
                      {farm.farmaciaCidade !== undefined && (
                        <span className={classes.subheader}>{farm.farmaciaCidade}</span>
                      )}
                    </CardContent>
                    <Divider light />
                    <Box display={'flex'}>
                      <Box p={2} flex={'auto'} className={borderedGridStyles.item}>
                        <p className={classes.statLabel}>Disponible</p>
                        <p className={classes.statValue1}>
                          {("0" + item.data.getDate()).slice(-2) + "/" + ("0" + (item.data.getMonth() + 1)).slice(-2)
                            + "\n" + item.data.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' })}
                        </p>
                      </Box>
                      <Box p={2} flex={'auto'} className={borderedGridStyles.item}>
                        <p className={classes.statLabel}>Retirada</p>
                        <p className={classes.statValue1}>{item.horarioFim}</p>
                      </Box>
                    </Box>
                  </Card>
                ) : ('')}

                {item.gray ? (
                  <Card key={index} className={cx(classes.cardroot, shadowStyles.root, classes.proximosDias)}>
                    <CardContent>
                      <Button className={classes.avatar} variant="contained" color="primary" size="small" disabled>RESERVAR</Button>
                      <h3 className={classes.heading}>{farm.farmaciaNome}</h3>
                      {farm.farmaciaCidade !== undefined && (
                        <div>
                          <span className={classes.subheader}>{farm.farmaciaCidade}</span>
                          <span className={classes.bairro}>{farm.farmaciaBairro}</span>
                        </div>
                      )}
                      <BoxProducts item={item} />
                    </CardContent>
                    <Divider light />
                    <Box display={'flex'}>
                      <Box p={2} flex={'auto'} className={borderedGridStyles.item}>
                        <p className={classes.statLabel}>Disponible</p>
                        <p className={classes.statValue1}>
                          {("0" + item.data.getDate()).slice(-2) + "/" + ("0" + (item.data.getMonth() + 1)).slice(-2)
                            + "\n" + item.data.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' })}
                        </p>
                      </Box>
                      <Box p={2} flex={'auto'} className={borderedGridStyles.item}>
                        <p className={classes.statLabel}>Retirada</p>
                        <p className={classes.statValue1}>{item.horarioFim}</p>
                      </Box>
                    </Box>
                  </Card>
                ) : ('')
                }
              </ImageListItem>
            ))}
        </ImageList>
      </div>
    </Paper >

    
      <div style={{marginTop:'250px',textAlign:'center'}}>
        <img src="https://blog.reservarcannabis.com/wp-content/uploads/2023/03/swipe.gif" width="80" alt="Puedes navegar entre las fechas deslizando el dedo hacia la derecha o hacia la izquierda"/>
        <br></br><small>Puedes navegar entre las fechas deslizando el dedo hacia la derecha o hacia la izquierda.</small>
      </div>
</div>
  );
};

export default Cards;