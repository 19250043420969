import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { logoutUser } from "../../actions";
import MenuAppBar from './components/Appbar';
import Copyright from './components/Copyright';
import Typography from '@material-ui/core/Typography';
import { myFirebase, db } from "../../firebase/firebase";
import { Icon } from '@iconify/react';
import qrcodeIcon from '@iconify/icons-mdi/qrcode-scan';
import barcodeIcon from '@iconify/icons-mdi/barcode-scan';
import { makeStyles } from '@material-ui/core/styles';
import './../../css/styles.css';
import TabelaReservas from './components/TabelaReservas';
import { CircularProgress, Tab, Tabs } from '@mui/material';
import Chart from './Chart';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  content: {
    marginTop: 100
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  fixedHeightPaper: {
    minHeight: 190,
    maxHeight: 300,
    overflowY: 'auto'
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    textAlign: "center",
    paddingTop: 10
  },
  counter: {
    fontSize: 12,
    fontWeight: 500,
    textAlign: "center",
    paddingTop: 10,
  },
  box_title_amanha: {
    fontSize: 18,
    fontWeight: 500,
    textAlign: "center",
    paddingTop: 20,
  },
}));

const getTodayAndTomorrowDates = () => {
  const today = new Date();
  const tomorrow = new Date();
  today.setHours(0, 0, 0, 0);
  tomorrow.setHours(23, 59, 59, 0);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return { today, tomorrow };
};

const Home = () => {
  const classes = useStyles();
  const [valueTab, setValueTab] = useState(0);
  const [reservasHoje, setReservasHoje] = useState(0);
  const [reservadosHoje, setReservadosHoje] = useState(0);
  const [retiradosHoje, setRetiradosHoje] = useState(0);
  const [canceladosHoje, setCanceladosHoje] = useState(0);
  const [livresHoje, setLivresHoje] = useState(0);

  const [reservasAmanha, setReservasAmanha] = useState(0);
  const [reservadosAmanha, setReservadosAmanha] = useState(0);
  const [livresAmanha, setLivresAmanha] = useState(0);
  const [totalAmanha, setTotalAmanha] = useState(0);

  const [reservasFixedHoje, setReservasFixedHoje] = useState(0);
  const [reservadosFixedHoje, setReservadosFixedHoje] = useState(0);
  const [retiradosFixedHoje, setRetiradosFixedHoje] = useState(0);
  const [canceladosFixedHoje, setCanceladosFixedHoje] = useState(0);
  const [totalFixedHoje, setTotalFixedHoje] = useState(0);
  const [loading, setLoading] = useState(false);
  const farmaciaID = myFirebase.auth().currentUser.uid;
  const [chartDataNow, setChartDataNow] = useState({
    reservedNow: [], retiredNow: [], cancelledNow: []
  });
  const [chartDataTomorrow, setChartDataTomorrow] = useState({
    reserveTomorrow: [], reservedTomorrow: [], freeTomorrow: []
  });
  const [chartDataFixed, setChartDataFixed] = useState({
    reserveFixed: [], reservedFixed: [], cancelledFixed: []
  });
  // console.log('CHARTDATA => ', chartData);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    await getReservesNow();
    await getReservesTomorrow();
    await getAgendamentoFixed();
  };

  const getReservesNow = async () => {
    const today = new Date();
    const tomorrow = new Date();
    today.setHours(0, 0, 0, 0);
    tomorrow.setHours(23, 59, 59, 0);
    today.setDate(today.getDate());
    tomorrow.setDate(tomorrow.getDate());
    db.collection('reservas')
      .where("farmaciaID", "==", farmaciaID)
      .where("dataRetirar", ">=", today)
      .where("dataRetirar", "<=", tomorrow)
      .orderBy("dataRetirar", "desc")
      .onSnapshot(async ({ docs }) => {
        handleReservesNow(docs);
      });
  };

  function somarQuantidades(arrayA, arrayB) {
    const resultado = [];

    // Criar um mapa para facilitar a busca pelo ID no arrayB
    const mapaArrayB = new Map(arrayB.map(item => [item.id, item]));

    // Iterar sobre o arrayA
    for (const itemA of arrayA) {
      const id = itemA.id;
      const qtdeA = itemA.qtde;
      const itemB = mapaArrayB.get(id);

      if (itemB) {
        // Se o ID existir em ambos os arrays, atualizar a quantidade
        const qtdeB = itemB.qtde;
        const novaQtde = qtdeA + qtdeB;

        // Adicionar o objeto atualizado ao resultado
        resultado.push({
          ...itemA,
          qtde: novaQtde,
        });
      } else {
        // Se o ID não existir em arrayB, adicionar o objeto de arrayA ao resultado
        resultado.push(itemA);
      }
    }

    return resultado;
  }

  function handleOperation(arrayA, arrayB, tipoOperacao) {
    const resultado = [];

    const mapaArrayB = new Map(arrayB.map(item => [item.id, item]));

    for (const itemA of arrayA) {
      const id = itemA.id;
      const qtdeA = itemA.qtde;
      const itemB = mapaArrayB.get(id);

      if (itemB) {
        const qtdeB = itemB.qtde;
        let novaQtde;

        if (tipoOperacao === 'sum') {
          novaQtde = qtdeA + qtdeB;
        } else if (tipoOperacao === 'subtract') {
          novaQtde = qtdeA - qtdeB;
        } else {
          throw new Error('Tipo de operação não reconhecido');
        }

        resultado.push({
          ...itemA,
          qtde: novaQtde,
        });
      } else {
        resultado.push(itemA);
      }
    }

    return resultado;
  }


  const getAllProducts = (arrayA) => {
    let data = [];
    arrayA.map(item => data.push(item.products));
    return data;
  };

  const handleReservesNow = async (docs) => {
    const list = filterReserves(docs);
    console.log('HANDLE RESERVES NOW => ', list);

    if (list && !list.agendamentoID) {
      return;
    }

    const totalAgendamentos = await getAgendamentosByid(list.agendamentoID);
    setReservasHoje({ qtde: summedTotalArray(totalAgendamentos.products), products: totalAgendamentos.products });

    const reserved = sumObjectsArray(list.reservados, 'products', 'qtde');
    setReservadosHoje({ qtde: summedTotalArray(reserved), products: reserved });

    const retired = sumObjectsArray(list.retirados, 'products', 'qtde');
    setRetiradosHoje({ qtde: summedTotalArray(retired), products: retired });

    const cancelled = sumObjectsArray(list.cancelados, 'products', 'qtde');
    setCanceladosHoje({ qtde: summedTotalArray(cancelled), products: cancelled });

    /* CALCULO PARA AS RESERVAS LIVRES */
    const somaReservadosRetirados = handleOperation(reserved, retired, 'sum');
    console.log('RESULTADO SOMA RETIRADOS E RESERVADOS => ', somaReservadosRetirados);

    const resultadoSubtracao = handleOperation(totalAgendamentos.products, somaReservadosRetirados, 'subtract');
    console.log('RESULTADO SUBTRACAO => ', resultadoSubtracao);

    const totalLivresHoje = handleOperation(resultadoSubtracao, cancelled ?? [], 'sum');

    setLivresHoje({ qtde: summedTotalArray(totalLivresHoje), products: resultadoSubtracao });

    /* FIM CALCULO RESERVAS LIVRES */

    setChartDataNow({ ...chartDataNow, reserveNow: totalAgendamentos.products, reservedNow: reserved, retiredNow: retired, cancelledNow: cancelled, freeNow: totalLivresHoje });

  };

  const getReservesTomorrow = async () => {
    const today = new Date();
    const tomorrow = new Date();
    today.setHours(0, 0, 0, 0);
    tomorrow.setHours(23, 59, 59, 0);
    today.setDate(today.getDate());
    tomorrow.setDate(tomorrow.getDate());
    today.setDate(today.getDate() + 1);
    tomorrow.setDate(tomorrow.getDate() + 1);

    db.collection('reservas')
      .where("farmaciaID", "==", farmaciaID)
      .where("dataRetirar", ">=", today)
      .where("dataRetirar", "<=", tomorrow)
      .orderBy("dataRetirar", "desc")
      .onSnapshot(async ({ docs }) => {
        handleReservesTomorrow(docs);
      });
  };

  const handleReservesTomorrow = async (docs) => {
    const list = filterReserves(docs);

    // console.log('HANDLE RESERVES TOMORROW => ', list);
    if (list && !list.agendamentoID) {
      return;
    }

    const totalAgendamentos = await getAgendamentosByid(list.agendamentoID);
    // console.log('TOTAL PRODUTOS AGENDAMENTO TOMORROW => ', totalAgendamentos);

    const reserveTomorrow = totalAgendamentos.products;
    // console.log('RESERVETOMORROW => ', reserveTomorrow);
    setReservasAmanha({ qtde: summedTotalArray(totalAgendamentos.products), products: totalAgendamentos.products });

    const reservedTomorrow = sumObjectsArray(list.reservados, 'products', 'qtde');
    const cancelledTomorrow = sumObjectsArray(list.cancelados, 'products', 'qtde');
    const tot = subtractValuesObjects(reservedTomorrow, 'qtde', cancelledTomorrow);
    // console.log('TOT => ', tot);
    // console.log('RESERVED FIXED => ', reservedTomorrow);
    setReservadosAmanha({ qtde: summedTotalArray(reservedTomorrow), products: reservedTomorrow });

    let freeTomorrow = [];
    if (list && list.reservasAgendamento && list.reservasAgendamento.length && totalAgendamentos) {
      freeTomorrow = subtractValuesObjects(totalAgendamentos.products, 'qtde', reservedTomorrow);
      // console.log('FREE => ', freeTomorrow);
      setLivresAmanha({ qtde: summedTotalArray(freeTomorrow), products: freeTomorrow });
    }

    setChartDataTomorrow({ ...chartDataTomorrow, reserveTomorrow: reserveTomorrow, reservedTomorrow: reservedTomorrow, freeTomorrow: freeTomorrow });
  };

  const getAgendamentoFixed = async () => {
    db.collection("usuarios")
      .doc(farmaciaID)
      .collection("agendamentos")
      .where("fixed", "==", true)
      .onSnapshot(async ({ docs }) => {
        const agendID = docs.map((doc, idx) => {
          return doc.id;
        });
        if (agendID && agendID.length) {
          getReservesFixed(agendID[0]);
        }
      });
  };

  const getReservesFixed = async (agendID) => {
    db.collection('reservas')
      .where("agendamentoID", "==", agendID)
      .onSnapshot(async ({ docs }) => {
        handleReservesFixed(docs);
      });
  };

  const handleReservesFixed = async (docs) => {
    const list = filterReserves(docs);
    // console.log('HANDLE RESERVES FIXED => ', list);

    const totalAgendamentos = await getAgendamentosByid(list.agendamentoID);

    // console.log('TOTAL PRODUTOS AGENDAMENTO FIXED=> ', totalAgendamentos);

    // list.total = totalAgendamentos;
    const reserveFixed = totalAgendamentos.products;//sumObjectsArray(list.reservasAgendamento, 'products', 'qtde');
    setReservasFixedHoje({ qtde: summedTotalArray(totalAgendamentos.products), products: totalAgendamentos.products });

    const reservedFixed = sumObjectsArray(list.reservados, 'products', 'qtde');
    // console.log('RESERVED TOMORROW => ', reservedFixed);
    setReservadosFixedHoje({ qtde: summedTotalArray(reservedFixed), products: reservedFixed });

    if (list && list.reservasAgendamento && list.reservasAgendamento.length && totalAgendamentos) {
      const free = subtractValuesObjects(totalAgendamentos.products, 'qtde', reservedFixed);
      // console.log('FREE => ', free);
      // setLivresFixedAmanha({ qtde: summedTotalArray(free), products: free });
    }

    const retiredFixed = sumObjectsArray(list.retirados, 'products', 'qtde');
    // console.log('RETIRED => ', retiredFixed);
    setRetiradosFixedHoje({ qtde: summedTotalArray(retiredFixed), products: retiredFixed });

    const cancelledFixed = sumObjectsArray(list.cancelados, 'products', 'qtde');
    // console.log('CANCELLED => ', cancelledFixed);
    setCanceladosFixedHoje({ qtde: summedTotalArray(cancelledFixed), products: cancelledFixed });

    setChartDataFixed({ ...chartDataFixed, reserveFixed: reserveFixed, reservedFixed: reservedFixed, retiredFixed: retiredFixed, cancelledFixed: cancelledFixed });
  };

  const filterReserves = (docs) => {
    let agendID = null;
    const list = [];
    const rsh = [];
    const rth = [];
    const ch = [];
    docs.forEach(function (d) {
      if (d.data().status === 'reservado') {
        rsh.push({ id: d.id, ...d.data() });
      }

      if (d.data().status === 'cancelado') {
        ch.push({ id: d.id, ...d.data() });
      }

      if (d.data().status === 'retirado') {
        rth.push({ id: d.id, ...d.data() });
      }
      agendID = d.data().agendamentoID;
      list.push({ id: d.id, ...d.data() });
    });
    return { agendamentoID: agendID, reservasAgendamento: list, reservados: rsh, retirados: rth, cancelados: ch };
  };

  const summedTotalArray = (products) => {
    if (products && products.length) {
      const total = products.reduce((acumulador, produto) => {
        return acumulador + parseInt(produto.qtde);
      }, 0);
      return total;
    }
  };

  const sumObjectsArray = (list, object, field) => {
    if (list && list.length) {
      const summedObjects = {};
      for (const item of list) {
        let itemObject = item[object];
        // console.log('ITEM[OBJECT] => ', item[object], itemObject);
        if (itemObject && itemObject.length) {
          for (const obj of item[object]) {
            const { id } = obj;
            if (!summedObjects[id]) {
              summedObjects[id] = { ...obj, [field]: 0 };
            }
            summedObjects[id][field] += obj[field];
          }
        }
      }
      const summedArray = Object.values(summedObjects);
      for (const summedItem of summedArray) {
        summedItem[field] = summedItem[field];
        delete summedItem.total;
      }
      return summedArray;
    }
  };

  const subtractValuesObjects = (list, field, listSubtract) => {
    if (list && list.length) {
      const result = [];
      for (let i = 0; i < list.length; i++) {
        const listItem = list[i];
        if (!listSubtract)
          return;
        const items = listSubtract.find(item => item.id === listItem.id);
        if (items) {
          const newQtde = listItem[field] - items[field];
          result.push({
            ...listItem,
            [field]: newQtde,
          });
        }
      }
      return result;
    }
  };

  const getAgendamentosByid = async (id) => {
    const querySnapshot = await db
      .collection("usuarios")
      .doc(farmaciaID)
      .collection("agendamentos")
      .where("agendamentoID", "==", id)
      .get();

    if (querySnapshot.empty) {
      return [];
    }

    const tempDoc = querySnapshot.docs.map((doc) => {

      const result = doc.data().products.reduce((acumulador, produto) => {
        return acumulador + parseInt(produto.qtde);
      }, 0);

      return { id: doc.id, ...doc.data(), qtdeTotal: result };
    });

    return tempDoc[0];
  };

  const getIntervalDate = () => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
    return { start: firstDayOfMonth, end: tomorrow };
  };

  const ListProducts = ({ products }) => {
    if (loading) {
      return (
        <div style={{ display: "flex", flex: 1, justifyContent: 'center', alignItems: 'center', padding: 20 }}>
          <CircularProgress size={20} />
        </div>
      );
    }
    if (!products.length) {
      return null;
    } else {
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {products
            // .filter(product => product.qtde > 0)
            .sort((a, b) => a.text.localeCompare(b.text))
            .map(product => {
              return (
                <div
                  key={product.id}
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottomStyle: 'solid', borderBottomWidth: 1, borderBottomColor: product.color, marginInline: 2, paddingLeft: 5, paddingRight: 5, fontSize: 11, color: product.color, marginTop: 10 }}>
                  <div>{`${product.text}`}</div>
                  <div>{`${product.qtde}`}</div>
                </div>
              );

            })}
        </div>
      );
    }
  };

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };


  const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 2 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const ComponentHeaderScanners = () => (
    <Grid container spacing={2} style={{ marginBottom: 10 }}>
      <Grid item xs={6} sm={6} md={6} lg={6} style={{ height: 150 }}>
        <Paper>
          <Typography className={classes.title}>
            Escanear con Telefono
          </Typography>
          <Typography align="center" style={{ paddingTop: 20 }} variant="h2" component="h4">
            <Link to={{ pathname: 'QrCodeScreen' }}>
              <Icon icon={qrcodeIcon} className="home_icons" />
            </Link>
          </Typography>
        </Paper>
      </Grid>

      <Grid item xs={6} sm={6} md={6} lg={6}>
        <Paper>
          <Typography className={classes.title}>
            Escanear con la Compu
          </Typography>
          <Typography align="center" style={{ paddingTop: 20 }} variant="h2" component="h4">
            <Link to={{ pathname: 'BarCodeScreen' }}>
              <Icon icon={barcodeIcon} className="home_icons" />
            </Link>
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );

  const ContainerCards = () => (
    <Grid spacing={2}>

      <ComponentHeaderScanners />
      {/*------------------------- HOJE-------------------------- */}

      <Grid container spacing={2}>
        <Grid item xs={6} sm={4} md={3} lg={3}>
          <Paper className={classes.fixedHeightPaper}>
            <Typography className={classes.title}>
              Total p/ Hoy
            </Typography>
            <ListProducts products={reservasHoje && reservasHoje.products ? reservasHoje.products : []} />
            {/* <Typography align="center" style={{ paddingTop: '10%' }} variant="h2" component="h4">
                    {totalHoje}
                  </Typography> */}
            <Typography className={classes.counter}>
              {reservasHoje.qtde}
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={6} sm={4} md={3} lg={3}>
          <Paper className={classes.fixedHeightPaper}>
            <Typography className={classes.title}>
              Esperando Retirada
            </Typography>
            <ListProducts products={reservadosHoje && reservadosHoje.products ? reservadosHoje.products : []} />
            <Typography className={classes.counter}>
              {reservadosHoje.qtde}
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={6} sm={4} md={3} lg={2}>
          <Paper className={classes.fixedHeightPaper}>
            <Typography className={classes.title}>
              Retirados Hoy
            </Typography>
            <ListProducts products={retiradosHoje && retiradosHoje.products ? retiradosHoje.products : []} />
            <Typography className={classes.counter}>
              {retiradosHoje.qtde}
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={6} sm={4} md={3} lg={2}>
          <Paper className={classes.fixedHeightPaper}>
            <Typography className={classes.title}>
              Cancelados Hoy
            </Typography>
            <ListProducts products={canceladosHoje && canceladosHoje.products ? canceladosHoje.products : []} />
            <Typography className={classes.counter}>
              {canceladosHoje.qtde}
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={6} sm={4} md={3} lg={2}>
          <Paper className={classes.fixedHeightPaper}>
            <Typography className={classes.title}>
              Libre p/ Hoy
            </Typography>
            <ListProducts products={livresHoje && livresHoje.products ? livresHoje.products : []} />
            <Typography className={classes.counter}>
              {livresHoje.qtde}
            </Typography>
          </Paper>
        </Grid>
      </Grid>


      {/*-------------------------FIM HOJE-------------------------- */}


      {/*------------------------- AMANHA-------------------------- */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Paper className={classes.fixedHeightPaper}>
            <Typography className={classes.box_title_amanha}>
              Total p/ Mañana
            </Typography>
            <ListProducts products={reservasAmanha && reservasAmanha.products ? reservasAmanha.products : []} />
            <Typography className={classes.counter}>
              {reservasAmanha.qtde}
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Paper className={classes.fixedHeightPaper}>
            <Typography className={classes.box_title_amanha}>
              Reservados Mañana
            </Typography>
            <ListProducts products={reservadosAmanha && reservadosAmanha.products ? reservadosAmanha.products : []} />
            <Typography className={classes.counter}>
              {reservadosAmanha.qtde}
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Paper className={classes.fixedHeightPaper}>
            <Typography className={classes.box_title_amanha}>
              Libre p/ Mañana
            </Typography>
            <ListProducts products={livresAmanha && livresAmanha.products ? livresAmanha.products : []} />
            <Typography className={classes.counter}>
              {livresAmanha.qtde}
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      {/*------------------------- FIM AMANHA-------------------------- */}


      {/*------------------------- DESTACADO-------------------------- */}

      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item xs={6} sm={6} md={3} lg={3}>
          <Paper className={classes.fixedHeightPaper}>
            <Typography className={classes.title}>
              Total p/ Destacado
            </Typography>
            <ListProducts products={reservasFixedHoje && reservasFixedHoje.products ? reservasFixedHoje.products : []} />
            <Typography className={classes.counter}>
              {reservasFixedHoje.qtde}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={3}>
          <Paper className={classes.fixedHeightPaper}>
            <Typography className={classes.title}>
              Destacado - Reservas
            </Typography>
            <ListProducts products={reservadosFixedHoje && reservadosFixedHoje.products ? reservadosFixedHoje.products : []} />
            <Typography className={classes.counter}>
              {reservadosFixedHoje.qtde}
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3}>
          <Paper className={classes.fixedHeightPaper}>
            <Typography className={classes.title}>
              Destacado - Retirado
            </Typography>
            <ListProducts products={retiradosFixedHoje && retiradosFixedHoje.products ? retiradosFixedHoje.products : []} />
            <Typography className={classes.counter}>
              {retiradosFixedHoje.qtde}
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3}>
          <Paper className={classes.fixedHeightPaper}>
            <Typography className={classes.title}>
              Destacado-Cancelados
            </Typography>
            <ListProducts products={canceladosFixedHoje && canceladosFixedHoje.products ? canceladosFixedHoje.products : []} />
            <Typography className={classes.counter}>
              {canceladosFixedHoje.qtde}
            </Typography>
          </Paper>
        </Grid>

        {/*------------------------- FIM DESTACADO-------------------------- */}
      </Grid>
    </Grid>
  );

  const ContainerCharts = () => (
    <Grid style={{ paddingTop: 0, paddingBottom: 0 }}>

      <ComponentHeaderScanners />

      {chartDataNow
        && chartDataNow.reservedNow
        && chartDataNow.reservedNow.length
        || chartDataNow.retiredNow
        && chartDataNow.retiredNow.length
        || chartDataNow.cancelledNow
        && chartDataNow.cancelledNow.length
        || chartDataNow.freeNow
        && chartDataNow.freeNow.length
        || chartDataNow.reserveNow
        && chartDataNow.reserveNow.length ? (
        <Paper style={{ marginBottom: 20 }}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography className={classes.title}>
                Hoy
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} style={{ width: '100%', height: 200, padding: 20 }}>
              <Chart data={chartDataNow.reserveNow} />
              <Typography variant="caption" align='center'>
                Total p/ hoy
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} style={{ width: '100%', height: 200, padding: 20 }}>
              <Chart data={chartDataNow.reservedNow} />
              <Typography variant="caption" align='center'>
                Esperando retirada
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} style={{ width: '100%', height: 200, padding: 20 }}>
              <Chart data={chartDataNow.retiredNow} />
              <Typography variant="caption" align='center'>
                Retirados
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} style={{ width: '100%', height: 200, padding: 20 }}>
              <Chart data={chartDataNow.cancelledNow} />
              <Typography variant="caption" align='center'>
                Cancelados
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} style={{ width: '100%', height: 200, padding: 20 }}>
              <Chart data={chartDataNow.freeNow} />
              <Typography variant="caption" align='center'>
                Libres
              </Typography>
            </Grid>

          </Grid>
        </Paper>
      ) : ('')}

      {chartDataTomorrow && chartDataTomorrow.reserveTomorrow && chartDataTomorrow.reserveTomorrow.length || chartDataTomorrow.reservedTomorrow && chartDataTomorrow.reservedTomorrow.length || chartDataTomorrow.freeTomorrow && chartDataTomorrow.freeTomorrow.length ? (
        <Paper style={{ marginBottom: 20 }}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography className={classes.title}>
                Mañana
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} style={{ width: '100%', height: 200, padding: 20 }}>
              <Chart data={chartDataTomorrow.reserveTomorrow} />
              <Typography variant="caption" align='center'>
                Total p/ Mañana
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} style={{ width: '100%', height: 200, padding: 20 }}>
              <Chart data={chartDataTomorrow.reservedTomorrow} />
              <Typography variant="caption" align='center'>
                Reservados Mañana
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} style={{ width: '100%', height: 200, padding: 20 }}>
              <Chart data={chartDataTomorrow.freeTomorrow} />
              <Typography variant="caption" align='center'>
                Libre p/ Mañana
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      ) : ('')}

      {chartDataFixed && chartDataFixed.reserveFixed && chartDataFixed.reserveFixed.length || chartDataFixed.reservedFixed && chartDataFixed.reservedFixed.length || chartDataFixed.cancelledFixed && chartDataFixed.cancelledFixed.length || chartDataFixed.retiredFixed && chartDataFixed.retiredFixed.length ? (
        <Paper style={{ marginBottom: 20 }}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography className={classes.title}>
                Destacado
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={3} style={{ width: '100%', height: 200, padding: 20 }}>
              <Chart data={chartDataFixed.reserveFixed} />
              <Typography variant="caption" align='center'>
                Total p/ destacado
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={3} style={{ width: '100%', height: 200, padding: 20 }}>
              <Chart data={chartDataFixed.reservedFixed} />
              <Typography variant="caption" align='center'>
                Reservados
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={3} style={{ width: '100%', height: 200, padding: 20 }}>
              <Chart data={chartDataFixed.retiredFixed} />
              <Typography variant="caption" align='center'>
                Retirados
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={3} style={{ width: '100%', height: 200, padding: 20 }}>
              <Chart data={chartDataFixed.cancelledFixed} />
              <Typography variant="caption" align='center'>
                Cancelados
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      ) : ('')}
    </Grid>
  );


  const ContainerTabs = () => (
    <Box style={{ backgroundColor: 'transparent' }} sx={{ width: '100%', marginTop: -30 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
        <Tabs value={valueTab} onChange={handleChangeTab} variant="fullWidth">
          <Tab label="Reporte en listado" {...a11yProps(0)} />
          <Tab label="Reporte en grafico" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={valueTab} index={0}>
        <ContainerCards />
      </CustomTabPanel>
      <CustomTabPanel value={valueTab} index={1}>
        <ContainerCharts />
      </CustomTabPanel>
    </Box>
  );
  return (
    <div className={classes.root}>
      <CssBaseline />
      <MenuAppBar />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>

          {/* <ContainerCards /> */}
          <ContainerTabs />
          <Grid item xs={12} style={{ marginTop: 10 }} >
            <Paper className={classes.paper}>
              <TabelaReservas limitFilter={3000} />
            </Paper>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>

    </div >
  );
};
function mapStateToProps(state) {
  return {
    isLoggingOut: state.auth.isLoggingOut,
    logoutError: state.auth.logoutError
  };
}

const mapDispatchToProps = dispatch => ({
  Deslogar() {
    dispatch(logoutUser());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);
