import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/functions';
import 'firebase/messaging';

const firebaseConfig = {
	apiKey: 'AIzaSyCpHMTKN10ARAHBe_b3oBgUjES1WuDNiLA',
	authDomain: 'reservar-cannabis-uru.firebaseapp.com',
	databaseURL: 'https://reservar-cannabis-uru.firebaseio.com',
	projectId: 'reservar-cannabis-uru',
	storageBucket: 'reservar-cannabis-uru.appspot.com',
	messagingSenderId: '733833905806',
	appId: '1:733833905806:web:f61f2348b404ef253eebdf',
	measurementId: 'G-MZFVNWSLCN',
};

const googleApiKey = 'AIzaSyBSbDGzU_uSzXbd4bs7D0D6sMC_C25BNhc';

export const fbURL = 'https://us-central1-reservar-cannabis-uru.cloudfunctions.net';

export const myFirebase = firebase.initializeApp(firebaseConfig);
const baseDb = myFirebase.firestore();
export const db = baseDb;
const analyticsBase = firebase.analytics();
export const analytics = analyticsBase;
const functionsFirebase = firebase.functions();
export const funcFirebase = functionsFirebase;

let messaging = null;
export const firebaseMessaging = messaging;

export const askForPermissioToReceiveNotifications = async () => {
	try {
		const messaging = firebase.messaging();
		await messaging.requestPermission();
		const token = await messaging.getToken();
		console.log('user token: ', token);
		return token;
	} catch (error) {
		console.error(error);
	}
};
