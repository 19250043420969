import { Chip, Grid, Paper } from '@mui/material';
import moment from 'moment';
import React from 'react';
import useStyles from '../../Styles';

const TableFirstResult = ({ rows }) => {
    console.log('ROWS => ', rows);
    const classes = useStyles();

    const GetChip = ({ status }) => {

        if (status === 'reservado')
            return <Chip label="Aguardando Retirada" color="default" />;

        if (status === 'retirado')
            return <Chip label="Retirado" color="primary" />;

        return <Chip label="Cancelado" color="secondary" />;
    };

    const ProductItem = ({ product }) => (
        <div key={product.id}
            style={{ borderStyle: 'solid', borderRadius: 20, borderWidth: 1, borderColor: product.color, marginInline: 2, paddingInline: 5, fontSize: 11, color: product.color }}>
            {`${product.text} - ${product.qtde}`}
        </div>
    );
    return (
        <Grid item xs={12}>
            <Paper className={classes.paper}>
                <div style={{ background: 'transparent' }}>
                    <div style={{ background: 'transparent' }}>
                        <h5 style={{ fontWeight: '500', fontSize: 18 }}>Ultima reserva leída</h5>
                    </div>
                    <table id="table_reserve" className="table">
                        <thead>
                            <tr>
                                <th className="order">Nombre</th>
                                <th className="order">Status</th>
                                <th className="">Variedad</th>
                                <th data-sort="">Fecha de retirada</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map(((item, idx) => {
                                if (idx === 0) {
                                    return (
                                        <tr key={idx}>
                                            <td>{item?.nome}</td>
                                            <td><GetChip status={item.status} /></td>
                                            <td style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', alignItems: 'center' }}>
                                                {item.products && item.products.map((product,idx) => {
                                                    if (product.qtde > 0) {
                                                        return (
                                                            <ProductItem key={idx} product={product} />
                                                        );
                                                    }
                                                })}
                                            </td>
                                            <td>{moment.unix(item.dataRetirar.seconds).format("DD/MM/YYYY HH:mm")}</td>
                                            {/* <td>{item.dataRetirar.toDate().toLocaleString('pt-BR')}</td> */}
                                        </tr>
                                    );
                                }
                            }))
                            }
                        </tbody>
                    </table>
                </div>
            </Paper>
        </Grid>
    );
};

export default TableFirstResult;