import React from "react";
import { Route, Switch } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import Home from "./components/dashboard/Home";
import meusDados from "./components/dashboard/MeusDados";
import listaReservas from "./components/dashboard/listaReservas";
import Calendario from "./components/dashboard/Calendario";
import Login from "./components/Login";
import Inicial from "./components/Inicial";
import Reserva from "./components/Reserva";
import CancelarReserva from "./components/CancelarReserva";
import PreencherReserva from "./components/PreencherReserva";
import ListEmailReserva from "./components/ListEmailReserva";
import QRCodeReserva from "./components/QRCodeReserva";
import SendNoticeEmail from './components/SendNoticeEmail';
import SendNoticeEmailAdminz from './components/SendNoticeEmailAdminz';
import SendNoticeEmailAdmin from './components/SendNoticeEmailAdmin';
import Disconnect from "./components/Disconnect";
import Iframe from "./components/Iframe";
import Products from "./components/Product/Products";
import BarCodeScreen from "./components/dashboard/components/Scanners/BarCodeScreen";
import QRCodeScreen from "./components/dashboard/components/Scanners/QrCodeScreen";
import ScannerScreen from "./components/dashboard/components/Scanners/ScannerScreen";

const Routes = ({ isAuthenticated, isVerifying }) => {

  return (

    <Switch>
      <ProtectedRoute
        exact
        path="/Dashboard"
        component={Home}
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
      />
      <ProtectedRoute
        exact
        path="/MeusDados"
        component={meusDados}
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
      />
      <ProtectedRoute
        exact
        path="/ListaReservas"
        component={listaReservas}
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
      />
      <ProtectedRoute
        exact
        path="/Calendario"
        component={Calendario}
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
      />
      <ProtectedRoute
        exact
        path="/products-admin"
        component={Products}
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
      />
      <ProtectedRoute
        exact
        path="/ListEmailReserva"
        component={ListEmailReserva}
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
      />
      <ProtectedRoute
        exact
        path="/avisos"
        component={SendNoticeEmail}
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
      />
      <ProtectedRoute
        exact
        path="/avisos-especial"
        component={SendNoticeEmailAdminz}
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
      />
      <ProtectedRoute
        exact
        path="/avisos-admin"
        component={SendNoticeEmailAdmin}
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
      />
      <ProtectedRoute
        exact
        path="/QrCodeScreen"
        component={QRCodeScreen}
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
      />
      <ProtectedRoute
        exact
        path="/BarCodeScreen"
        component={BarCodeScreen}
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
      />
      <ProtectedRoute
        exact
        path="/ScannerScreen"
        component={ScannerScreen}
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
      />

      <Route path="/queue" render={({ location, match, history }) => {
        if (!location.state)
          history.replace({ pathname: "/", state: false });

        return location.state ? <Reserva location={location} /> : <Inicial />;
      }} />
      <Route path="/preencherReserva" render={({ location, match, history }) => {
        if (!location.state)
          history.replace({ pathname: "/", state: false });

        return location.state ? <PreencherReserva location={location} /> : <Inicial />;
      }} />
      <Route path="/qrcodereserva" render={({ location, match, history }) => {
        if (!location.state)
          history.replace({ pathname: "/", state: false });

        return location.state ? <QRCodeReserva location={location} /> : <Inicial />;
      }} />

      <Route path="/iframe" component={Iframe} />
      <Route path="/cancelar-reserva" component={CancelarReserva} />
      <Route path="/disconnect" component={Disconnect} />
      <Route path="/login" component={Login} />
      <Route path="/" component={Inicial} />

    </Switch>
  );
};

export default Routes;