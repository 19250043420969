import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { db, myFirebase } from '../../firebase/firebase';
import { logoutUser } from "../../actions";
import Swal from 'sweetalert2';
import MenuAppBar from '../../components/dashboard/components/Appbar';
import Copyright from '../../components/dashboard/components/Copyright';
import useStyles from '../../components/dashboard/Styles';
import ProductFormDialog from './ProductFormDialog';
import { Avatar, Button, Container, Divider, IconButton, LinearProgress, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography } from '@mui/material';
import { ColorLens, Delete, Edit } from '@mui/icons-material';
import { fakerAsync } from '../dashboard/components/Helpers';
import { reverse } from 'lodash';
import ProductCard from './ProductCard';

const mockProduct = {
    color: "#3C1ED3",
    id: '',
    name: '',
    description: '',
    total: 10,
    minimum: 1,
    maximum: 1,
    status: false,
};

const Products = (props) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const userId = myFirebase.auth().currentUser.uid;
    const [open, setOpen] = useState(false);
    const [listProducts, setListProducts] = useState([]);
    const [product, setProduct] = useState(mockProduct);
    const [title, setTitle] = useState();
    let callback = () => { };

    useEffect(() => {
        setLoading(true);
        db.collection("products")
            .orderBy("name", "asc")
            .onSnapshot(async (querySnapshot) => {
                let dados = [];
                let docs = querySnapshot.docs;
                for (let ag of docs) {
                    dados.push({
                        id: ag.id,
                        name: ag.data().name,
                        description: ag.data().description,
                        color: ag.data().color,
                        total: ag.data().total,
                        minimum: ag.data().minimum,
                        maximum: ag.data().maximum,
                        status: ag.data().status,
                    });
                }
                setListProducts(dados);
            });
        setLoading(false);
    }, []);

    const handleChange = (event) => {
        console.log('HANDLE CHANGE => ', event);
        const name = event.target.name;
        setProduct({
            ...product,
            [name]: event.target.value,
        });
    };

    const onCancel = () => {
        setOpen(false);
        setTitle('');
    };

    const onConfirm = (item) => {
        setLoading(true);
        console.log('ON CONFIRM => ', item);
        setOpen(false);
        !item.id ? saveProduct(item) : updateProduct(item);
    };

    const addProduct = () => {
        console.log('ADD PRODUCT => ',);
        setTitle('Adicionar novo');
        setOpen(true);

    };
    const saveProduct = async (item) => {
        console.log('SAVE PRODUCT => ', item);
        await fakerAsync(3000);
        try {
            db.collection("products")
                .add(item).then(ref => {
                    const id = ref.id;
                    console.log('ID => ', id);
                    updateSavedProduct(id);
                });
            setProduct(mockProduct);
        } catch (error) {
            console.log('ERROR SAVE PRODUCT => ', error);
            Swal.fire(
                'Erro!',
                error.toString(),
                'error'
            );
        }
        setLoading(false);
    };

    const updateSavedProduct = (id) => {
        console.log('UPDATESAVEDPRODUCT => ', id);
        db.collection("products")
            .doc(id).set({ id }, { merge: true });
    };

    const handleEdit = (item) => {
        console.log('EDIT PRODUCT => ', item);
        setTitle("Editar");
        setProduct(item);
        setOpen(true);
    };

    const updateProduct = async (item) => {
        console.log('UPDATE PRODUCT => ', product);
        await fakerAsync(1000);
        try {
            db.collection("products")
                .doc(item.id).set(item, { merge: true });
            setProduct(mockProduct);
        } catch (error) {
            console.log('ERROR UPDATE PRODUCT => ', error);
            Swal.fire(
                'Erro!',
                error.toString(),
                'error'
            );
        }
        setLoading(false);
    };

    const handleChangeColor = (color, event) => {
        console.log('COLOR => ', color);
        console.log('EVENT => ', event.target);
        setProduct({
            ...product,
            color: color.hex,
        });
    };

    const handleDelete = (id) => {
        console.log('HANDLE DELETE => ', id);
        Swal.fire({
            title: 'Excluir',
            text: "Confirma a exclusão do produto?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                onDelete(id);
            }
        });
    };

    const onDelete = (id) => {
        console.log('ON DELETE => ', id);
        try {
            db.collection("products")
                .doc(id).delete();
            Swal.fire(
                'Deletado!',
                'Produto deletado com sucesso!',
                'success'
            );
        } catch (error) {
            Swal.fire(
                'Erro!',
                error.toString(),
                'error'
            );
        }
    };


    return (
        <div className={classes.root}>
            <CssBaseline />
            <MenuAppBar />
            {loading && (
                <LinearProgress style={{ marginTop: 64 }} />
            )}
            <main className={classes.content}>

                <div className={classes.appBarSpacer} />
                <Container className={classes.container}>

                    <Grid container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center">

                        <h3 style={{ padding: 2 }}>Productos</h3>

                        <Button variant="outlined" color="primary" onClick={() => addProduct()}>
                            Add new
                        </Button>

                    </Grid>
                    <Grid item xs={12}>

                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            {!!listProducts && listProducts.map((item, idx) => (
                                <ProductCard key={idx} item={item} onEdit={handleEdit} onDelete={handleDelete} />
                            ))}
                        </div>

                        {/* <div className={classes.demo}>
                            <List>
                                {!!listProducts && listProducts.map((item, idx) => (
                                    <div key={idx}>
                                        <ListItem >
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <ColorLens style={{ color: item.color }} />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={item.name}
                                                secondary={item.text}
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton edge="start" aria-label="edit" onClick={() => handleEdit(item)}>
                                                    <Edit />
                                                </IconButton>
                                                <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(item.idProduct)}>
                                                    <Delete />
                                                </IconButton>
                                            </ListItemSecondaryAction>

                                        </ListItem>
                                        <Divider light />
                                    </div>
                                ))}
                            </List>
                        </div> */}
                    </Grid>

                    <Grid item xs={12}>
                        <ProductFormDialog
                            title={title}
                            open={open}
                            onCancel={onCancel}
                            onConfirm={onConfirm}
                            product={product}
                            onChange={handleChange}
                            setProduct={setProduct}
                            onColor={handleChangeColor}
                        />
                    </Grid>
                </Container>
                <Copyright />
            </main>
        </div>
    );
};
function mapStateToProps(state) {
    return {
        isLoggingOut: state.auth.isLoggingOut,
        logoutError: state.auth.logoutError
    };
}

const mapDispatchToProps = dispatch => ({
    Deslogar() {
        dispatch(logoutUser());
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Products);
