import React from 'react';

import '../../../css/styles.css';

function LegendReservas() {

  return (
    <div className="containerLegend">
      <h4>Quedate atento en los colores</h4>
      <ul className="legend">
        <li><span className="span_legend proxima"></span> <span className="txt_legend">Proximas fechas de reservas</span></li>
        <li><span className="span_legend pronto"></span> <span className="txt_legend">Disponible pronto</span></li>

        <li><span className="span_legend esgotadas"></span> <span className="txt_legend">Reservas agotadas</span></li>
        <li><span className="span_legend disponible"></span> <span className="txt_legend">Fecha disponible</span></li>
      </ul>
    </div>
  )
}

export default LegendReservas;