import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { connect } from "react-redux";
import Parser from 'html-react-parser';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { logoutUser } from "../../actions";
import MenuAppBar from './components/Appbar';
import Copyright from './components/Copyright';
import useStyles from './Styles';
import { myFirebase, db } from "../../firebase/firebase";

const MeusDados = (props) => {
  const classes = useStyles();
  const farmaciaID = myFirebase.auth().currentUser.uid;
  const [farmaciaData, setfarmaciaData] = useState('');
  const [reservas, setreservas] = useState(0);
  const [diaVencimento, setdiaVencimento] = useState(0);

  useEffect(() => {
    db.collection('usuarios').doc(farmaciaID)
      .onSnapshot((docRef) => {
        const data = docRef.data();
        handleData(data);
      });
  }, []);

  const handleData = (data) => {
    console.log('DATA => ', data);
    var t = new Date();
    const diaVencimento = new Date(t.getFullYear(), t.getMonth() + 1, data.diaVencimento, 23, 59, 59);
    setdiaVencimento(data.diaVencimento);
    setfarmaciaData(`
          <div>
            <img style="width:150px;" alt="logo farmacia" src="${data.logo}" /><br>
            <h5>Tus Informaciones:</h5>
            <b>Nombre:</b> ${data.nome}<br/>
            <b>Mail:</b> ${data.email}<br/>
            <b>Calle:</b> ${data.endereco.rua}<br/>
            <b>Barrio:</b> ${data.endereco.bairro}<br/>
            <b>Provincia:</b> ${data.endereco.provincia}<br/>
            <b>Ciudad:</b> ${data.endereco.cidade}<br/>
            <b>Figura en:</b> ${data.endereco.regiao == 2 ? 'Interior' : 'Montevideo'}<br/>
            <b>Status:</b> ${data.mostrar ? 'Activa' : 'Desactivada'}<br/>
            <hr>
            <h5>¿Cómo acceder? </h5>
          <code>  Si necesitas pasar tu configuración a otro compañero(a) podes copiar y pegar abajo:<br/>
          🤳 💻  https://reservarcannabis.com/login<br/>
            El usuario es 👉   ${data.email}<br/>
            La contraseña es 👉 (tu_contrasena)<br/>
            <h4>Ayuda:</h4>
              💁‍♂️ Para saber como utilizar la aplicación debes entrar en:<br/>
              📄 <a href="https://blog.reservarcannabis.com/como-utilizar-la-herramienta-administrativa" target="_blank">https://blog.reservarcannabis.com/como-utilizar-la-herramienta-administrativa</a>
              </code> <br>
            🗝️👉 Contraseña para leer es:  como123    
            <hr>
              Número de reservas utilizado: 
              ${reservas}<br/>
              Número de reservas contractado: 
              ${data.numeros}
            <hr>
            <h5>Pago:</h5>
            <br>
            Jonas Rafael Rossatto<br>
            💳 Tarjeta Midinero - Número: 4846276<br>
            🪪 Cédula: 6.300.731-6<br/>
            🪙 Forma de Pago: Mensual<br/>
            🗓️ Día del Vencimento: ${diaVencimento.toLocaleDateString('es-ES', { day: '2-digit' })} de cada mês;<br/>
            <hr>
            ⚠️ Si deseas darte de baja del ReservarCannabis se ruega avisar 3 meses por adelantado para que se pueda hacer los cambios necesarios. Pedimos también que no paren de regalar reservas - aún que sean en cantidad mas bajas - de una para evitar que tu cliente/consumidor no sufra con este cambio de la nada.
          </div>
          `);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <MenuAppBar />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={12} lg={12}>
              <Paper style={{ padding: '20px' }}>
                <h3 style={{ paddingLeft: '10px', color: '#000' }}>Logueado como:</h3>
                {Parser(farmaciaData)}
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    isLoggingOut: state.auth.isLoggingOut,
    logoutError: state.auth.logoutError
  };
}

const mapDispatchToProps = dispatch => ({
  Deslogar() {
    dispatch(logoutUser());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(MeusDados);
