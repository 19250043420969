import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import { logoutUser } from "../../../../actions";
import { FormControl, InputAdornment, TextField } from '@material-ui/core';
import { BiBarcodeReader } from "react-icons/bi";
import useStyles from '../../Styles';

const BCReader = ({ label, onSubmit }) => {
  const classes = useStyles();
  return (

    <form onSubmit={onSubmit} className={classes.barcodeForm} >
      <FormControl fullWidth className={classes.margin}>
        <Grid container alignItems='center' justifyContent='center'>
          <BiBarcodeReader size={140} color={'#020202'} />
        </Grid>
        <TextField
          fullWidth
          autoFocus={true}
          // variant=""
          className={classes.barcodeInput}
          id="barcode"
          name={"code"}
          label="Apunte el QRCODE hacia el scanner"
          InputProps={{
            style: { fontSize: 40, marginTop: '20px' },
            // endAdornment: (
            //   <InputAdornment position="end">
            //     <BiBarcodeReader size={36} />
            //   </InputAdornment>
            // ),
          }}
          // inputProps={{style: {fontSize: 40}}} // font size of input text
          InputLabelProps={{ style: { fontSize: 22, color: '#333333', marginLeft: 10 } }}
        />
      </FormControl>
    </form>

  );
};

const mapStateToProps = (state) => {
  console.log('STATE => ', state);
  return {
    isLoggingOut: state.auth.isLoggingOut,
    logoutError: state.auth.logoutError,
    user: state.auth.user
  };
};

const mapDispatchToProps = dispatch => ({
  Deslogar() {
    dispatch(logoutUser());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(BCReader);
