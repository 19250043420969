import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { logoutUser } from "../../../actions";
import axios from 'axios';
import uuid from 'react-uuid';
import MaterialTable from 'material-table';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';

import { myFirebase, db } from "../../../firebase/firebase";
import { green, grey, red, orange } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AlarmOn from '@material-ui/icons/AlarmOn';
import CancelIcon from '@material-ui/icons/Cancel';
import isSameDay from 'date-fns/isSameDay';
import format from 'date-fns/format';
import Swal from 'sweetalert2';
import { IconButton } from '@mui/material';
import SimpleCustomTable from './Table/SimpleCustomTable';
import Table from './TableSimple/Table';
import { LinearProgress } from '@material-ui/core';
import TableScreen from './TableCustom/TableScreen';

const TabelaReservas = ({ limit = 3000 }) => {

  const sendEmailPermission = true;

  let [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [reservaID, setreservaID] = useState(null);
  const [agendamentoID, setAgendamentoID] = React.useState(null);
  const [email, setEmail] = useState('');
  const [farmaciaNome, setFarmaciaNome] = useState('');
  const [columns] = useState([
    { title: 'Nombre', field: 'nome' },
    { title: 'Status', field: 'statusreg' },
    { title: 'Hora Registro', field: 'horadecadastro' },
    { title: 'Fecha Retirada', field: 'dt_retirada' },
  ]);

  useEffect(() => {
    setLoading(true);
    db.collection("reservas")
      .where("farmaciaID", "==", myFirebase.auth().currentUser.uid)
      .orderBy("horacadastro", "desc")
      .limit(limit)
      .onSnapshot(function (doc) {
        var datas = [];
        doc.docs.forEach(function (documents) {
          // console.log(documents.data());
          datas.push(documents);
        });
        handleRows(datas);

      });

  }, []);

  const handleRows = (data) => {
    const tempDoc = data.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });
    // console.log('TEMPDOC => ', tempDoc);
    setRows(tempDoc);
    setLoading(false);
  };

  useEffect(() => {
    let docRef = db.collection("usuarios").doc(myFirebase.auth().currentUser.uid);
    docRef.get().then(function (doc) {
      if (doc.exists) {
        setFarmaciaNome(doc.data().nome);
      }
    }).catch(function (error) {
      console.log("Error :", error);
    });
  }, []);


  const updateReload = async () => {
    console.log("UPDATE RELOAD");
    // return
    await db.collection('reservas').doc('7oiVhzuFStY8s9DCFEQJ').update({
      status: 'reservado',
      horaretirada: null
    }).then(async () => {
      console.log('UPDATE SUCESSO ');

    });
  };

  const handleBajarReserva = async (item) => {
    //rowData.id, rowData.data_retirada, rowData.reservaID, rowData.email
    setLoading(true);
    console.log('BAJAR RESERVA => ', item.id, item.dataRetirar, item.reservaID, item.email);
    console.log('BAJAR RESERVA CONFIRM  ');
    if (myFirebase.auth().currentUser != null && item.id) {
      const checkDt = checkPermittedRetireReserve(item.dataRetirar, null);
      const horaRetirada = new Date();
      const obj = { email: item.email, type: 'read', title: 'Reserva Bajada/Scaneada', nome: farmaciaNome, hora: format(horaRetirada, "HH:mm") };
      if (checkDt) {
        console.log("PERMITIDO BAJAR RESERVA", item.id, item.dataRetirar, item.reservaID, item.email);
        try {
          await db.collection('reservas').doc(item.id).update({
            status: 'retirado',
            horaretirada: horaRetirada
          }).then(async () => {
            console.log('RESERVA BAJADA COM SUCESSO ');
            await triggerUpdateAgendamento(item.reservaID);
            await sendEmail(obj);
            console.log('FIM BAJAR RESERVA ');
          });
        } catch (error) {
          console.log("ERROR PARA BAJAR A RESERVA", item.id, item.dataRetirar, item.reservaID, item.email);
        }
      } else {
        console.log("NAO FOI POSSIVEL BAJAR A RESERVA", item.id, item.dataRetirar, item.reservaID, item.email);
      }
    }
    setLoading(false);
  };

  const triggerUpdateAgendamento = async (reservaID) => {
    setLoading(true);
    console.log("INICIO UPDATE AGENDAMENTO", reservaID);
    await db.collection("usuarios")
      .doc(myFirebase.auth().currentUser.uid)
      .collection("agendamentos")
      .doc(reservaID).set({ "uuid": uuid() }, { merge: true });
    console.log("FIM UPDATE AGENDAMENTO", reservaID);
    setLoading(false);
  };


  const checkPermittedRetireReserve = (dtRetirada, dtFimRetirada) => {

    console.log("CHECK PERMITED RETIRAR RESERVA", dtRetirada, dtFimRetirada);
    const dataAgora = new Date();

    if (isSameDay(dataAgora, dtRetirada.toDate())) {
      return true;
    } else if (isSameDay(dataAgora, dtFimRetirada)) {
      return true;
    } else {
      console.log("NAO PERMITIDO RETIRAR RESERVA - OTRA FECHA", dtRetirada, dtFimRetirada);
      Swal.fire({
        title: 'Reserva inválida',
        text: 'Esta reserva es de otra fecha.',
        icon: 'error',
      });
      return false;
    }

  };

  const handleCancelReserve = async (item) => {
    console.log('HANDLE CANCEL RESERVA => ', item);
    if (item.status == 'reservado' && !loading) {
      if (window.confirm("Cancelar esta reserva? \nSeguro quieres cancelar, este cambio inhabilita el uso y permite que otro usuario haga la reserva.")) {
        setLoading(true);
        console.log('CONFIRM CANCELAR => ', item.reservaID, item.id, item.email);
        setreservaID(item.reservaID);
        setAgendamentoID(item.id);
        setEmail(item.email);

        console.log('CANCELANDO.... ', item.reservaID, item.id, item.email);
        await db.collection('reservas').doc(item.id).set({
          status: 'cancelado',
          dataCancelamento: new Date(),
        }, { merge: true });
        console.log('RESERVA CANCELADA ');
        const obj = { email: item.email, type: 'cancel', title: 'Reserva Cancelada', nome: farmaciaNome };
        await triggerUpdateAgendamento(item.id);
        await sendEmail(obj);
        console.log('FIM CANCELAR  ');
        setLoading(false);
      }
    }
  };


  const sendEmail = async (data) => {
    setLoading(true);
    console.log('INICIO ENVIAR EMAIL => ', data);
    if (sendEmailPermission) {
      await axios({
        method: "POST",
        url: "https://us-central1-reservar-cannabis-uru.cloudfunctions.net/widgets/statusReservaEmail",
        data: data
      }).then((result) => {
        console.log("EMAIL ENVIADO SUCESSO", result);
      }).catch((err) => {
        console.log("ERRO ENVIAR EMAIL", err);
        console.log(err);
      });
      console.log('FIM ENVIO EMAIL => ', data);
    }
    setLoading(false);
  };

  const localization = {
    pagination: {
      labelDisplayedRows: '{from}-{to} de {count}',

      labelRowsSelect: 'líneas',
      firstTooltip: 'Primera pagina',
      previousTooltip: 'Pagina anterior',
      nextTooltip: 'Proxima pagina',
      lastTooltip: 'Última pagina',
      labelRowsPerPage: 'Líneas por pagina',
    },
    toolbar: {
      nRowsSelected: '{0} líneas(s) selecionadas',

      searchTooltip: 'Buscar',
      searchPlaceholder: 'Ingrese un nombre...'
    },
    header: {
      actions: ''
    },
    body: {
      emptyDataSourceMessage: 'No ha sido encontrado registros',
      filterRow: {
        filterTooltip: 'Filtrar'
      },
      editRow: {
        deleteText: '¿Quieres borrar la reserva?'
      }
    }
  };

  const Tabela = () => (
    <MaterialTable
      title="Listado de Reservas"
      localization={localization}
      options={{
        paging: true,
        pageSize: 10,
        emptyRowsWhenPaging: false,
        pageSizeOptions: [5, 10, 20, 50],
      }}
      isLoading={loading}
      actions={[
        rowData => ({
          icon: props => <CheckCircleIcon style={{ color: green[500] }} />,
          // tooltip: 'Bajar Reserva Manualmente',
          onClick: (event, rowData) => handleBajarReserva(rowData),
          disabled: rowData.status !== 'reservado'
        }),
        rowData => {
          return (rowData.status !== 'reservado' ?
            { icon: props => <CancelIcon />, disabled: true, onClick: (event, rowData) => { /* anythink */ } }
            :
            {
              icon: props => <CancelIcon color="secondary" />,
              // tooltip: 'Cancelar Reserva',
              disabled: false,
              onClick: (event, rowData) => {
                handleCancelReserve(rowData);
              }
            }
          );
        }
      ]}
      columns={columns}
      data={rows != null && rows.map(row => ({
        id: row.id,
        nome: row.data().nome,
        statusreg:
          row.data().status === 'reservado' ? (
            <Chip
              label="Aguardando"
              color="default" />
          ) : row.data().status === 'retirado' ? (
            <Chip
              label="Retirado"
              color="primary" />
          ) : (
            <Chip
              label="Cancelado"
              color="secondary" />
          ),

        horadecadastro: row.data().horacadastro.toDate().toLocaleString(),
        dt_retirada: row.data().dataRetirar.toDate().toLocaleString(),
        data_retirada: row.data().dataRetirar.toDate(),
        status: row.data().status,
        email: row.data().email,
        reservaID: row.data().reservaID,
      }))}
    />
  );

  function handleSearch() {
    console.log("FILTERING...");
    // Declare variables
    var input, filter, table, tbody, tr, td, i, txtValue;
    input = document.getElementById("search_reserve");
    filter = input.value.toUpperCase();
    table = document.getElementById("table_reserve");
    tr = table.getElementsByTagName("tr");

    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[1];
      // console.log('TD => ', td);
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  }

  const handleDownReserve = async (item) => {
    // window.alert(item.data().status);
    if (item.status == 'reservado' && !loading) {
      // if (window.confirm("Bajar reserva?")) {
      return await handleBajarReserva(item);
      // }
    }
    return;
  };

  const handleDeleteReserve = async (item) => {
    console.log('HANDLE DELETE RESERVA => ', item);
    if (item.status != 'retirado' && !loading) {
      if (window.confirm("Borrar esta reserva?\n Esta seguro que quieres borrar esta reserva permanentemente? Sugerimos utilizar esta botonera en reservas en usuarios que no tienen variedades agregadas.")) {
        setLoading(true);
        await db.collection('reservas').doc(item.id).delete();
        // await db.collection('reservas').doc(item.id).set({
        //   active: 0,
        //   deleted_at: new Date(),
        // }, { merge: true });
        setLoading(false);
      }
    }
  };


  const ZimpleCustomTable = () => (
    <div id="simple_custom_table">

      <div class="form-group">
        <input type="text" id="search_reserve" onKeyUp={handleSearch} placeholder="Search for names.." />
        <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
      </div>



      <table id="table_reserve" width="100%" className="table table-responsive">
        <thead>
          <tr>
            <td></td>
            <td>Nome</td>
            <td>Status</td>
            <td>Hora Registro</td>
            <td>Fecha Retirar</td>
            <td>Email</td>
          </tr>
        </thead>

        <tbody>
          {rows.map(((item, idx) =>
            <tr key={idx}>
              <td >
                <div className="td_actions">
                  <div className="btn_action" onClick={() => handleDownReserve(item)}>
                    <CheckCircleIcon style={{ color: item.data().status == 'reservado' ? green[500] : grey[500] }} />
                  </div>
                  <div className="btn_action" onClick={() => handleCancelReserve(item)}>
                    <CancelIcon style={{ color: item.data().status == 'reservado' ? red[500] : grey[500] }} />
                  </div>
                </div>
              </td>
              <td>{item.data().nome}</td>
              <td>{
                item.data().status === 'reservado' ? (
                  <Chip
                    label="Aguardando"
                    color="default" />
                ) : item.data().status === 'retirado' ? (
                  <Chip
                    label="Retirado"
                    color="primary" />
                ) : (
                  <Chip
                    label="Cancelado"
                    color="secondary" />
                )}</td>
              <td>{item.data().horacadastro.toDate().toLocaleString()}</td>
              <td>{item.data().dataRetirar.toDate().toLocaleString()}</td>
              <td>{item.data().email}</td>
            </tr>
          ))}
        </tbody>

      </table>
    </div>
  );

  return (
    <div className='col-12'>
      <div>
        {/* <button onClick={updateReload}>UPDATE</button> */}
        <TableScreen
          rows={rows}
          onCancelReserve={handleCancelReserve}
          onDownReserve={handleDownReserve}
          onDeleteReserve={handleDeleteReserve}
          farmacyName={farmaciaNome}
          onLoading={loading}
        />
      </div>
    </div>
  );

};

function mapStateToProps(state) {
  return {
    isLoggingOut: state.auth.isLoggingOut,
    logoutError: state.auth.logoutError
  };
}

const mapDispatchToProps = dispatch => ({
  Deslogar() {
    dispatch(logoutUser());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(TabelaReservas);