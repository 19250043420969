import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import { db, myFirebase } from "../firebase/firebase";
import { logoutUser } from "../actions";
import PropTypes from 'prop-types';

import CssBaseline from '@material-ui/core/CssBaseline';
import MenuAppBar from './dashboard/components/Appbar';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormGroup from '@material-ui/core/FormGroup';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import Send from '@material-ui/icons/Send';
import FilterListIcon from '@material-ui/icons/FilterList';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';

import DateFnsUtils from '@date-io/date-fns';
import ptbrLocale from "date-fns/locale/pt-BR";
import esLocale from "date-fns/locale/es";
import isValid from 'date-fns/isValid';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import '../css/styles.css';
import QRGenerator from './QRGenerator';
import { Label } from 'devextreme-react/bar-gauge';
import { chunk } from './dashboard/components/Helpers';

// import { createTheme, ThemeProvider } from '@material-ui/core/styles';
// import { esES } from '@material-ui/core/locale';

// const theme = createTheme({
//   palette: {
//     primary: { main: '#1976d2' },
//   },
// }, esES);

function createData(email) {
  return { email };
}

let selecionados = [];

const rows = [
  createData('Cupcake'),
  createData('Donut'),
  createData('Eclair'),
  createData('Frozen yoghurt'),
  createData('Gingerbread'),
  createData('Honeycomb')
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'email', numeric: false, disablePadding: true, label: 'Email' }
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'selecionar todos' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.primary.main,
        backgroundColor: lighten(theme.palette.primary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.dark,
      },
  title: {
    flex: '1 1 100%',
  },

}));

const printdeletearray = selectedId => {
  console.log(selectedId);
  console.log(selecionados);
};

const EnhancedTableToolbar = (props) => {
  // console.log(props);
  const classes = useToolbarStyles();
  const { numSelected, selectedIndex } = props;


  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="primary" variant="subtitle1" component="div">
          {numSelected} selecionado{numSelected > 1 ? `s` : ``}
        </Typography>
      ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            Lista de correos
          </Typography>
        )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    minHeight: 200,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  formControl: {
    // margin: theme.spacing(1),
    width: '100%',
    marginTop: theme.spacing(2),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  textarea: {
    resize: 'block',
    padding: '15px',
    margin: '2px',
    border: 'none'
  },
  backdrop: {
    zIndex: 9999,
    backgroundColor: "rgba(0, 0, 0, 0.5)"
  }
}));

function EnhancedTable(props) {

  const sendEmailPermission = true;
  const sendEmailToFarmacia = "qpK4UncBpWgDPqHy1iWDNqQTWb92";
  // const farmaciaID = myFirebase.auth().currentUser.uid ?? null;
  // console.log(props);
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showDialog, setShowDialog] = useState(false);
  const [invalidDateMessage, setInvalidDateMessage] = useState('');

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogDescription, setDialogDescription] = useState('');
  const [open, setOpen] = useState(false);

  const farmaciaID = myFirebase.auth().currentUser.uid;
  const [farmacias, setFarmacias] = useState([]);
  const [farmaciaSelect, setFarmaciaSelect] = useState('');

  const [list, setList] = useState([]);
  const [nroEmails, setNroEmails] = useState(0);
  const [response, setResponse] = useState('');
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [sendEmail, setSendEmail] = useState(false);

  const [aviso, setAviso] = useState('');
  const [avisoID, setAvisoID] = useState(0);
  const [farmaciaData, setFarmaciaData] = useState([]);
  // const [avisos, setAvisos] = useState([
  //   { value: 1, label: 'Sistema Caído', desc: '' },
  //   { value: 2, label: 'Sistema Volvió', desc: '' },
  //   { value: 3, label: 'Feriado', desc: '' },
  //   { value: 4, label: 'Farmacia Cerrada', desc: '' },
  //   { value: 5, label: 'Interromper Entrega/Cancelar las reservas', desc: '' },
  //   { value: 6, label: 'Correr el dia de Entrega para el Lunes', desc: '' },
  // ]);
  // const [radioValue, setRadioValue] = useState("1");

  useEffect(() => {
    db.collection("usuarios")
      .get()
      .then(function (querySnapshot) {
        let arr = [];
        querySnapshot.forEach(function (doc) {
          // console.log(doc.id, " => ", doc.data());
          arr.push({
            farmaciaID: doc.id,
            farmaciaNome: doc.data().nome,
            farmaciaEmail: doc.data().email
          });
        });
        setFarmacias(arr);
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  }, []);



  useEffect(() => {
    db.collection("usuarios")
      .doc(farmaciaID)
      .get().then(function (doc) {
        // console.log(doc.data());
        if (!doc.empty) {
          let obj = {
            farmaciaID: doc.id,
            farmaciaNome: doc.data().nome,
            farmaciaLogo: doc.data().logo,
            farmaciaRua: doc.data().endereco.rua,
            farmaciaCidade: doc.data().endereco.cidade
          };
          setFarmaciaData(obj);
        }
      });
  }, [farmaciaID]);

  function getReservas(date, id) {
    const diaHoje = new Date(date);
    const diaAmanha = new Date(date);
    diaHoje.setHours(23, 59, 59, 0);
    diaAmanha.setHours(0, 0, 0, 0);
    diaHoje.setDate(diaHoje.getDate() - 1);
    diaAmanha.setDate(diaAmanha.getDate() + 1);

    console.log(diaHoje);
    console.log(diaAmanha);
    // console.log(farmaciaSelect);
    // useEffect(() => {
    let reservasRef = db.collection('reservas');
    reservasRef
      .where("farmaciaID", "==", id)
      .where("horacadastro", ">", diaHoje)
      .where("horacadastro", "<", diaAmanha)

      // .orderBy("data", "desc")
      .get()
      .then((snapshot) => {
        let obj = [];
        if (snapshot.empty) {
          console.log('NO MATCHING DOCUMENTS!');
        } else {
          console.log('MATCHING DOCUMENTS!!!!');
          snapshot.forEach(function (doc) {
            // console.log(doc.data().email);
            obj.push({ id: doc.ref.id, email: doc.data().email.toLowerCase() });

          });
          obj.sort(function (a, b) {
            if (a.email < b.email) return -1;
            if (a.email > b.email) return 1;
            return 0;
          });
          setNroEmails(obj.length);
        }
        console.log(obj);
        setList(obj);
        return;
      })
      .catch(err => {
        console.log('Error getting documents', err);
      });
  }



  const handleSendEmail = async () => {
    setOpen(true);
    let chunked = chunk(selected, 90);
    // console.log(chunked);
    const lista = chunked.map(e => {
      return e.join(', ');
    })
    senderEmail(lista);
  };

  const senderEmail = (lista) => {

    if (sendEmailPermission) {

      axios({
        method: "POST",
        url: "https://us-central1-reservar-cannabis-uru.cloudfunctions.net/widgets/avisoReservaEmail",
        data: { emails: lista, title: title, message: message }
      }).then((result) => {
        console.log(result);
        setOpen(false);
        setDialogTitle("Listo!");
        setDialogDescription(result.data[0].message);
        setOpenDialog(true);
      }).catch((err) => {
        console.log(err);
        setOpen(false);
        setDialogTitle("Erro");
        setDialogDescription(err.response.data.message);
        setOpenDialog(true);
        // console.log(err.response.data.message);
      })
    }
  };


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = list.map((n) => n.email);
      setSelected(newSelecteds);
      selecionados = newSelecteds;
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, email) => {
    console.log(email);
    console.log(selected);

    const selectedIndex = selected.indexOf(email);
    console.log(selectedIndex);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, email);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    console.log(newSelected)
    setSelected(newSelected);
    selecionados = newSelected;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (email) => selected.indexOf(email) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, list.length - page * rowsPerPage);

  const hoje = new Date();
  const [selectedDate, setSelectedDate] = useState((hoje));

  const handleDateChange = (date, value) => {
    console.log(date);
    console.log(value);
    setSelectedDate(date);
    if (isValid(date) && farmaciaID) {
      setSelected([]);
      getReservas(date, farmaciaID);
    }
  };


  const handleSelectFarmacia = (event) => {
    console.log(event.target.value);
    setFarmaciaSelect(event.target.value);
    if (isValid(selectedDate) && selectedDate) {
      setSelected([]);
      getReservas(selectedDate, event.target.value)
    }
  };

  useEffect(() => {
    setSendEmail(selected.length && selectedDate && title && message && farmaciaID);
  }, [selected, selectedDate, title, message, farmaciaID]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    window.location.reload(false);
  }


  return (

    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="primary" />
      </Backdrop>
      <CssBaseline />
      <MenuAppBar />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <h3 style={{ padding: 30, textAlign: "center" }}>Que deseas avisar:</h3>
          <Grid container spacing={2} direction="row">

            <Grid item xs={12} sm={8}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="">Seleccione una farmacia</InputLabel>
                <Select
                  style={{ height: '67px' }}
                  labelId=""
                  id=""
                  value={farmaciaSelect}
                  onChange={handleSelectFarmacia}
                  label="Seleccione una farmacia"
                >
                  {farmacias && farmacias.map(farm => (
                    <MenuItem key={farm.farmaciaID} value={farm.farmaciaID}>{farm.farmaciaNome}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>

              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale} style={{}}>
                <KeyboardDatePicker
                  allowKeyboardControl={false}
                  disableToolbar
                  variant="inline"
                  fullWidth={true}
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Seleccione una fecha de reserva"
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  autoOk={true}
                  onError={(error, value) => { error ? setInvalidDateMessage('Fecha inválida') : setInvalidDateMessage('') }}
                  invalidDateMessage={invalidDateMessage}
                  helperText={invalidDateMessage}
                />
              </MuiPickersUtilsProvider>

            </Grid>

            <Grid item xs={12} style={{ display: "block" }}>
              <Paper className={classes.paper}>
                {selected.length > 0 &&
                  <div className={selected.length > 0 ? 'fadeIn' : 'fadeOut'} style={
                    { marginTop: 30, background: '#dbfbd1', padding: 20, borderRadius: 5, textAlign: 'center' }
                  }>
                    <blockquote>Enviaremos un correo electrónico a las {selected.length} persona(s) que reservaram para este día</blockquote>
                  </div>
                }
                <EnhancedTableToolbar numSelected={selected.length} />
                <TableContainer>
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                    aria-label="enhanced table"
                  >
                    <EnhancedTableHead
                      classes={classes}
                      numSelected={selected.length}
                      selectedId={selected.id}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={list.length}
                    />
                    <TableBody>
                      {stableSort(list, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.email);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              onClick={(event) => handleClick(event, row.email)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox color="primary"
                                  checked={isItemSelected}
                                  inputProps={{ 'aria-labelledby': labelId }}
                                />
                              </TableCell>
                              <TableCell component="th" id={labelId} scope="row" padding="none">
                                {row.email}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 75, 100, 200, 500, 1000]}
                  component="div"
                  count={list.length}
                  rowsPerPage={rowsPerPage}
                  labelRowsPerPage=""
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Paper>
              <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} color="primary" />}
                label="Comprimir la mesa"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id=""
                fullWidth={true}
                label="El título del correo electrónico"
                placeholder=""
                value={title}
                onChange={(e) => { setTitle(e.target.value) }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                id=""
                fullWidth={true}
                label="Su mensaje"
                multiline
                rows={6}
                // placeholder="Comece escrever..."
                value={message}
                onChange={(e) => { setMessage(e.target.value) }}
                variant="outlined"
              />
            </Grid>

            <Grid item xs align="">
              <Paper elevation={3} style={{ paddingTop: 10, paddingRight: 30, paddingBottom: 30, paddingLeft: 20 }}>
                <div align="center">
                  <small id="emailHelp" className="form-text text-muted">&nbsp;</small>
                  <button onClick={handleSendEmail} className="btn btn-primary" disabled={sendEmail ? false : true}>Enviar</button>
                </div>
              </Paper>
            </Grid>
          </Grid>
          <Dialog
            open={openDialog}
            onClose={() => handleCloseDialog()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {dialogDescription}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleCloseDialog()} color="primary" autoFocus>
                Cerrar
          					</Button>
            </DialogActions>
          </Dialog>
        </Container>
      </main>

    </div >


  );
}
function mapStateToProps(state) {
  return {
    isLoggingOut: state.auth.isLoggingOut,
    logoutError: state.auth.logoutError
  };
}

const mapDispatchToProps = dispatch => ({
  Deslogar() {
    dispatch(logoutUser());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(EnhancedTable);
