import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Root from "./Root";
import { SnackbarProvider } from "notistack";
import * as serviceWorker from './service-worker';

ReactDOM.render(
    <SnackbarProvider maxSnack={1}>
        <Root />
    </SnackbarProvider>
    ,
    document.getElementById("root")
);

serviceWorker.register();
