import React from 'react';
// import { QRCode } from "react-qr-svg";
import QRCode from 'react-qr-code';

function QRGenerator(props) {

    return (
        <QRCode
            bgColor="#FFFFFF"
            fgColor="#000000"
            level="L"
            style={{ width: 256 }}
            value={props.value}
            // cellClassPrefix="my-qr-code"
            
        />
    );
}

export default QRGenerator;