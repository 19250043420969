import createReducerFor from './createReducerFor';

const initial_state = {
  user: null,
  notifications_count: 999,
};

const [UserReducer, actions] = createReducerFor('USER', initial_state);

export const setUser = actions.setUser;
export const setNotificationsCount = actions.setNotificationsCount;

export default UserReducer;
