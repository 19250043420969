import React from 'react';

export default function Reglas(props) {

    return (

        <div className="modal fade" id="reglas" tabIndex="1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="modal-title">Reglas de la Reserva</h2>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <i className="material-icons">clear</i>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>
                            <strong>Cuando haces una reserva significa que te involucraste y tomaste una decisión!</strong>
                        </p>
                        <p><strong>Reglas&nbsp; de Suspensión</strong> (Reserva sin retirar)<br></br>
                            Si hiciste una reserva,<strong> no te olvides de venir hasta el plazo para retirar!<br></br>
                            </strong>Esta aplicación fue hecha para las personas que <strong>necesitan y quieren</strong>,<br></br>
                            si no logras venir <a href="https://reservarcannabis.com/cancelar-reserva/">haga la cancelación de la reserva para evitar ser suspendido.</a>
                        </p>
                        <ul>
                            <li><strong>1 reserva sin venir;</strong> Suspendido una semana;</li>
                            <li><strong>2 reserva sin venir;</strong> Suspendido un mês;</li>
                        </ul>
                        <p>
                            <strong>Los paquetes son pagos unicamente en efectivo - se ruega traer cambio!</strong>
                            <strong>Usamos QRCODE!<br></br></strong>
                            Si tienes el ticket con la información de la reserva, significa que su reservación está garantizada! Si te quedaste sin bateria también podes retirar con tu nombre completo.
                        </p>
                        <p><strong>Convertir o cambiar reservas</strong><br></br>
                            Las reservas no pueden ser intercambiados por dinero real.
                            Las reservas para cannabis, por lo general, son intransferibles a otras personas y no se pueden cambiar por nada.
                            Si se descubre estos casos pueden ser penados por ley.
                            Fijate si estas cerca de la farmacia donde vas a reservar;
                            Si no vas a venir a retirar, no haga su reserva; La reserva online no da prioridad en la fila;
                            Cuando haces la reserva, vos aceptas las reglas establecidas aquí.
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger btn-link" data-dismiss="modal">Cerrar
                            <div className="ripple-container"></div>
                            <div className="ripple-decorator ripple-on ripple-out" style={{ left: '35.4821px', top: '21.05px', backgroundColor: 'rgb(244, 67, 54)', transform: 'scale(8.50976)' }}>
                            </div>
                            <div className="ripple-decorator ripple-on ripple-out" style={{ left: '35.4821px', top: '21.05px', backgroundColor: 'rgb(244, 67, 54)', transform: 'scale(8.50976)' }}>
                            </div>
                        </button>
                    </div>
                </div></div>
        </div>

    );
}


