import { isSameDay, format } from 'date-fns';
import esLocale from "date-fns/locale/es";
import { domainInvalid, extensionInvalid, domainToCheck } from './Constants'

export function FormatDateQRCode(dataIni, dataFim) {

    const dtini = new Date(dataIni.seconds * 1000);
    const dtfim = new Date(dataFim.seconds * 1000);

    return isSameDay(dtini, dtfim) ?
        `Podes retirar en el día ${format(dtini, "dd/MM ' de las ' HH:mm", { locale: esLocale })} hasta ${format(dtfim, "HH:mm", { locale: esLocale })}` :
        `Podes retirar en el día ${format(dtini, "dd/MM", { locale: esLocale })} y ${format(dtfim, "dd/MM", { locale: esLocale })} de las ${format(dtini, "HH:mm", { locale: esLocale })} hasta a ${format(dtfim, "HH:mm", { locale: esLocale })}`;
}


export function FormatDateHourBRtoDateHourString(data, hora) {
    let dataFull = data.split("/");
    let dataAdjust = `${dataFull[2]}-${dataFull[1]}-${dataFull[0]} ${hora}:00`;
    let dataNew = new Date(dataAdjust);
    return dataNew;

}


export function FormatDatetoFB(data) {
    let objDateHour = data.split(" ");
    let objDate = objDateHour[0];
    let objHour = objDateHour[1];
    let dateFull = objDate.split("/");
    let dateAdjust = `${dateFull[2]}-${dateFull[1]}-${dateFull[0]}`;
    let hourAdjust = `${objHour}:00`;

    let date = `${dateAdjust} ${hourAdjust}`;
    let values = date.split(/[^0-9]/);
    let year = parseInt(values[0], 10);
    let month = parseInt(values[1], 10) - 1;
    let day = parseInt(values[2], 10);
    let hours = parseInt(values[3], 10);
    let minutes = parseInt(values[4], 10);
    let seconds = parseInt(values[5], 10);

    let formattedDate = new Date(year, month, day, hours, minutes, seconds);

    return formattedDate;

}


export function FormatDateHourBRtoDateHourDB(data) {
    let dataFull = data.split("/");
    let dataAdjust = `${dataFull[2]}-${dataFull[1]}-${dataFull[0]}`;
    let dataNew = new Date(dataAdjust.seconds * 1000);
    return dataNew;

}

export function checkBrowser() {
    var test = function (regexp) { return regexp.test(window.navigator.userAgent) }
    switch (true) {
        case test(/edg/i): return "Microsoft Edge";
        case test(/trident/i): return "Microsoft Internet Explorer";
        case test(/firefox|fxios/i): return "Mozilla Firefox";
        case test(/opr\//i): return "Opera";
        case test(/ucbrowser/i): return "UC Browser";
        case test(/samsungbrowser/i): return "Samsung Browser";
        case test(/chrome|chromium|crios/i): return "Google Chrome";
        case test(/safari/i): return "Apple Safari";
        default: return "Other";
    }
}

export function checkOS() {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'MacOSX', 'Mac', 'darwin'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        unixPlatforms = ['X11'],
        os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
    } else if (/Android/.test(userAgent)) {
        os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
    } else if (!os && /Unix/.test(unixPlatforms)) {
        os = 'Unix';
    }

    return os;
}

export function getBrowserOS() {
    return `${checkBrowser()}/${checkOS()}`

}

export function isEmailValid(email) {
    const rgx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return rgx.test(email);
}

export function validateEmailDomain(email) {
    let validEmail = false;
    if (isEmailValid(email)) {
        // validEmail = true;
        let address = email.split("@");
        let ext = address[1];
        let ext1 = ext.split(".");
        let ext2 = ext1[0];
        let ext3 = ext1[1];
        let ext4 = ext1[2] ? ext1[2] : null;
        let ext5 = ext1[3] ? ext1[3] : null;

        const checkDomain = domainToCheck.indexOf(ext2)

        const validDomain = !domainInvalid.includes(ext2);

        const validExtension = !extensionInvalid.includes(ext3);

        if (validDomain) {
            //verificar todos ".com" => gmail, hotmail, outlook, adinet, montevideo
            if (email.search(/gmail\b/i) > 0) {
                if (ext === "gmail.com") validEmail = true;
            }
            if (email.search(/hotmail\b/i) > 0) {
                if (ext === "hotmail.com") validEmail = true;
            }
            if (email.search(/montevideo\b/i) > 0) {
                if (ext === "montevideo.com.uy") validEmail = true;
            }
            if (email.search(/yahoo\b/i) > 0) {
                if (ext === "yahoo.com") validEmail = true;
            }
            if (email.search(/adinet\b/i) > 0) {
                if (ext === "adinet.com.uy") validEmail = true;
            }
            if (email.search(/antel\b/i) > 0) {
                if (ext === "antel.com.uy") validEmail = true;
            }
            if (email.search(/\vera\b/i) > 0) {
                if (ext === "vera.com.uy") validEmail = true;
            }
        }

        console.log({
            address,
            ext,
            ext1,
            ext2,
            ext3,
            ext4,
            ext5,
            checkDomain,
            validDomain,
            validExtension,
            validEmail
        });

        if (validDomain && validExtension && validEmail) {
            return ({ valid: true, message: "VALID" });
        } else {
            return ({ valid: false, message: "INVALID" });
        }
        // return validDomain && validExtension && validEmail;
    }
}

export function chunk(array, size) {
    const chunked_arr = [];
    for (let i = 0; i < array.length; i++) {
        const last = chunked_arr[chunked_arr.length - 1];
        if (!last || last.length === size) {
            chunked_arr.push([array[i]]);
        } else {
            last.push(array[i]);
        }
    }
    return chunked_arr;
}

export function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function validateRecaptcha(recaptcha) {
    if (window.location.hostname === 'localhost') {
        return true;
    } else {
        if (recaptcha !== '')
            return true;
        else
            return false;
    }
}

export const fakerAsync = (seconds = 2000) => new Promise((resolve) => setTimeout(resolve, seconds));