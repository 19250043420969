import axios from 'axios';

const ENVIRONMENTS = {
  production: 'https://us-central1-reservar-cannabis-uru.cloudfunctions.net/widgets/',
  local: 'http://localhost:5001/reservar-cannabis-uru/us-central1',
};

const SELECTED_ENV = 'production';

class Api {
  axios;
  token;
  onUnauthenticatedCallback;

  constructor(env) {
    this.axios = axios.create({
      baseURL: env,
      timeout: 60000,
    });

    this.axios.interceptors.request.use(
      async options => this.transformRequest(options),
      error => Promise.reject(error),
    );

    this.axios.interceptors.response.use(
      response => response,
      error => this.onRequestError(error),
    );
  }

  onUnauthenticated(callback) {
    this.onUnauthenticatedCallback = callback;
  }

  onRequestError(error) {
    if (error.response && error.response.status == 401) {
      this.onUnauthenticatedCallback && this.onUnauthenticatedCallback(error);
    }
    return Promise.reject(error);
  }

  async transformRequest(options) {
    // if (['put', 'post', 'delete'].includes(options.method)) {
    //   const requestToken = await Castle.createRequestToken();
    //   options.headers['X-Castle-Request-Token'] = requestToken;
    // }

    if (this.token) {
      options.headers['Authorization'] = `Bearer ${this.token}`;
    }

    return options;
  }

  get(endpoint, config) {
    return this.request('get', endpoint, config);
  }

  post(endpoint, data, config) {
    return this.request('post', endpoint, data, config);
  }

  put(endpoint, data, config) {
    return this.request('put', endpoint, data, config);
  }

  delete(endpoint, config) {
    return this.request('delete', endpoint, config);
  }

  async request(method, endpoint, data, config) {
    let response = await this.axios[method](endpoint, data, config);
    return response.data;
  }

  setToken(token) {
    this.token = token;
  }

  getToken() {
    return this.token;
  }
}

const api = new Api(ENVIRONMENTS[SELECTED_ENV]);

export default api;
