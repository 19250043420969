import React from 'react';
import { version } from '../../../../package.json';
import { Box, Container, Link, Typography } from '@mui/material';
import { Grid } from '@material-ui/core';

function Copyright() {
  return (
    <Container style={{ marginBottom: 20 }}>
      <Grid item>
        <Typography variant={'caption'} align={'center'}>
          Tienes alguno inconveniente?
        </Typography>
        <Typography variant={'caption'} align={'center'}>
          Llama a nuestro Soporte por Whatsapp - <a href="https://wa.me/59891220420">091 220 420 </a> |
          <a href="https://blog.reservarcannabis.com/como-utilizar-la-herramienta-administrativa"> Como utilizar la herramienta?</a>
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption" color="textSecondary" align="center" style={{ marginTop: 10 }}>
          {'Copyright © '}
          <Link color="inherit" href="https://reservarcannabis.com/">
            Reservar Cannabis
          </Link>{' '}
          {new Date().getFullYear()}
          {' - '} Version {version}
        </Typography>
      </Grid>
    </Container>
  );
}

export default Copyright;