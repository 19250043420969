import React, { useEffect, useState, useRef } from 'react';
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { db, analytics } from "../firebase/firebase";
import axios from 'axios';
import Footer from './parts/footer';
import Header from './parts/header';
import TextField from '@material-ui/core/TextField';
import ReCAPTCHA from "react-google-recaptcha";
import { Alert, AlertTitle } from '@material-ui/lab';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import { Icon, InlineIcon } from '@iconify/react';
import qrcodeIcon from '@iconify/icons-mdi/qrcode';
import ballotIcon from '@iconify/icons-mdi/ballot-outline';
import lawIcon from '@iconify/icons-whh/law';
import { validateRecaptcha } from './dashboard/components/Helpers';

import '../css/styles.css';

function CancelarReserva() {

	const sendEmailPermission = true;

	const scrollDiv = useRef();
	// const [farmaciaID, setFarmaciaID] = useState('');
	const [farmaciaNome, setFarmaciaNome] = useState('');
	const [email, setEmail] = useState('');
	const [motivo, setMotivo] = useState('');
	const [motivoID, setMotivoID] = useState('');
	const [emailError, setEmailError] = useState(false);
	const [emailMessage, setEmailMessage] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [showError, setShowError] = useState(false);
	const [showCancelado, setShowCancelado] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [showAlertTimer, setShowAlertTimer] = useState(0);
	const [alertOptions, setAlertOptions] = useState({ title: "Title", message: "Message", severity: "error" });
	const recaptchaRef = useRef();

	const validateEmail = (email) => {
		var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	}

	const diaHoje = new Date();
	const diaAmanha = new Date();
	diaHoje.setHours(0, 0, 0, 0);
	diaAmanha.setHours(0, 0, 0, 0);
	diaHoje.setDate(diaHoje.getDate() - 2);
	diaAmanha.setDate(diaAmanha.getDate() + 1);

	const handleSubmit = (event) => {
		event.preventDefault();
		if (validateRecaptcha(recaptchaRef.current.getValue())) {
			// if (recaptchaRef.current.getValue() !== '') {
			setShowAlertTimer(3000);
			console.log(email);
			if (!validateEmail(email)) {
				setEmailError("E-mail inválido!");
			} else {

				db.collection("reservas")
					.where("horacadastro", ">=", diaHoje)
					.where("horacadastro", "<=", diaAmanha)
					.where("email", "==", email)
					.get()
					.then((doc) => {
						if (!doc.empty) {
							if (doc.docs[0].data().status === 'reservado') {
								console.log(doc.docs[0].data());
								console.log('reserva encontrada');
								// setFarmaciaID();

								db.collection("reservas").doc(doc.docs[0].id)
									.update({
										status: 'cancelado',
										motivoID: motivoID,
										motivo: motivo,
									}).then(() => {
										setShowCancelado(true);
										setAlertOptions({ title: "Reserva cancelada", message: "Gracias por informarnos, la cancelación há sido registrada", severity: "success" });
										setShowAlertTimer(0);
										setShowAlert(true);
										const obj = { email: email, type: 'cancel', title: 'Reserva Cancelada', farmaciaID: doc.docs[0].data().farmaciaID };
										sendEmail(obj);
									});
							} else if (doc.docs[0].data().status === 'cancelado') {
								setAlertOptions({ title: "Reserva cancelada", message: "La reserva ha sido cancelada anteriormente", severity: "error" });
								setShowAlert(true);
							} else {
								setAlertOptions({ title: "Reserva retirada", message: "La reserva ha sido retirada en otro momento", severity: "error" });
								setShowAlert(true);
							}
						} else {
							console.log('reserva nao encontrada');
							setAlertOptions({ title: "Reserva no encontrada", message: "Este mail no tiene ninguna reserva agregada", severity: "error" });
							setShowAlert(true);
						}

					});
			}

		} else {
			setShowError(true);
			setErrorMessage('¿Eres un robot? Seleccione arriba si no.');
			setTimeout(
				function () {
					setShowError(false);
				},
				3000);
		}

	};

	const sendEmail = async (data) => {
		if (sendEmailPermission) {
			const nome = await getFarmaciaData(data.farmaciaID)
			data.nome = nome;

			axios({
				method: "POST",
				url: "https://us-central1-reservar-cannabis-uru.cloudfunctions.net/widgets/statusReservaEmail",
				data: data
			}).then((result) => {
				console.log(result);
			}).catch((err) => {
				console.log(err);
			});

		}
	}


	const getFarmaciaData = async (farmaciaID) => {
		return new Promise(resolve => {
			db.collection("usuarios")
				.doc(farmaciaID)
				.get().then(function (doc) {
					if (!doc.empty) {
						resolve(doc.data().nome);
					}
				});
		});


	}

	useEffect(() => {
		if (!showAlert)
			return;

		const timer = setTimeout(function () {
			if (showAlertTimer > 0)
				setShowAlert(false);
		}, showAlertTimer);
		return () => clearTimeout(timer);
	}, [showAlert, showAlertTimer]);


	function handleChange(event) {

		let text = '';
		let option = parseInt(event.target.value);
		switch (option) {
			case 1:
				text = "Estoy en Otro Departamento";
				break;
			case 2:
				text = "Estoy en Otro Barrio";
				break;
			case 3:
				text = "No llego a esta hora";
				break;
			case 4:
				text = "Problemas Personales";
				break;
			case 5:
				text = "Otro";
				break;

			default:
				text = '';
				break;
		}
		setMotivo(text);
		setMotivoID(event.target.value);
	}

	return (

		<div>
			<Header />
			<div className="page- header-filter" style={{ backgroundImage: `url('/img/chamada-teste-1.jpg'` }}>
				<div className="container">
					<div className="row">
						<div className="col-lg-6 col-md-6 ml-auto mr-auto">

							<div className="card">

								<div className="card-header-3 text-center ">
									<p className="dias">Cancelar reserva</p>

								</div>
								<div className="card-header-description text-center">
									<h4>Gracias por llegar hasta aquí!</h4>
									<center style={{ marginTop: 10, marginLeft: 20, marginBottom: 0, marginRight: 20, color: '#808080' }}>Al hacer una cancelamento de reserva vos estas contribuyendo y permitiendo que otra persona pueda lograr retirar el cannabis.</center>

									<form onSubmit={handleSubmit} autoComplete="on">
										{!showCancelado &&
											<FormControl className="razao_cancelamento">
												<TextField
													className={'muilabel'}
													error={emailError}
													value={email}
													onChange={e => setEmail(e.target.value)}
													required={true}
													style={{ width: '80%' }}
													id="email"
													label="E-mail"
													helperText={emailMessage}
												/>
												<br /><br />
												<FormLabel className="razao_title muilabel" required={true} component="legend">Razón</FormLabel>
												<RadioGroup aria-label="motivo" name="motivo" value={motivoID} onChange={handleChange}>
													<FormControlLabel className="labelRadio" value="1" control={<Radio color="primary" />} label="Estoy en Otro Departamento" />
													<FormControlLabel className="labelRadio" value="2" control={<Radio color="primary" />} label="Estoy en Otro Barrio" />
													<FormControlLabel className="labelRadio" value="3" control={<Radio color="primary" />} label="No llego a esta hora" />
													<FormControlLabel className="labelRadio" value="4" control={<Radio color="primary" />} label="Problemas Personales" />
													<FormControlLabel className="labelRadio" value="5" control={<Radio color="primary" />} label="Otro" />
												</RadioGroup>
											</FormControl>
										}
										{!showCancelado &&
											<ReCAPTCHA style={{ width: '80%', marginTop: '0', marginLeft: '20%', marginBottom: '1em', marginRight: 'auto' }}
												sitekey="6LeiZOUUAAAAAEYn0gzirG4g30wMwaMrLJ8f6wzm"
												size="normal"
												ref={recaptchaRef}
											/>
										}
										<br />
										<div className='alertCalendar' ref={scrollDiv}>
											{showAlert &&
												<>
													<Alert severity={alertOptions.severity}>
														<AlertTitle>{alertOptions.title}</AlertTitle>
														{alertOptions.message}
													</Alert>
													<br /><br />
												</>
											}
										</div>
										{!showCancelado &&
											<button className="btn btn-block btn-primary" type="submit">Enviar</button>
										}
									</form>

									<div className="navigation-bottom-cancel">
										<ul className="nav nav-pills nav-pills-icons" role="tablist">

											{/* color-classes: "nav-pills-primary", "nav-pills-info", "nav-pills-success", "nav-pills-warning","nav-pills-danger" */}

											<li className="nav-item">
												<a className="nav-link active" href="#dashboard-1" role="tab" data-toggle="tab">
													<Icon icon={ballotIcon} width="30" height="30" className="custom_icons" />
            							Reglas
        							</a>
											</li>
											<li className="nav-item">
												<a className="nav-link" href="#schedule-1" role="tab" data-toggle="tab">
													<Icon icon={qrcodeIcon} width="30" height="30" className="custom_icons" />
            										QRCode
        									</a>
											</li>
											<li className="nav-item">
												<a className="nav-link" href="#tasks-1" role="tab" data-toggle="tab">
													<Icon icon={lawIcon} width="30" height="30" className="custom_icons" />
            							Legal
       							 		</a>
											</li>
										</ul>
										<div className="tab-content tab-space">

											<div className="tab-pane active cancel-content" id="dashboard-1">
												<h5>Reglas de Suspensión (Reserva sin retirar)</h5>
												<p>Si hiciste una reserva, no te olvides de venir hasta el plazo para retirar!</p>
												<p>El Reservar Cannabis fue hecho para las personas que necesitan y quieren, si no logras venir, haga tu cancelamento directo con nosotros para evitar ser suspendido.</p>
												<ul>
													<li>1- Reserva sin venir ( Suspendido una semana )</li>
													<li>2- Reserva sin venir ( Suspendido un mês )</li>
												</ul>
												<br />
											</div>

											<div className="tab-pane cancel-content" id="schedule-1">
												<h5>Descargue el QRCODE ó CÓDIGO DE BARRAS!</h5>
												<p>Si tienes el ticket con la información de la reserva. En ese momento su reservación está garantizada. Si te quedaste sin bateria puedes decir tu nombre.</p>
											</div>

											<div className="tab-pane cancel-content" id="tasks-1">
												<h5>Convertir o cambiar reservas</h5>
												<p>Las reservas no pueden ser intercambiados por dinero real. Por otra parte, las reservas para cannabis, por lo general,
													 son intransferibles a otras personas y no se pueden cambiar por nada. Si se descubre estos casos pueden ser penados por ley.</p>
											</div>

										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div >

	);

}
function mapStateToProps(state) {
	return {
		isLoggingOut: state.auth.isLoggingOut,
		logoutError: state.auth.logoutError
	};
}
export default connect(mapStateToProps, {})(CancelarReserva);