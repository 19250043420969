import React, { useState, useEffect } from "react";
import { Circle, GoogleMap, InfoWindow, Marker, useLoadScript } from "@react-google-maps/api";
import { Slider, Typography, withStyles } from "@material-ui/core";
import { Button } from "react-bootstrap";
import { Box, CircularProgress } from "@mui/material";

const GeolocationScreen = ({ pharmacies, onClick, loading, onBack }) => {
  const [activeMarker, setActiveMarker] = useState(null);
  const [addressesMarkers, setAddressesMarkers] = useState(null);
  const [markers, setMarkers] = useState(null);
  const lat = 0;//-34.869469;
  const lng = 0;//-56.167834;
  const [myLocation, setMyLocation] = useState({ lat: lat, lng: lng, accuracy: '' });
  const [center, setCenter] = useState({ lat: lat, lng: lng });
  const [zoom, setZoom] = useState(12);
  const [radius, setRadius] = useState(10000);
  const [loadingMap, setLoadingMap] = useState(false);
  // const icon = require('../../img/location.gif');
  const icon = require('../../img/pharmacy_ma2rker.png');
  const userLocation = require('../../img/user_marker.png');
  const [radiusMarks, setRadiusMarks] = useState([
    { value: 5000, label: '' },
    { value: 10000, label: '' },
    { value: 15000, label: '' },
    { value: 20000, label: '' },
    { value: 25000, label: '' },
    { value: 30000, label: '' },
    { value: 35000, label: '' },
    { value: 40000, label: '' },
    { value: 45000, label: '' },
    { value: 50000, label: '' },
  ]);
  const keyMap = 'AIzaSyAQWugJtju9rYLV4rNhyFnjjKyYDnjOQrg';
  const RadiusWorldMeters = 6371e3;
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: keyMap,
  });

  useEffect(() => {
    setLoadingMap(true);
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((result) => {
          handlePermission(result);
        });
    } else {
      console.log("Sem acesso a localizacao!");
    }
    return () => { };
  }, []);

  const handlePermission = (result) => {
    if (result.state === "granted") {
      navigator.geolocation.getCurrentPosition(success);
    } else if (result.state === "prompt") {
      navigator.geolocation.getCurrentPosition(success, errors, null);
    } else if (result.state === "denied") {
      alert("Debe otorgar permiso de acceso a su ubicación para buscar farmacias cerca de su ubicación!");
      onBack && onBack();
    }
    result.onchange = () => {
      console.log('ON CHANGE PERMISSION=> ', result.state);
    };
  };

  const success = async (pos) => {
    // console.log('SUCCESS LOCATION=> ', pos);
    var crd = pos.coords;
    setLoadingMap(false);
    setMyLocation({ lat: crd.latitude, lng: crd.longitude });
    setCenter({ lat: crd.latitude, lng: crd.longitude });
  };

  const errors = (err) => {
    // console.log(`ERROR(${err.code}): ${err.message}`);
    alert("Debe otorgar permiso de acceso a su ubicación para buscar farmacias cerca de su ubicación!");
    onBack && onBack();
  };

  const handleActiveMarker = (marker, geolocation) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
    setCenter(geolocation);
  };


  const handleOnLoad = async () => {
    console.log('HANDLEONLOAD => ');
    const addresses = [];
    pharmacies.map((item) => {
      const address = `${item.endereco.rua} ${item.endereco.bairro} ${item.endereco.cidade}`;
      if (item.geolocation && item.geolocation.lat) {
        addresses.push({ id: item.id, nome: item.nome, lat: item.geolocation.lat, lng: item.geolocation.lng, endereco: address, logo: item.logo });
      }
    });
    setAddressesMarkers(addresses);
    const distances = calculateDistance(myLocation, addresses);
    await handleProximity(distances, addresses);
  };

  const toRadians = (degrees) => {
    return degrees * Math.PI / 180;
  };

  const calculateDistance = (reference, points) => {
    const lat1 = toRadians(reference.lat);
    const lng1 = toRadians(reference.lng);

    return points.map(point => {
      const lat2 = toRadians(point.lat);
      const lng2 = toRadians(point.lng);

      const deltaLat = lat2 - lat1;
      const deltaLng = lng2 - lng1;
      const a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
        Math.cos(lat1) * Math.cos(lat2) *
        Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = RadiusWorldMeters * c;

      return { ...point, distance: d };
    });
  };

  const handleRadius = async (value) => {
    setMarkers([]);
    handleActiveMarker(1, { lat: myLocation.lat, lng: myLocation.lng })
    setRadius(value);
    await handleOnLoad();
  };

  const handleProximity = async (response, addresses) => {
    console.log('HANDLE PROXIMITY => ', response, addresses);
    let selecteds = [];
    if (response && response.length > 0) {
      for (let i = 0; i < response.length; i++) {
        const distance = response[i].distance;
        if (distance < radius) {
          selecteds.push(addresses[i]);
        }
      }
      console.log('SELECTEDS => ', selecteds);
      // setAddressesMarkers(addresses);
      // setMarkers(selecteds && selecteds.length ? selecteds : response);
      setMarkers(selecteds);
    }
  };

  const valuetext = (value) => {
    return `${value / 1000} km`;
  };

  const DistanceSlider = withStyles({
    root: {
      color: '#52af77',
      height: 8,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      marginTop: -8,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      width: '32px',
      height: '32px',
      left: 'calc(-50% + 4px)',
    },
    track: {
      height: 8,
      borderRadius: 4,
    },
    rail: {
      height: 8,
      borderRadius: 4,
    },
    mark: {
      backgroundColor: '#bfbfbf',
      height: 8,
      width: 1,
      marginTop: -3,
    },
  })(Slider);

  return (
    <div style={{ marginTop: 20 }}>

      {loadingMap && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress style={{ margin: 30 }} color="secondary" />
        </div>
      )}

      {!isLoaded ? (
        <h1>Loading...</h1>
      ) : (
        <div>
          <Typography id="discrete-slider" >
            Veamos qué farmacias tenés mas cerca. Si usted estas mas lejos podes seleccionar la distancia en quilometros.
          </Typography>
          {!loadingMap && (
            <Box style={{ marginTop: 50 }}>
              <DistanceSlider
                value={radius}
                // getAriaValueText={valuetext}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="on"
                valueLabelFormat={valuetext}
                step={5000}
                marks={radiusMarks}
                min={5000}
                max={50000}
                onChangeCommitted={(event, value) => handleRadius(value)}
              />
            </Box>
          )}
          {!loadingMap && (
            <div style={{ width: '100%', height: '500px' }}>
              <GoogleMap
                onLoad={handleOnLoad}
                onClick={() => setActiveMarker(null)}
                mapContainerClassName="map-container"
                mapContainerStyle={{ display: !loadingMap ? 'block' : 'none', width: '100%', height: '100%' }}
                center={{ lat: center.lat, lng: center.lng }}

                zoom={zoom}
                gestureHandling="greedy"
                options={{
                  // panControl: true,
                  zoomControl: true,
                  // styles: mapStyle,
                  disableDefaultUI: true,
                  // streetViewControl: false,
                  // disableDefaultUI: false,
                }}
              >
                <Marker
                  icon={userLocation}
                  key={1}
                  onClick={() => handleActiveMarker(1, { lat: myLocation.lat, lng: myLocation.lng })}
                  position={{ lat: myLocation.lat, lng: myLocation.lng }}
                  animation={2}
                >
                  <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                    <div style={{ backgroundColor: 'transparent' }}>
                      <div style={{ color: '#333', padding: '10px' }}>
                        {`Tu estás aquí`}
                      </div>
                    </div>
                  </InfoWindow>
                </Marker>
                {markers && markers.map(({ id, nome, lat, lng, endereco, logo }) => (
                  <Marker
                    title={nome}
                    icon={icon}
                    key={id}
                    position={{ lat, lng }}
                    onClick={() => handleActiveMarker(id, { lat, lng })}
                    animation={2}
                  >
                    {activeMarker === id ? (
                      <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                        <div style={{ backgroundColor: 'transparent' }}>
                          <div style={{ color: '#333', padding: '10px' }}>
                            {!loading ? (
                              <div>
                                <img style={{ borderRadius: '25px' }} width="200px" alt="logo" src={logo} />
                                <p style={{ padding: 10 }}>{endereco}</p>
                                <Button variant="outline-primary" color="primary" size="small" onClick={() => onClick(id)}>
                                  Seleccionar
                                </Button>
                              </div>
                            ) : (
                              <CircularProgress style={{ margin: 30 }} color="secondary" />
                            )}
                          </div>
                        </div>
                      </InfoWindow>
                    ) : null}
                  </Marker>
                ))}
                <Circle
                  radius={radius}
                  center={{ lat: myLocation.lat, lng: myLocation.lng }}
                  options={{
                    strokeColor: "yellowgreen",
                    strokeOpacity: 0.8,
                    strokeWeight: 2.5,
                    fillColor: "yellowgreen",
                    fillOpacity: 0.35,
                  }}
                />
              </GoogleMap>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GeolocationScreen;