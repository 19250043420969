import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { loginUser } from "../actions";
import { withStyles } from "@material-ui/styles";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Box } from "@material-ui/core";
import { Paper } from "@mui/material";

const styles = () => ({
  "@global": {
    body: {
      backgroundColor: "#fff"
    }
  },
  box: {
    marginTop: 100,
    padding: 20,
    textAlign: "center"
  },
  avatar: {
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#f50057"
  },
  form: {
    marginTop: 1
  },
  errorText: {
    color: "#f50057",
    marginBottom: 5,
    textAlign: "center"
  },
  submit: {
    marginTop: 15,
    marginBottom: 30,
  }
});

const Login = (props) => {
  const [state, setState] = useState({ email: "", password: "" });
  const [isValid, setIsValid] = useState();

  useEffect(() => {
    setIsValid(!!state.email && !!state.password);
  }, [state]);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { dispatch } = props;
    const { email, password } = state;
    dispatch(loginUser(email, password));
  };

  const { classes, loginError, isAuthenticated } = props;
  if (isAuthenticated) {
    return <Redirect to="/Dashboard" />;
  }

  return (
    <Container style={{ marginTop: 100, textAlign: "center", border: 'yellowgreen' }} component="main" maxWidth="xs">
      <Paper elevation={3} style={{ padding: 30 }}>
        <Box sx={{ marginBottom: 10 }}>
          <a href="/"><img src="/img/reservarcannabis-logo_preto.png" alt="Reserve Cannabis" width="200" /></a>
        </Box>
        <Typography component="h1" variant="h5" style={{ paddingTop: 10, paddingBottom: 10 }}>
          Panel de Administración
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label="Mail"
            name="email"
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            onChange={handleChange}
          />
          {loginError && (
            <Typography component="p" className={classes.errorText}>
              Usuario ó contraseña inválida
            </Typography>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!isValid}
            size={'large'}
          >
            Entrar
          </Button>
          <Box>
            <Typography variant={'caption'}>
              Tienes alguno inconveniente?
            </Typography>
            <Typography variant={'caption'}>
              Llama a nuestro Soporte por Whatsapp - <a href="https://wa.me/59891220420">091 220 420</a>
            </Typography>
          </Box>
        </form>
      </Paper>
    </Container>

  );

};


function mapStateToProps(state) {
  return {
    isLoggingIn: state.auth.isLoggingIn,
    loginError: state.auth.loginError,
    isAuthenticated: state.auth.isAuthenticated
  };
}

export default withStyles(styles)(connect(mapStateToProps)(Login));