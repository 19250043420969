import { db } from "../firebase/firebase";
import firebase from 'firebase';
import { makeid, getBrowserOS } from './dashboard/components/Helpers';

export const initPresence = ({ farmaciaNome, agendamentoID }) => {

  const preenchendo = false;
  let n = localStorage.getItem('key' + farmaciaNome);
  if (n === null) {
    localStorage.setItem("key" + farmaciaNome, makeid(15));
  }

  let userStatusFirestoreRef = db.collection('status').doc(localStorage.getItem('key' + farmaciaNome));

  const dateDatabase = firebase.database.ServerValue.TIMESTAMP;
  const dateFirestore = firebase.firestore.FieldValue.serverTimestamp();

  let isOfflineForFirestore = {
    state: 'offline',
    data_offline: new Date().toLocaleString('es'),
    last_changed: dateFirestore,
    preenchendo: preenchendo,
    agendamentoID: agendamentoID,
    browser: getBrowserOS(),
  };

  let isOnlineForFirestore = {
    state: 'online',
    last_changed: dateFirestore,
    farmacia: farmaciaNome,
    data_queue: new Date().toLocaleString('es'),
    preenchendo: preenchendo,
    agendamentoID: agendamentoID,
    browser: getBrowserOS()
  };

  let isOfflineForDatabase = {
    state: 'offline',
    data_offline: new Date().toLocaleString('es'),
    last_changed: dateDatabase,
    preenchendo: preenchendo,
    agendamentoID: agendamentoID,
    browser: getBrowserOS(),
  };

  let isOnlineForDatabase = {
    state: 'online',
    last_changed: dateDatabase,
    farmacia: farmaciaNome,
    data_queue: new Date().toLocaleString('es'),
    preenchendo: preenchendo,
    agendamentoID: agendamentoID,
    browser: getBrowserOS()
  };

  let userStatusDatabaseRef = firebase.database().ref('/status/' + localStorage.getItem('key' + farmaciaNome));

  firebase.database().ref('.info/connected').on('value', function (snapshot) {
    if (snapshot.val() === false) {
      userStatusFirestoreRef.set(isOfflineForFirestore, { merge: true });
      userStatusDatabaseRef.remove();
      db.collection('status').doc(userStatusFirestoreRef.id).delete()
      return;
    }
    userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function () {
      userStatusDatabaseRef.set(isOnlineForDatabase);
      userStatusFirestoreRef.set(isOnlineForFirestore, { merge: true });
    });


  });
}

