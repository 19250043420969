import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import * as serviceWorker from "./service-worker";
import { Button } from "@material-ui/core";
import Routes from "./Routes";

const App = (props) => {
  const { isAuthenticated, isVerifying, enqueueSnackbar } = props;
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState({});

  const onServiceWorkerUpdate = (registration) => {
    setWaitingWorker(registration.waiting);
    setNewVersionAvailable(true);
  };

  const updateServiceWorker = () => {
    const _waitingWorker = waitingWorker;
    _waitingWorker && _waitingWorker.postMessage({ type: "SKIP_WAITING" });
    setNewVersionAvailable(true);
    window.location.reload(true);
  };

  const refreshAction = () => {
    return (
      <Fragment>
        <Button
          className="snackbar-button"
          size="small"
          onClick={() => updateServiceWorker()}
        >
          {"actualizar"}
        </Button>
      </Fragment>
    );
  };

  useEffect(() => {
    var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if ((isSafari && iOS) || (isSafari)) {
      serviceWorker.unregister();
    } else {
      serviceWorker.register({ onUpdate: onServiceWorkerUpdate });
      if (newVersionAvailable) {
        enqueueSnackbar("Una nueva versión fue lanzada! Es importante actualizar su aplicación!!", {
          persist: true,
          variant: "success",
          action: refreshAction(),
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          }
        });
        setTimeout(() => {
          console.log('SET TIMEOUT UPDATE SERVICE WORKER ', );
          updateServiceWorker();
        }, 3000);
      }
    }
  }, [enqueueSnackbar, newVersionAvailable, refreshAction]);

  return (
    <Routes isAuthenticated={isAuthenticated} isVerifying={isVerifying} />
  );
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying
  };
}

export default connect(mapStateToProps)(withSnackbar(App));