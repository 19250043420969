import React, { useState } from 'react';
import { db } from "../firebase/firebase";
import Header from './parts/header';
import format from 'date-fns/format';
import { ptBR } from 'date-fns/locale';

function ListEmailReserva(props) {
	const [list, setList] = useState('');
	const [email, setEmail] = useState('teste@gmail.com');

	const getEmails = () => {
		db.collection("reservas")
			.where('farmaciaID', '==', 'qpK4UncBpWgDPqHy1iWDNqQTWb92')
			.where('status', '==', 'retirado')
			.where('horacadastro', '>', new Date('2021-01-01'))
			// .limit(10)
			// .orderBy('nome', 'asc')
			.onSnapshot(function (querySnapshot) {

				const tempDoc = querySnapshot.docs.map((doc) => {
					return { id: doc.id, ...doc.data() };
				});

				const filter = tempDoc.map(item => {
					return `${(item.nome).toUpperCase()}, ${item.email}`;
				});

				const emails = [...new Set(filter)];
				console.log(emails.join(";"));
			});
	};

	function getReservas() {

		db.collection('reservas')
			.where("reservaID", "==", "mRi6QWKl7pdBDX1SR6zE")
			.where("farmaciaID", "==", "rGHVqhfphbg48Q6zcxl9ahcdnki2")

			// .where("reservaID", "==", "qJz0nwH1rGPTux5eVpOM")
			// .where("farmaciaID", "==", "pAu9pk0o8AcGA3Sy4jTLxstDf6e2")

			// .where("reservaID", "==", "oUWHArO7pOkgeO30YLBr")
			// .where("farmaciaID", "==", "rGHVqhfphbg48Q6zcxl9ahcdnki2")

			// .where("reservaID", "==", "mAmEjPucN77PI9TcidS0")
			// .where("farmaciaID", "==", "XfVPvaVKlpVP8SgDM47DGFdi1c43")
			.onSnapshot((querySnapshot) => {
				let docs = querySnapshot.docs;
				let obj = [];
				docs.forEach(function (d) {
					console.log(d.data().email);
					obj.push(d.data().email);

				});
				setList(obj.join(','));
				console.log(obj.join(','));

			});
	}

	const diaHoje = new Date();//.toLocaleString('pt-BR');
	diaHoje.setHours(23, 59, 59, 0);
	diaHoje.setDate(diaHoje.getDate() - 1);

	const diaAmanha = new Date();//.toLocaleString('pt-BR');
	diaAmanha.setHours(0, 0, 0, 0);
	diaAmanha.setDate(diaAmanha.getDate() + 1);

	const getCronJobs = async () => {
		console.log("GET CRON JOBS..");

		let cronRef = db.collection('cron');
		let query = new Promise((resolve, reject) => {
			cronRef
				.where("data", ">", diaHoje)
				.where("data", "<", diaAmanha)
				.orderBy("data", "desc")
				.get()
				.then((snapshot) => {
					if (snapshot.empty) {
						console.log('NO MATCHING DOCUMENTS.');
						reject('NO MATCHING DOCUMENTS.');
					} else {
						console.log('MATCHING DOCUMENTS!!');
						resolve(snapshot);
						// 
					}
				})
				.catch(err => {
					reject('Error getting documents');
					console.log('Error getting documents', err);
				});
		});

		const result = await query;
		setDataCronJobs(result);

	};

	const setDataCronJobs = (snapshot) => {
		let jobs = [];
		snapshot.forEach(doc => {
			jobs.push({
				data: format(new Date(doc.data().data.seconds * 1000), "dd/MM/yyyy HH:mm", { locale: ptBR }),
				cronID: doc.id,
				hour: new Date(doc.data().data.seconds * 1000).getHours(),
				minute: new Date(doc.data().data.seconds * 1000).getMinutes(),
				agendamentoID: doc.data().agendamentoID,
				farmaciaID: doc.data().farmaciaID,
				cron: `0 ${new Date(doc.data().data.seconds * 1000).getMinutes()} ${new Date(doc.data().data.seconds * 1000).getHours()} * * *`
			});
		});
		createTask(jobs);

	};

	const createTask = jobs => {
		console.log("CREATE TASK...");
		console.log(jobs);
	};

	function handleSubmit() {
		getReservas();
		// e.preventDefault();
		// console.log(validateEmailDomain(email));

		// db.collection("cron").get().then((querySnapshot) => {
		// 	querySnapshot.forEach((doc) => {
		// 		console.log(doc.data());
		// 		// console.log(`${doc.id} => ${doc.data()}`);
		// 	});
		// });

		// console.log(diaHoje);
		// console.log(diaAmanha);

		// db.collection("cron")
		// 	.where("data", ">", diaHoje)
		// 	.where("data", "<", diaAmanha)
		// 	.orderBy("data", "asc")
		// 	.get()
		// 	.then((querySnapshot) => {
		// 		// console.log(querySnapshot);
		// 		let jobs = [];
		// 		querySnapshot.forEach(function (doc) {
		// 			// doc.data() is never undefined for query doc snapshots
		// 			// console.log(doc.id, " => ", doc.data());
		// 			let data = format(new Date(doc.data().data.seconds * 1000), "dd/MM/yyyy HH:mm", { locale: ptBR });
		// 			let _data = new Date(doc.data().data.seconds * 1000);
		// 			let hour = format(_data, "HH", { locale: ptBR });
		// 			let minute = format(_data, "mm", { locale: ptBR });
		// 			jobs.push({
		// 				data: data,
		// 				cronID: doc.id,
		// 				hour: new Date(doc.data().data.seconds * 1000).getHours(),
		// 				minute: new Date(doc.data().data.seconds * 1000).getMinutes(),
		// 				agendamentoID: doc.data().agendamentoID,
		// 				farmaciaID: doc.data().farmaciaID,
		// 				cron: `0 ${new Date(doc.data().data.seconds * 1000).getMinutes()} ${new Date(doc.data().data.seconds * 1000).getHours()} * * *`
		// 			});
		// 		});
		// 		console.log(jobs);
		// 	});

		// db.collection("cron")
		// .where("data",">=", new Date())
		// .get()
		// .then(function (doc) {
		// 	if (doc.exists) {
		// 		console.log("Document data: ", doc.data());
		// 	} else {
		// 		// doc.data() will be undefined in this case
		// 		console.log("No such document! ");
		// 	}
		// }).catch(function (error) {
		// 	console.log("Error getting document:", error);
		// });

		// const keyUser = localStorage.getItem('keyFarmacia Prueba');
		// if (keyUser) {
		// axios({
		// 	method: "POST",
		// 	url: "https://us-central1-reservar-cannabis-uru.cloudfunctions.net/widgets/avisoDataReservaEmail",
		// 	// data: { key: keyUser }
		// }).then((response) => {
		// 	console.log(response);
		// })
		// }
	}

	function validateEmailDomains() {

		let validEmail = false;
		const isInvalid = ['gmil', 'gmal', 'gmeil', 'gmei', 'gmaiu', 'gmai', 'hotmeil', 'hotmil',
			'hotmeil', 'gmi', 'gmaail', 'gnail', 'maail', 'gmi', 'hotmaik', 'gmaik',
			'hotmai', 'hormail', 'hormai', 'hotnail', 'hotmaiu', 'homail', 'mail', 'gmaiul', 'maiul', 'maiu'];

		const extensionInvalid = ['amish', 'arrow', 'badname', 'be', 'beez', 'bk', 'borg', 'bs', 'bugs', 'ça', 'cabal',
			'cam', 'cat', 'cc', 'con', 'coom', 'comm', 'coon', 'dash', 'dot', 'df', 'disinfo', 'dog', 'dumb',
			'euth', 'fargo', 'flee', 'fraud', 'freud', 'garbage', 'gay', 'gb', 'god', 'hack', 'hell', 'hex',
			'invalid', 'iq', 'junk', 'kibo', 'linux', 'lost', 'manure', 'mcjobs', 'moon', 'ms', 'nf', 'nfg',
			'not', 'nude', 'nuer', 'nuke', 'null', 'nyet', 'oldnews', 'on', 'ow', 'oz', 'ph', 'phish', 'poo',
			'pro', 'pt', 'quack', 'real', 'rh', 'rot13', 'rs', 'scam', 'sco', 'sf', 'shit', 'sin', 'snot',
			'snowjobs', 'so', 'sony', 'spam', 'spy', 'su', 'sue', 'sux', 'tb', 'titanic', 'toga', 'uk',
			'uk9', 'um', 'un', 'us', 'vi', 'wales', 'yu'];

		let address = email.split("@");
		let ext = address[1];
		let ext1 = ext.split('.');
		let ext2 = ext1[0];
		let ext3 = ext1[1];
		let ext4 = ext1[2] ? ext1[2] : null;
		let ext5 = ext1[3] ? ext1[3] : null;

		const validDomain = !isInvalid.includes(ext2);

		const validExtension = !extensionInvalid.includes(ext3);

		if (validDomain) {
			if (email.search(/\gmail\b/i) > 0) {
				if (ext === 'gmail.com')
					validEmail = true;
			}
		}

		console.log({ address, ext, ext1, ext2, ext3, ext4, ext5, validDomain, validExtension, validEmail });

		return validDomain && validExtension && validEmail;
	}

	return (
		<div>
			<Header />
			<div>
				{/* <label>Digite o e-mail</label>
				<input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
				<br />
				<br /> */}
				{/* <button type="button" onClick={() => validateEmailDomain()}>Validar e-mail</button> */}
				{/* <button type="button" onClick={() => handleSubmit()}>Validar e-mail</button> */}

			</div>
			{/* <EnviarEmailReservas /> */}
			{/* <EnhancedTable /> */}
		</div>
	);

}

export default ListEmailReserva;

