import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, Switch, TextField } from '@mui/material';
import ProductColorPicker from './ProductColor';

const ProductFormDialog = ({ title, open, onCancel, onConfirm, product, onChange, onColor, setProduct }) => {

    return (
        <div>
            <Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To add new product filled required fields.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        variant="outlined"
                        margin="dense"
                        name="name"
                        label="Nome"
                        type="text"
                        fullWidth
                        value={product?.name}
                        onChange={onChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="dense"
                        name="description"
                        label="Description"
                        type="text"
                        fullWidth
                        value={product?.description}
                        onChange={onChange}
                    />
                    <Grid container direction={'row'}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                name="total"
                                label="Total em estoque"
                                type="number"
                                value={product?.total}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid container direction={'row'} spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    margin="dense"
                                    name="minimum"
                                    label="Minimo para retirar"
                                    type="number"
                                    value={product?.minimum}
                                    onChange={onChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    margin="dense"
                                    name="maximum"
                                    label="Maximo para retirar"
                                    type="number"
                                    value={product?.maximum}
                                    onChange={onChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <FormControlLabel
                            style={{ marginTop: 25, width: '100%', color: '#333' }}
                            control={
                                <Switch
                                    checked={product?.status}
                                    onChange={(e) => setProduct({ ...product, status: e.target.checked })}
                                    name="status"
                                    color="primary"
                                />
                            }
                            label="Status"
                        />
                    </Grid>
                    <ProductColorPicker
                        onChange={onColor}
                        color={product?.color} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={() => onConfirm(product)} color="primary">
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ProductFormDialog;
