import React, { useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import { compose } from 'redux';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { ViewState, EditingState } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  MonthView,
  Appointments,
  Toolbar,
  DateNavigator,
  AppointmentTooltip,
  AppointmentForm,
  EditRecurrenceMenu,
  Resources,
  WeekView,
} from '@devexpress/dx-react-scheduler-material-ui';

import Button from '@material-ui/core/Button';

import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { logoutUser } from "../../actions";
import MenuAppBar from './components/Appbar';
import Copyright from './components/Copyright';
import { withStyles } from '@material-ui/core/styles';
import { Chip, Container, createStyles } from '@material-ui/core';
import { myFirebase, db } from "../../firebase/firebase";
import TextField from '@material-ui/core/TextField';

import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Alert, AlertTitle } from '@material-ui/lab';

import differenceInSeconds from 'date-fns/differenceInSeconds';
import { FormatDatetoFB, chunk } from './components/Helpers';
import moment from 'moment';
import 'moment/locale/es';
import { Face } from '@material-ui/icons';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, } from '@mui/material';
import api from '../../api/api';
import { version, version_app } from "../../../package.json";
const sendEmailPermission = true;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appointment: {
    backgroundColor: '#808080',
    borderRadius: '10px',
    '&:hover': {
      opacity: 0.6,
      backgroundColor: '#808080',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  imageList: {
    flexWrap: "nowrap"
  },
  image: {
    maxWidth: 200
  }
});

const allDayLocalizationMessages = {
  'es-ES': {
    detailsLabel: '',
    allDayLabel: 'Dia Todo',
    titleLabel: 'descripción',
    commitCommand: 'Guardar',
    moreInformationLabel: '',
    repeatLabel: 'Repetir?',
    notesLabel: 'Notas',
    never: 'Nunca',
    daily: 'Diariamente',
    weekly: 'Semanal',
    monthly: 'Mensual',
    yearly: 'Anualmente',
    repeatEveryLabel: 'Repite todo',
    daysLabel: 'dia(s)',
    endRepeatLabel: 'Fin de repetición',
    onLabel: 'En',
    afterLabel: 'Despues',
    occurrencesLabel: 'ocurrencia(s)',
    weeksOnLabel: 'semana(s) en:',
    monthsLabel: 'mes(es)',
    ofEveryMonthLabel: 'todos los meses',
    theLabel: 'O',
    firstLabel: 'Primero',
    secondLabel: 'Segundo',
    thirdLabel: 'Tercero',
    fourthLabel: 'Cuarto',
    lastLabel: 'Ultimo',
    yearsLabel: 'ano(s)',
    ofLabel: 'de',
    everyLabel: 'Todos',
    current: 'Esa Fecha',
    currentAndFollowing: 'Este y el siguiente',
    all: 'Todas las fechas',
    menuEditingTitle: 'Editar todas las fechas?',
    menuDeletingTitle: 'Eliminar todas las fechas?',
    cancelButton: 'Cancelar',
    commitButton: 'OK',
    confirmDeleteMessage: 'Deseja deletar?',
  },
};

const Calendario = () => {
  const fbUser = myFirebase.auth().currentUser.uid;
  const [data, setData] = useState([]);
  const [listProducts, setListProducts] = useState([]);
  const [userId] = useState(fbUser);
  const [productSelected, setProductSelected] = useState([]);
  const inputRef = useRef([]);
  const PACKAGES_QTDE = 2;
  const [state, setState] = useState(
    {
      farmaciaData: [],
      locale: 'es-ES',
      severity: 'error',
      title: 'Teste',
      message: 'Teste1',
      visiblity: false,
      showDialog: false,
      farmaciaID: fbUser,
      userId: fbUser,
    }
  );

  useEffect(() => {
    const userDocRef = db.collection("usuarios").doc(userId);
    const agendamentosRef = userDocRef.collection("agendamentos");

    const agendamentosUnsubscribe = agendamentosRef.onSnapshot(querySnapshot => {
      const itens = querySnapshot.docs.map(doc => {
        const data = doc.data();
        if (!data.startDate?.seconds) {
          return null;
        }
        return {
          id: data.id,
          agendamentoID: doc.id,
          cronID: data.cronID,
          title: handleTitleEs(
            handleNewDate(data.startDate),
            handleNewDate(data.endDate)
          ),
          startDate: handleNewDate(data.startDate),
          endDate: handleNewDate(data.endDate),
          dataFimRet: handleNewDate(data.dataFimRet),
          dataInicioRet: handleNewDate(data.dataInicioRet),
          products: data.products,
          numerosRes: data.numerosRes,
          numerosPacotes: data.numerosPacotes,
          totalPacotes: data.totalPacotes,
          resAtiva: data.resAtiva,
          observacao: data.observacao,
          fixed: data.fixed
        };
      }).filter(item => item !== null);

      setData(itens);
    });

    const farmaciaDocRef = db.collection("usuarios").doc(fbUser);
    const farmaciaPromise = farmaciaDocRef.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        return {
          farmaciaID: doc.id,
          farmaciaNome: data.nome,
          farmaciaLogo: data.logo,
          farmaciaRua: data.endereco.rua,
          farmaciaCidade: data.endereco.cidade
        };
      }
      return null;
    });

    const unsubscribeAll = () => {
      agendamentosUnsubscribe();
    };

    Promise.all([farmaciaPromise])
      .then(([farmaciaData]) => {
        setState({ farmaciaData });
      })
      .catch(error => {
        console.error("Error fetching data: ", error);
      });

    return unsubscribeAll;
  }, [userId, fbUser]);


  useEffect(() => {
    const unsubscribe = db.collection("products")
      .where('status', '==', true)
      .orderBy("name", "asc")
      .onSnapshot(querySnapshot => {
        const products = querySnapshot.docs.map(doc => ({
          id: doc.id,
          name: doc.data().name,
          text: doc.data().name,
          description: doc.data().description,
          color: doc.data().color,
          total: doc.data().total,
          minimum: doc.data().minimum,
          maximum: doc.data().maximum,
          qtde: 0,
        }));
        setListProducts(products);
      });
    return unsubscribe;
  }, []);


  const TextEditor = (props) => {
    return null;
  };

  const BooleanEditor = (props) => {
    return null;
  };

  const DateEditor = (props) => {
    // eslint-disable-next-line react/destructuring-assignment
    return null;
  };

  const LabelComponent = (props) => {
    // eslint-disable-next-line react/destructuring-assignment
    return null;
  };

  const handleTitleEs = (ini, fim) => {
    let title = `${(ini).toLocaleDateString('es-ES', { weekday: 'long' })} - ${(fim).toLocaleDateString('es-ES', { weekday: 'long' })}`;
    return title;
  };

  const BasicLayout = ({ onFieldChange, classes, appointmentData, appointmentResources, resources, ...restProps }) => {
    if (appointmentResources.length === 0) {
      appointmentResources = appointmentData.products;
    } else {
      if (appointmentData.products && appointmentData.products.length > 0) {
        console.log('------- EXISTS PRODUCTS --------');
        const filteredProducts = appointmentData.selectProducts
          ? appointmentData.products.filter(product => appointmentData.selectProducts.includes(product.id))
          : appointmentData.products;

        if (appointmentData.selectProducts && appointmentData.selectProducts.length > filteredProducts.length) {
          let newprod = appointmentResources.slice(-1);
          newprod[0].qtde = 0;
          appointmentData.products.push(newprod[0]);
        } else {
          appointmentData.products = filteredProducts;
        }
        appointmentResources = appointmentData.products;
      }
    }

    const hoje = new Date(appointmentData.startDate);
    if (hoje.getHours() <= 0 && hoje.getMinutes() <= 0 && hoje.getSeconds() <= 0) {
      hoje.setHours(11, 0, 0, 0);
      appointmentData.startDate = hoje;
    }

    const diaAmanha = new Date(appointmentData.startDate);
    const diaAmanhaIn = new Date(appointmentData.startDate);
    diaAmanhaIn.setHours(9, 0, 0, 0);
    diaAmanhaIn.setDate(diaAmanha.getDate() + 1);
    diaAmanha.setDate(diaAmanha.getDate() + 1);

    appointmentData.allDay = false;

    if (!appointmentData.dataInicioRet) {
      appointmentData.dataInicioRet = diaAmanhaIn;
    }

    if (!appointmentData.dataFimRet) {
      diaAmanha.setHours(18, 0, 0, 0);
      appointmentData.dataFimRet = diaAmanha;
    }

    if (appointmentData.resAtiva === false) {
      appointmentData.resAtiva = false;
    } else if (appointmentData.resAtiva === true) {
      appointmentData.resAtiva = true;
    } else {
      appointmentData.resAtiva = true;
    }

    appointmentData.fixed = appointmentData.fixed === true;

    const handleDateChange = (date) => {
      onFieldChange({ startDate: date });
    };

    const onDataInicioChange = (nextValue) => {
      if (differenceInSeconds(nextValue, appointmentData.startDate) > 60) {
        onFieldChange({ errorDataInicial: false, msgDataInicial: '' });
      } else {
        onFieldChange({ errorDataInicial: true, msgDataInicial: "Data inválida" });
      }
      onFieldChange({ dataInicioRet: nextValue });
      onFieldChange({
        title: appointmentData.startDate.toLocaleDateString('es-ES', { weekday: 'long' }) + " - " +
          appointmentData.dataInicioRet.toLocaleDateString('es-ES', { weekday: 'long' })
      });
    };

    const onDataFimChange = (nextValue) => {
      if (differenceInSeconds(nextValue, appointmentData.dataInicioRet) > 60) {
        onFieldChange({ errorDataFinal: false, msgDataFinal: "" });
      } else {
        onFieldChange({ errorDataFinal: true, msgDataFinal: "Data inválida" });
      }
      onFieldChange({ dataFimRet: nextValue });
      onFieldChange({
        title: appointmentData.startDate.toLocaleDateString('es-ES', { weekday: 'long' }) + " - " +
          appointmentData.dataInicioRet.toLocaleDateString('es-ES', { weekday: 'long' })
      });
    };

    const onNumerosChange = (event) => {
      onFieldChange({ numerosRes: event.target.value });
      if (event.target.value > 0 && event.target.value <= 5000) {
        onFieldChange({ numerosRes: event.target.value, errorNumeros: false, msgNumeros: '' });
      } else {
        onFieldChange({ errorNumeros: true, msgNumeros: 'Número inválido' });
      }
    };

    const handleResAtiva = (ativa) => {
      onFieldChange({ resAtiva: ativa.target.checked });
    };

    const handleFixed = (fixed) => {
      onFieldChange({ fixed: fixed.target.checked });
    };

    const handleObservacao = (observacao) => {
      onFieldChange({ observacao: observacao.target.value });
    };


    const handleChange = (e, item) => {
      const value = e.target.value;
      let resources = [];
      item.qtde = value;

      if (appointmentResources && appointmentResources.length > 0) {
        const maped = appointmentResources.map((prod) => {
          if (prod.id == item.id) {
            return item;
          } else {
            if (!prod.qtde)
              prod.qtde = 0;
            return prod;
          }
        });
        resources = maped;
      } else {
        resources.push(item);
      }
      const numerosPacotes = resources.reduce(
        (total, itz) => total + parseInt(itz.qtde),
        0
      );
      const numerosRes = Math.ceil(numerosPacotes / 2);
      onFieldChange({ products: resources, numerosPacotes: numerosPacotes, numerosRes: numerosRes });
    };

    return (
      <div>
        <div className="row" style={{ paddingLeft: 50, marginTop: 0 }}>
          <AppointmentForm.Label text='Variedades Disponibles para El Dia' type='titleLabel' style={{ fontWeight: "600" }} />
        </div>
        <AppointmentForm.BasicLayout
          {...restProps}
          appointmentData={appointmentData}
          onFieldChange={onFieldChange}
          appointmentResources={appointmentResources}
          resources={resources}
        >
          <div className="row" style={{ marginTop: 20, paddingLeft: 15, paddingRight: 15, paddingTop: 5 }}>
            {appointmentResources && appointmentResources.length && appointmentResources.map((item, idx) => (
              <Grid container
                key={idx}
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={2}
                style={{ paddingBottom: 20, marginTop: 5 }}
              >
                <Grid item xs={8}>
                  <span style={{ borderWidth: 1, borderStyle: 'solid', borderColor: item.color ?? '#c2c2c2', borderRadius: 4, padding: 20, color: '#333', fontSize: 18 }}>
                    {item?.text}
                  </span>
                </Grid>
                <Grid item xs={4}>
                  <Box sx={{ display: 'flex' }}>
                    <TextField
                      autoComplete='off'
                      inputRef={el => inputRef.current[item.id] = el}
                      onChange={(e) => handleChange(e, item)}
                      name={item.id}
                      id={item.id}
                      value={item.qtde || ''}
                      variant="outlined"
                      type='text'
                    />
                  </Box>
                </Grid>
              </Grid>
            ))}
          </div>

          <Box>
            <table className='table table-bordered' style={{ marginTop: 20, display: 'none' }}>
              <thead style={{ textAlign: 'center' }}>
                <th>Suma de los paquetes</th>
                <th>Total atendimentos</th>
              </thead>
              <tbody>
                <tr style={{ textAlign: 'center' }}>
                  <td>{appointmentData.numerosPacotes || 0}</td>
                  <td>{appointmentData.numerosRes || 0}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'center' }}><h4 style={{ fontWeight: "600", paddingBottom: 0 }}>Clientes Atendidos </h4></td>
                  <td>
                    <TextField
                      disabled
                      style={{ width: '100%', border: 'none' }}
                      autoComplete="off"
                      value={appointmentData.numerosRes || ''}
                      onChange={onNumerosChange}
                      type="text"
                      id="numerosRes"
                      error={appointmentData.errorNumeros}
                      helperText={appointmentData.msgNumeros}
                      InputProps={{
                        pattern: "[0-9]",
                        textAlign: 'center',
                        disableUnderline: true
                      }}
                      inputProps={{
                        autoComplete: "off",
                        style: { textAlign: 'center', fontSize: 22, fontWeight: 'bolder' }
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

          </Box>

          <Box style={{ display: 'flex', marginTop: 30, flexDirection: 'row', paddingLeft: 15, justifyContent: 'space-between', borderWidth: 1, borderStyle: 'solid', borderColor: '#c2c2c2', borderRadius: 4 }}>
            <Grid item xs={6}>
              <FormControlLabel
                style={{ marginTop: 15, paddingLeft: 20, width: '100%', color: '#333' }}
                control={
                  <Switch
                    checked={appointmentData.resAtiva}
                    onChange={handleResAtiva}
                    name="resAtiva"
                    id="resAtiva"
                    color="primary"
                  />
                }
                label="Reserva Activa"
              />
            </Grid>

            <Grid item xs={6}>
              <FormControlLabel
                style={{ marginTop: 15, width: '100%', color: '#333' }}
                control={
                  <Switch
                    checked={appointmentData.fixed}
                    onChange={handleFixed}
                    name="fixed"
                    id="fixed"
                    color="primary"
                  />
                }
                label="Reserva Destacada"
              />
              <small>* Una reserva destacada a la vez. </small><br /><br />
            </Grid>

          </Box>


          <Box style={{ marginTop: 15 }}>

            <AppointmentForm.DateEditor
              style={{ marginTop: 20 }}
              onValueChange={handleDateChange}
              placeholder="Fecha y hora"
              locale={'es'}
              disableToolbar
              ampm={false}
              autoOk={true}
              variant="inline"
              inputVariant="outlined"
              label="Fecha y Hora de Liberación de la Reserva"
              format="DD/MM/yyyy HH:mm"
              margin="none"
              fullWidth={true}
              id="startDate"
              value={new Date(appointmentData.startDate)}
              minDate={new Date(appointmentData.startDate)}
              maxDate={new Date(appointmentData.startDate)}
              invalidDateMessage="Data invalida"
            />

            <AppointmentForm.DateEditor
              style={{ marginTop: 20 }}
              onValueChange={onDataInicioChange}
              id="dataInicioRet"
              locale={'es'}
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              format="DD/MM/yyyy HH:mm"
              margin="none"
              fullWidth={true}
              label="Fecha y Hora de Retirada"
              value={new Date(appointmentData.dataInicioRet)}
              error={appointmentData.errorDataInicial}
              helperText={appointmentData.msgDataInicial}
              autoOk={true}
              ampm={false}
            />


            <AppointmentForm.DateEditor
              style={{ marginTop: 20 }}
              onValueChange={onDataFimChange}
              id="dataFimRet"
              locale={'es'}
              disableToolbar
              variant="inline"
              format="DD/MM/yyyy HH:mm"
              margin="none"
              fullWidth={true}
              label="Fecha y Hora Termina la Retirada"
              inputVariant="outlined"
              value={new Date(appointmentData.dataFimRet)}
              error={appointmentData.errorDataFinal}
              helperText={appointmentData.msgDataFinal}
              autoOk={true}
              ampm={false}
            />



            <TextField
              label="Aviso"
              type="text"
              id="observacao"
              value={appointmentData.observacao || ''}
              style={{ width: '100%', marginTop: 20 }}
              onChange={handleObservacao}
              variant="outlined"
              placeholder="Ejemplo: Cerrado para el almuerzo de las 12 horas a 16 horas."
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              error={appointmentData.errorHoraInicial}
              helperText={appointmentData.msgHoraInicial}
            />

          </Box>
        </AppointmentForm.BasicLayout >
      </div >
    );
  };




  const getAllDayMessages = locale => allDayLocalizationMessages[locale];

  const TimeTableCell = React.memo(({ onDoubleClick, ...restProps }) => (
    <MonthView.TimeTableCell
      {...restProps}
      onDoubleClick={() => handleDoubleClick(onDoubleClick)}
      onClick={() => console.log('ON CLICK => ')}
      isShaded={true}
    />
  ));

  const handleDoubleClick = (onDoubleClick) => {
    setProductSelected([]);
    onDoubleClick && onDoubleClick();
  };

  const Appointment = withStyles(styles, { name: 'Appointment' })(({ classes, data, children, ...restProps }) => {
    const color = data.fixed ? '#7a98ff' : '#D9DFE2';
    return (
      <Appointments.Appointment
        {...restProps}
        data={data}
        style={{
          backgroundColor: color,
          fontSize: '8px',
          color: '#000000',
          paddingLeft: '3px',
          margin: '-2px 0px',
        }}
      // className={classes.appointment}
      >
        {children}
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          {data && data.products && data.products.map((item, idx) => (
            <div key={idx} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 5, marginRight: 5 }}>
              <span style={{ background: item.color, width: 15, height: 15, borderRadius: 50 }}></span>
              {/* <span style={{ fontSize: 11, fontWeight: '400' }}>{item.text}</span> */}
              <span style={{ fontSize: 11, fontWeight: '400', marginLeft: 5 }}>{item.qtde}</span>
            </div>
          ))}
        </div>
      </Appointments.Appointment>
    );
  });



  const AppointmentContent = withStyles(styles, { name: 'AppointmentContent' })(({ classes, ...restProps }) => (
    <Appointments.AppointmentContent {...restProps} className={classes.apptContent} />
  ));

  const FlexibleSpace = withStyles(styles, { name: 'ToolbarRoot' })(({ classes, ...restProps }) => (
    <Toolbar.FlexibleSpace {...restProps} className={classes.flexibleSpace}>
      <div className={classes.flexContainer}>
        <Typography variant="h5" style={{ marginLeft: '10px' }}>Reservas</Typography>
      </div>
    </Toolbar.FlexibleSpace>
  ));

  const Content = (({
    children, data, appointmentData, ...restProps
  }) => {
    // console.log('APPOINTMENTDATA => ', restProps);
    return (
      <AppointmentTooltip.Content {...restProps} appointmentData={appointmentData}>
        <Grid container alignItems="center">
          <Grid item>
            {/* <HandleShowType data={appointmentData} /> */}
            {appointmentData?.products && appointmentData.products.map((item, idx) => {
              return (
                <Chip
                  key={idx}
                  icon={<Face style={{ color: item.color }} />}
                  label={item.text}
                  style={{ borderWidth: 1, marginLeft: 20 }}
                  variant="outlined"
                />
              );
            })}
          </Grid>
        </Grid>
      </AppointmentTooltip.Content>
    );
  });

  const handleNewDate = (oldate) => {
    const startDate = oldate;
    const { seconds } = startDate;
    const newdate = moment(new Date(seconds * 1000));
    return newdate._i;
  };


  const handleMessage = () => {
    setTimeout(
      function () {
        setState({ visiblity: false });
      },
      3000);
  };

  const commitChanges = ({ added, changed, deleted }) => {
    if (added) {
      addAgendamento(added);
    }

    if (changed) {
      updateAgendamento(changed);
    }

    if (deleted !== undefined) {
      deleteAgendamento(deleted);
    }
  };

  const getFormData = () => {
    let form = {
      startDate: document.getElementById('startDate').value,
      // startHour: document.getElementById('startHour').value,
      dataInicioRet: document.getElementById('dataInicioRet').value,
      // horaInicioRet: document.getElementById('horaInicioRet').value,
      dataFimRet: document.getElementById('dataFimRet').value,
      // horaFimRet: document.getElementById('horaFimRet').value,
      observacao: document.getElementById('observacao').value,
      numerosRes: document.getElementById('numerosRes').value,
      resAtiva: document.getElementById('resAtiva').checked,
      fixed: document.getElementById('fixed').checked,
    };

    return form;
  };

  const addAgendamento = async (added) => {
    let obj = {};
    const startingAddedId = parseInt((new Date().getTime() / 1000).toFixed(0));
    const result = validateAgendamento(added, startingAddedId);

    const farmaciaID = fbUser;
    let next = true;
    if (added.fixed === true) {
      const check = await checkExistFixed();
      if (check.length) {
        next = false;
      }
    }
    if (!next) {
      obj = {
        title: "Error!",
        message: "Por ahora solo es posible dejar una reserva como destacada, si quieres seguir recomendamos sacar la opción de destacado de la reserva que pusiste anteriormente.",
        severity: "error",
        visiblity: true,
      };
    } else if (result.error) {
      obj = {
        title: "Aviso:",
        message: result.errors.join(', '),
        severity: "error",
        visiblity: true
      };
    } else {
      delete result.error;
      delete result.errors;
      result.versionNow = {
        version: version,
        versionApp: version_app,
      };
      try {
        db.collection("usuarios")
          .doc(farmaciaID)
          .collection("agendamentos")
          .add(result).then(ref => {
            const agendamentoID = ref.id;

            db.collection("cron")
              .add({
                farmaciaID: farmaciaID,
                agendamentoID: agendamentoID,
                data: result.startDate,
                complete: false
              }).then(refCron => {

                db.collection("usuarios")
                  .doc(farmaciaID)
                  .collection("agendamentos")
                  .doc(agendamentoID).set({ agendamentoID: agendamentoID, cronID: refCron.id }, { merge: true });

              });

            // data = ;
            setData([...data, { id: startingAddedId, ...result }]);
          });
        obj = {
          title: "Exito!",
          message: "La reserva ha sido editada",
          severity: "success",
          visiblity: true,
        };
      } catch (error) {
        obj = {
          title: "Erro!",
          message: "La reserva erro",
          severity: "error",
          visiblity: true,
        };
      }

    }
    setState(obj);
  };

  const checkExistFixed = async () => {
    let response = new Promise((resolve) => {
      db.collection("usuarios")
        .doc(userId)
        .collection("agendamentos")
        .where("fixed", "==", true)
        .onSnapshot((querySnapshot) => {
          const tempDoc = querySnapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
          });
          resolve(tempDoc);
        });
    });
    response.then(function (result) {
      return result;
    });
    return response;
  };

  const validateAgendamento = (doc, id) => {


    const form = getFormData();

    console.log('DOC => ', doc);
    console.log('FORM => ', form);

    let errors = [];
    let ag = {};

    ag.id = id ? id : doc.id;

    if (!form.startDate) {
      errors.push("Fecha reserva");
    }

    ag.endDate = doc.endDate;
    ag.agendamentoID = doc.agendamentoID || '';

    if (!form.dataInicioRet) {
      errors.push("Fecha Empeza la Retirada");
    } else {

    }

    if (!form.dataFimRet) {
      errors.push("Fecha Termina la Retirada");
    }

    let dtstart = FormatDatetoFB(form.startDate);
    let dtini = FormatDatetoFB(form.dataInicioRet);
    let dtfim = FormatDatetoFB(form.dataFimRet);

    if (differenceInSeconds(new Date(form.dataInicioRet), new Date(form.startDate)) <= 60) {
      errors.push("Fecha Empeza la Retirada");
    }

    if (differenceInSeconds(new Date(form.dataFimRetirar), new Date(form.dataInicioRet)) <= 60) {
      errors.push("Fecha Termina la Retirada");
    }

    if (doc.numerosRes <= 0 || doc.numerosRes > 5000) {
      errors.push("Numero invalido - Es necesario que digas cuantas reservas queres habilitar para este dia");
    }

    ag.resAtiva = form.resAtiva;
    ag.fixed = form.fixed;
    ag.observacao = form.observacao || '';
    // ag.products = doc.products || [];

    const products = doc.products || [];
    const selectProducts = doc?.selectProducts && doc?.selectProducts[0] !== undefined && doc?.selectProducts || [];
    if (selectProducts.length <= 0 && products.length <= 0) {
      // if (!doc.id)
      errors.push("Seleccione una variedad");
    } else {

      const qtdeProducts = products.reduce(
        (total, itz) => total + parseInt(itz.qtde),
        0
      );
      if (qtdeProducts <= 0) {
        errors.push("Ingrese la cantidad da la variedad");
      } else {
        // const newproducts = products.map((item, idx) => {
        //   item.qtde = parseInt(item.qtde);
        //   item.qtde_estoque = item.qtde;
        //   return item;
        // });
        ag.products = products;
      }
    }

    ag.type = doc.type || '';

    if (errors.length) {
      ag.error = true;
      ag.errors = errors;
    } else {
      ag.error = false;
      ag.errors = errors;
      ag.startDate = dtstart;
      ag.dataInicioRet = dtini;
      ag.dataFimRet = dtfim;
      ag.numerosRes = parseInt(doc.numerosRes);
      ag.numerosPacotes = parseInt(doc.numerosPacotes);
      ag.totalPacotes = PACKAGES_QTDE;
      if (!doc.title || doc.title === undefined) {
        ag.title = `${moment(dtstart).locale('es').format("dddd")} - ${moment(dtini).locale('es').format("dddd")}`;
      } else {
        ag.title = doc.title;
      }
    }
    // console.log(ag);
    return ag;
  };

  const updateAgendamento = async (changed) => {
    // console.log('UPDATE AGENDAMENTO => ', changed);
    // let { data } = state;

    let obj = {};
    let agendamento = {};

    const farmaciaID = fbUser;

    const newdata = data.map(appointment => (
      changed[appointment.id] ? { ...appointment, ...changed[appointment.id] } : appointment)
    );
    let id = Object.keys(changed);
    let ag = changed[id];

    let pos = newdata.filter(appointment => parseInt(appointment.id) === parseInt(id[0]));
    // console.log('POS => ', pos);

    const prods = pos[0].products;
    const selecteProds = pos[0].selectProducts;
    // console.log('SELECTEPRODS => ', selecteProds);

    if (prods) {
      agendamento.products = prods;
    }
    let newProdsSelecteds = [];
    if (!!selecteProds) {
      for (let arr in prods) {
        for (let filter in selecteProds) {
          if (prods[arr].id == selecteProds[filter]) {
            newProdsSelecteds.push(prods[arr]);
          }
        }
      }
      // console.log('MYARRAYFILTERED => ', newProdsSelecteds);
      agendamento.products = newProdsSelecteds;
    }


    const result = validateAgendamento(pos[0]);
    const cronID = pos[0].cronID ?? null;
    // console.log('RESULT => ', result);


    let next = true;
    if (result.fixed === true) {
      const check = await checkExistFixed();
      // console.log('CHECK => ', check);
      if (check.length) {
        if (check[0].agendamentoID != result.agendamentoID) {
          next = false;
        }
      }
    }

    if (!next) {
      obj = {
        title: "Error!",
        message: "Por ahora solo es posible dejar una reserva como destacada, si quieres seguir recomendamos sacar la opción de destacado de la reserva que pusiste anteriormente.",
        severity: "error",
        visiblity: true,
      };
    } else if (result.error) {
      obj = {
        title: "La data esta incorreta:",
        message: result.errors.join(', '),
        severity: "error",
        visiblity: true
      };
    } else {
      delete result.error;
      delete result.errors;
      agendamento.agendamentoID = result.agendamentoID;
      agendamento.startDate = result.startDate;
      agendamento.dataInicioRet = result.dataInicioRet;
      agendamento.dataFimRet = result.dataFimRet;
      agendamento.numerosRes = result.numerosRes;
      agendamento.numerosPacotes = result.numerosPacotes;
      agendamento.totalPacotes = PACKAGES_QTDE;
      agendamento.fixed = result.fixed;
      agendamento.resAtiva = result.resAtiva;
      agendamento.observacao = result.observacao ? result.observacao : '';
      agendamento.title = handleTitleEs(result.startDate, result.endDate);
      agendamento.type = result.type;
      agendamento.products = result.products;
      agendamento.versionNow = {
        version: version,
        versionApp: version_app,
      };

      db.collection("usuarios")
        .doc(farmaciaID)
        .collection("agendamentos")
        .doc(agendamento.agendamentoID).set(agendamento, { merge: true });

      if (cronID) {
        console.log("CRON EXISTS");
        db.collection("cron")
          .doc(cronID)
          .set({
            farmaciaID: farmaciaID,
            agendamentoID: result.agendamentoID,
            data: result.startDate
          }, { merge: true });
      } else {
        console.log("CRON NOT EXISTS");
        db.collection("cron")
          .add({
            farmaciaID: farmaciaID,
            agendamentoID: result.agendamentoID,
            data: result.startDate,
            complete: false,
          }).then(refCron => {
            // console.log("CRON ADDED")
            db.collection("usuarios")
              .doc(farmaciaID)
              .collection("agendamentos")
              .doc(result.agendamentoID).set({ agendamentoID: result.agendamentoID, cronID: refCron.id }, { merge: true });
          });
      }

      setData([...data, newdata]);

      if (ag.dataFimRet || ag.dataInicioRet) {
        if (pos[0].numerosRes > 0) {
          listReservas(agendamento);
        }
      }
      obj = {
        title: "Exito!",
        message: "La reserva ha sido editada",
        severity: "success",
        visiblity: true,
      };

    }
    setState(obj);
  };

  const listReservas = (agend) => {
    const { agendamentoID, dataInicioRet, dataFimRet, observacao } = agend;
    db.collection("reservas")
      .where("farmaciaID", "==", fbUser)
      .where("reservaID", "==", agendamentoID)
      .get()
      .then((doc) => {
        let dados = [];
        let docs = doc.docs;
        for (let ag of docs) {
          // console.log(ag.data());
          dados.push({
            ID: ag.id,
            reservaID: agendamentoID,
            dataRetirar: dataInicioRet,
            dataFimRetirar: dataFimRet,
            email: ag.data().email,
          });
        }
        setReservas(dados, dataInicioRet, dataFimRet, observacao);
      });
  };

  const setReservas = (data, dataInicioRet, dataFimRet, observacao) => {
    console.log('SET RESERVAS => ', data, dataInicioRet, dataFimRet, observacao);
    data.forEach(function (doc, index) {
      let obj = {};
      if (doc.dataRetirar) {
        obj.dataRetirar = doc.dataRetirar;
      }

      if (doc.dataFimRetirar) {
        obj.dataFimRetirar = doc.dataFimRetirar;
      }

      db.collection("reservas").doc(doc.ID)
        .update(obj).then(() => {
          // console.log("SUCESSO");
          // obj.email = doc.email;

        });
    });
    // setState({ ...state, showDialog: true });
    senderEmail(data, dataInicioRet, dataFimRet, observacao);
    // handleDialog();
  };


  const senderEmail = async (data, dataInicioRet, dataFimRet, observacao) => {
    console.log('SENDEREMAIL => ', data, dataInicioRet, dataFimRet, observacao);
    let { farmaciaData, farmaciaID } = state;
    // console.log(farmaciaData)
    const lista = [];
    data.forEach(function (doc, index) {
      lista.push(doc.email);
    });

    let chunked = chunk(lista, 90);

    // console.log(chunked);
    if (lista.length > 0) {
      const emails = chunked.map(e => {
        return e.join(', ');
      });
      // console.log(farmaciaData);
      // console.log(format(dataInicioRet, "HH:mm"));
      const objEmail = {
        emails,
        title: 'Reservar Cannabis: El dia de retiro ha sido cambiado!',
        farmaciaData,
        dataInicioRet,
        dataFimRet,
        observacao
      };


      if (sendEmailPermission) {
        axios({
          method: "POST",
          url: "https://us-central1-reservar-cannabis-uru.cloudfunctions.net/widgets/avisoDataReservaEmail",
          data: objEmail,
        }).then((result) => {
          console.log(result);
        }).catch((err) => {
          console.log(err);
          // console.log(err.response.data.message);
        });
      }
      // if (sendEmailPermission) {
      //   try {
      //     const response = await api.post('avisoDataReservaEmail', objEmail);
      //     if (response && response.success) {
      //       console.log('SUCCESS AVISO DATA RESERVA EMAIL => ', response.message);
      //     }
      //   } catch (error) {
      //     console.log('ERROR AVISO DATA RESERVA EMAIL => ', error);
      //   }
      // }
    }
  };


  const handleClick = () => {
    // console.log("HANDLE CLICK");
  };

  const deleteAgendamento = async (deleted) => {
    // let { data } = state;
    let obj = {};
    let ag = data.filter(appointment => appointment.id === deleted);

    const reservas = await getReservasID(ag[0].agendamentoID);
    // console.log(reservas);

    const cronID = ag[0].cronID ?? null;

    if (reservas > 0) {
      obj = {
        title: "Erro",
        message: "Esta reserva ya tiene reservas agregadas y no se puede borrar. Probá desactivar la reserva adentro de la fecha!",
        severity: "error",
        visiblity: true,
      };
    } else {
      db.collection("usuarios")
        .doc(fbUser)
        .collection("agendamentos")
        .doc(ag[0].agendamentoID).delete();

      if (cronID) {
        db.collection("cron")
          .doc(cronID)
          .delete();
      }

      obj = {
        title: "Exito",
        message: "Su fecha de reserva ha sido borrada!",
        severity: "success",
        visiblity: true
      };

      const newdata = data.filter(appointment => appointment.id !== deleted);
      setState({ data: newdata });
    }

    setState(obj);
    handleMessage();
  };

  const getReservasID = async (id) => {
    let reservas = new Promise((resolve) => {
      db.collection('reservas')
        .where("reservaID", "==", id)
        .onSnapshot(async (querySnapshot) => {
          let docs = querySnapshot.docs;
          resolve(docs.length);
        });
    });
    reservas.then(function (result) {
      return result;
    });
    return reservas;
  };

  return (
    <div className={styles.root} >
      <CssBaseline />
      <MenuAppBar />
      <main className={styles.content}>

        <div className={styles.appBarSpacer} />
        <div className='alertCalendar'>
          {state.visiblity &&
            <Alert
              severity={state.severity}
              onClose={() => setState({ ...state, visiblity: false })}
            >
              <AlertTitle>{state.title}</AlertTitle>
              {state.message}
            </Alert>
          }
        </div>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Paper>
              <Scheduler
                data={data}
                locale={state.locale}
              >
                <EditingState
                  onCommitChanges={commitChanges}
                />
                <ViewState
                  defaultCurrentDate={new Date()}
                />

                <MonthView
                  timeTableCellComponent={TimeTableCell}
                // dayScaleRowComponent={() => { return (<h1>OK</h1>)}}
                // dayScaleEmptyCellComponent={() => { return (<h1>OK</h1>)}}
                />
                <WeekView
                  startDayHour={9}
                  endDayHour={15}
                />


                <Appointments
                  appointmentComponent={Appointment}
                  appointmentContentComponent={AppointmentContent}
                  onClick={handleClick}
                />

                <Toolbar
                  flexibleSpaceComponent={FlexibleSpace}
                />
                <DateNavigator />

                <EditRecurrenceMenu
                  messages={getAllDayMessages(state.locale)}
                />

                <AppointmentTooltip
                  showCloseButton
                  showDeleteButton
                  showOpenButton
                  contentComponent={Content}
                // onOpenButtonClick={() => handleClick()}
                />
                <AppointmentForm
                  booleanEditorComponent={BooleanEditor}

                  basicLayoutComponent={BasicLayout}
                  textEditorComponent={TextEditor}
                  dateEditorComponent={DateEditor}
                  messages={getAllDayMessages(state.locale)}
                  labelComponent={LabelComponent}
                />
                <Resources data={[
                  {
                    id: 'id',
                    fieldName: 'selectProducts',
                    bosta: 'merda',
                    // title: 'Selecione a variedade',
                    // text: 'List Products',
                    instances: listProducts,
                    allowMultiple: true,
                    // isMain: true
                  }
                ]}
                />

              </Scheduler>
              <Dialog
                open={state.showDialog}
                onClose={() => { setState({ showDialog: false }); }}
              >
                <DialogTitle>
                  Información!
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Las fechas de retirada de las reservas ya agregada ha sido actualizadas.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => { setState({ showDialog: false }); }} color="primary" variant="contained">
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
            </Paper>
          </Grid>
        </Grid>
        <Box pt={4}>
          <Copyright />
        </Box>
      </main>
    </div>
  );

};
function mapStateToProps(state) {
  return {
    isLoggingOut: state.auth.isLoggingOut,
    logoutError: state.auth.logoutError
  };
}

const mapDispatchToProps = dispatch => ({
  Deslogar() {
    dispatch(logoutUser());
  },
});
const style = (theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
  });
export default compose(withStyles(style), connect(mapStateToProps, mapDispatchToProps))(Calendario);