import React from 'react';
import { usePagination, DOTS } from './usePagination';
import styles from "./styles.module.css";

const Pagination = (props) => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    return (
        <ul
            className={`${styles.pagination_bar} ${styles.pagination_container}`}
        >
            <li

                className={`${styles.pagination_item} ${currentPage === 1 ? 'disabled' : ''}`}
                onClick={onPrevious}
            >
                <div className={`${styles.arrow} ${styles.left}`} />
            </li>
            {paginationRange.map((pageNumber, idx) => {
                if (pageNumber === DOTS) {
                    return <li key={idx} className={`${styles.pagination_item} ${styles.dots}`}>&#8230;</li>;
                }

                return (
                    <li
                        key={idx}
                        className={`${styles.pagination_item} ${pageNumber === currentPage ? styles.activeButton : styles.inactiveButton}`}
                        onClick={() => onPageChange(pageNumber)}
                    >
                        {pageNumber}
                    </li>
                );
            })}
            <li
                className={`${styles.pagination_item} ${currentPage === lastPage ? 'disabled' : ''}`}
                onClick={onNext}
            >
                <div className={`${styles.arrow} ${styles.right}`} />
            </li>
        </ul>
    );
};

export default Pagination;
