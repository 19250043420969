import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'clsx';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import '../App.css';

const useStyles = makeStyles((theme) => ({
    cardroot: {
        minWidth: 260,
        maxWidth: 300,
        boxShadow: '0 4px 6px rgba(0,0,0,0.14)',
        borderRadius: '10px',
        display: 'block',
        textAlign: 'center',
        background: '#fff',
        // borderLeftStyle: 'solid',
        // borderLeftWidth: '5px',
        // borderLeftColor: '#2ecc71',
        // '-webkit-transition': 'all .4s ease-out',
        // transition: ' all .4s ease-out',
        // '&:hover': {
        //     boxShadow: '0 6px 10px rgba(0,0,0,0.14)',
        // }
    },
    aindaTem: {
        // borderLeftColor: '#2ecc71',
    },
    avatar: {
        margin: 'auto',
    },
    heading: {
        fontSize: 18,
        fontWeight: 'bold',
        letterSpacing: '0.5px',
        marginTop: 8,
        marginBottom: 0,
    },
    subheader: {
        fontSize: 14,
        color: '#808080',
        // marginBottom: '0.875em',
    },
    bairro: {
        fontSize: 12,
        color: '#808080',
        marginBottom: '0.875em',
    },
    statLabel: {
        fontSize: 12,
        color: '#808080',
        fontWeight: 500,
        fontFamily:
            '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        margin: 0,
    },
    statValue1: {
        fontSize: 13,
        fontWeight: 'bold',
        marginBottom: 0,
        lineHeight: 1,
        letterSpacing: '1px',
    },
    cardBoxFixed: {
        borderRadius: 6,
        width: '60vmin',
        height: '50vmin',
        backgroundImage: 'conic-gradient(from 0turn, #fff, #fff 50%, #fff), conic-gradient(from 0turn, transparent 20%, #08f, #f03)',
        backgroundSize: 'calc(100% - (var(--border-size) * 2)) calc(100% - (3 * 2))',
        backgroundPosition: 'center center',
        backgroundRepeat: ' no-repeat',
        animation: 'bg-spin 3s linear infinite',
        '&:before, &:after': {
            borderColor: '#f03',
            width: 0,
        },
        '&:before': {
            transform: 'translate3d(0, 0, 0)',
            animation: `'50%': { width: 0, height: '100%' },
            '100%': { width: '100%', height: '100%' } 2ms`,
            animationFillMode: 'both',
        },
        '&:after': {
            top: 0,
            transform: 'translate3d(0, 0, 0)',
            animation: `'50%': { width: 0, height: '100%' },
            '100%': { width: '100%', height: '100%' } 2ms`,
            // animation: `${errorBottomAnim} ${duration / 2}ms`,
            animationFillMode: 'both',
        }
    }


}));

const CardScheduled = ({ scheduledFixed, dataPharm }) => {
    const classes = useStyles();
    const history = useHistory();

    const navigateReservar = (params) => {
        history.replace(params);
    };
    if (scheduledFixed) {
        return (

            <Card key={scheduledFixed.reservaID} className={`cardBoxFixed`}>
                <CardContent>
                    <Button
                        className={classes.avatar}
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => navigateReservar({
                            pathname: '/reserva',
                            state: {
                                agendamentoID: scheduledFixed.reservaID,
                                idfarmacia: scheduledFixed._id,
                                farmaciaNome: dataPharm.farmaciaNome,
                                date: new Date(),
                                iframe: false
                            }
                        })}
                    >
                        RESERVAR
                    </Button>
                    <h3 className={classes.heading}>{dataPharm.farmaciaNome}</h3>
                    <div>
                        <span className={classes.subheader}>{dataPharm.farmaciaCidade}</span>
                        <span className={classes.bairro}>{dataPharm.farmaciaBairro}</span>
                    </div>
                    <Grid container flexDirection={'column'}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {scheduledFixed.products && scheduledFixed.products.map((item, idx) => (
                                <div key={idx} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flexWrap: 'wrap', alignItems: 'center', marginLeft: 5, marginRight: 5 }}>
                                    <span style={{ background: item.color, width: 15, height: 15, borderRadius: 50 }}></span>
                                    {/* <span style={{ fontSize: 11, fontWeight: '400', marginLeft: 3, textAlign: 'center', }}>{item.qtde}</span> */}
                                    <span style={{ fontSize: 10, fontWeight: '400', marginLeft: 5, lineHeight: '13px', textAlign: 'center', marginTop: 5 }}>{item.text}</span>
                                </div>
                            ))}
                        </div>
                    </Grid>
                </CardContent>
                <Divider light />
                <Box display={'flex'}>
                    <Box p={2} flex={'auto'}>
                        <p className={classes.statLabel}>Disponible</p>
                        <p className={classes.statValue1}>
                            {("0" + scheduledFixed.data.getDate()).slice(-2) + "/" + ("0" + (scheduledFixed.data.getMonth() + 1)).slice(-2)
                                + "\n" + scheduledFixed.data.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' })}
                        </p>
                    </Box>
                    <Box p={2} flex={'auto'}>
                        <p className={classes.statLabel}>Retirada</p>
                        <p className={classes.statValue1}>{scheduledFixed.horarioFim}</p>
                    </Box>
                </Box>
            </Card>
        );
    } else {
        return ('');
    }
};

export default CardScheduled;