import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 250;
const useStyles = makeStyles(theme => ({
  root: {
    // height: '100vh',
    // background: 'greenyellow',
    // display: 'flex',
    // flex:1,
    // flexDirection: 'column',
    // justifyContent: 'space-between',
    // alignItems:'center'
  },
  rootz: {
    flexGrow: 1,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: '#171717',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  // content: {
  //   flexGrow: 1,
  //   height: '100vh',
  //   overflow: 'auto',
  // },
  // container: {
  //   paddingTop: theme.spacing(4),
  //   paddingBottom: theme.spacing(4),
  // },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  // paper: {
  //   padding: theme.spacing(2),
  //   display: 'flex',
  //   overflow: 'auto',
  //   flexDirection: 'column',
  // },
  // fixedHeight: {
  //   height: 240,
  // },
  // alert: {
  //   paddingBottom: 20
  // },
  barcodeForm: {
    // background: "blue",
    width: '100%',
    // paddingLeft:10,
    // paddingRight:10,
  },
  barcodeInput: {
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
    marginBottom:20
  },
  formScanner: {

    // background: 'brown',
    // display: "flex",
    // justifyContent: "center",
    // alignItems: 'center',
    // width: '50%',
  },
  headerTitle: {
    color:'#020202',
    // textAlign: 'center',
    fontSize: 42,
    // paddingTop: 80,
    // paddingBottom: 50,
    fontWeight: 'bolder',
  
    // background: 'darkgreen'
  },
  scannerBox: {
    // height:100,
    display: "flex",
    // flexDirection:'row',
    justifyContent: 'center',
    alignItems: 'center',
    // background: 'aquamarine'
  },
  gridCustom: {
    // marginTop: 'calc(26vh + 26px)'
  },
}));

export default useStyles;