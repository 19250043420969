import React, { useState, useEffect, useRef } from 'react';
import Chip from '@material-ui/core/Chip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { green, grey, red, orange } from '@material-ui/core/colors';

const Table = ({ rows, onCancelReserve, onCompleteReserve, onLoading, farmacyName }) => {
    const [error, setError] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [query, setQuery] = useState("");
    const [filter, setFilter] = useState("");
    const [paginate, setpaginate] = useState(8);
    // console.log('ROWS => ', rows);
    useEffect(() => {
        setItems(rows);
        setLoaded(true);
    }, []);

    const data = Object.values(items);

    const search_parameters = [...data];
    const filter_items = [...new Set(data.map((item) => item.nome))];

    function search(items) {
        console.log('SEARCH => ', items);
        console.log('QUERY => ', query);
        console.log('SEARCH_PARAMETERS => ', search_parameters);
        console.log('FILTER_ITEMS => ', filter_items);
        return items
        // .filter((item) =>
        //         item.nome.includes(filter) &&
        //         search_parameters.some((parameter) =>
        //             item[parameter].toString().toLowerCase().includes(query)
        //         )
        // );
    }

    const load_more = (event) => {
        setpaginate((prevValue) => prevValue + 8);
    };

    if (error) {
        return <>{error.message}</>;
    } else if (!loaded) {
        return <>loading...</>;
    } else {
        return (
            <div className="wrapper">
                <div className="search-wrapper">
                    <label htmlFor="search-form">
                        <input
                            type="search"
                            name="search-form"
                            id="search-form"
                            className="search-input"
                            placeholder="Search for..."
                            onChange={(e) => setQuery(e.target.value)}
                        />
                        <span className="sr-only">Search countries here</span>
                    </label>

                    <div className="select">
                        <select
                            onChange={(e) => setFilter(e.target.value)}
                            className="custom-select"
                            aria-label="Filter Countries By Region"
                        >
                            <option value="">Filter By Region</option>
                            {filter_items.map((item) => (
                                <option value={item}>Filter By {item}</option>
                            ))}
                        </select>
                        <span className="focus"></span>
                    </div>
                </div>
                <div>
                    <table id="table_reserve" width="100%" className="table responsive">
                        <thead>
                            <tr>
                                <th></th>
                                <th className="order">Nome</th>
                                <th className="order">Status</th>
                                <th>Hora cadastro</th>
                                <th>Data retirar</th>
                                <th className="order">Email</th>
                            </tr>
                        </thead>

                        <tbody>

                            {/* {console.log('DATA => ', rows)} */}
                            {search(rows)
                                .slice(0, paginate)
                                .map((item, idx) => (
                                    <tr key={idx}>
                                        <td >
                                            <div className="td_actions">
                                                <div className="btn_action" onClick={() => onCompleteReserve(item)}>
                                                    <CheckCircleIcon style={{ color: item.status == 'reservado' ? green[500] : grey[500] }} />
                                                </div>
                                                <div className="btn_action" onClick={() => onCancelReserve(item)}>
                                                    <CancelIcon style={{ color: item.status == 'reservado' ? red[500] : grey[500] }} />
                                                </div>
                                            </div>
                                        </td>
                                        <td>{item.nome}</td>
                                        <td>{
                                            item.status === 'reservado' ? (
                                                <Chip
                                                    label="Aguardando"
                                                    color="default" />
                                            ) : item.status === 'retirado' ? (
                                                <Chip
                                                    label="Retirado"
                                                    color="primary" />
                                            ) : (
                                                <Chip
                                                    label="Cancelado"
                                                    color="secondary" />
                                            )}</td>
                                        <td>{item.horacadastro.toDate().toLocaleString()}</td>
                                        <td>{item.dataRetirar.toDate().toLocaleString()}</td>
                                        <td>{item.email}</td>
                                    </tr>
                                ))}
                        </tbody>

                    </table>

                    <button onClick={load_more}>Load More</button>
                </div>
            </div>
        );
    }
};
export default Table;
