import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import Footer from './parts/footer';
import Header from './parts/header';
import { db, analytics, fbURL } from "../firebase/firebase";
import firebase from 'firebase';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import Sound from 'react-sound';
import sound from '../sounds/sound_in.mp3';
import '../css/styles.css';
import Reglas from './Reglas';
import Direccion from './Direccion';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import { getBrowserOS } from './dashboard/components/Helpers';
import { initPresence } from './Presence';

function makeid(length) {
	var result = '';
	var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

function Reserva({ location }) {
	window.scrollTo(0, 0);
	const { state } = location;
	const history = useHistory();
	const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);

	const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
	useEffect(() => {
		if ((isSafari && iOS) || (isSafari)) {
			setSafari(true);
		}
	}, [iOS, isSafari]);

	const [farmaciaNome, setFarmaciaNome] = useState(state.farmaciaNome);
	const [idfarmacia, setIdFarmacia] = useState(state.idfarmacia);
	const [agendamentoID, setAgendamentoID] = useState(state.agendamentoID);

	const [iframe, setIframe] = useState(state.iframe);

	const [nroReservas, setNroReservas] = useState(0);
	const [reservasLivre, setReservasLivre] = useState(0);
	const [pessoasNaFila, setPessoasNaFila] = useState(0);

	const [seuLugar, setSeuLugar] = useState(0);
	const [agendamento, setAgendamento] = useState([]);
	const [farmacia, setFarmacia] = useState([]);
	const [pessoasReservando, setPessoasReservando] = useState(0);

	const [refreshPage, setRefreshPage] = useState(false);

	const [lotado, setLotado] = useState(false);
	const [esgotado, setEsgotado] = useState(false);
	const [suavez, setSuavez] = useState(false);
	const [espera, setEspera] = useState(false);
	const [desativado, setDesativado] = useState(false);
	const [playSound, setPlaySound] = useState(false);
	const [soundOnOff, setSoundOnOff] = useState(true);
	const [safari, setSafari] = useState(false);
	const [startFB, setStartFB] = useState(false);
	const [totalProdutosAgendamento, setTotalProdutosAgendamento] = useState(0);
	const [totalProdutosReserva, setTotalProdutosReserva] = useState(0);

	function handleIconSound(option) {
		if (option) {
			setSoundOnOff(false);
			muteAllSounds();
		} else {
			setSoundOnOff(true);
			unmuteAllSounds();
		}
	}

	function muteAllSounds() {
		window.soundManager.muteAll();
	}

	function unmuteAllSounds() {
		window.soundManager.unmuteAll();
		setPlaySound(true);
	}

	const redirectBlockedUser = () => {
		setTimeout(() => {
			history.replace({
				pathname: '/',
				state: { message: "ERROR!" }
			});
		}, 3000);

	};

	useEffect(() => {
		// console.log("---------INITPRESENCE---------");
		initPresence({ farmaciaNome, agendamentoID, preenchendo: false });
	}, [agendamentoID, farmaciaNome]);


	useEffect(() => {

		if (!refreshPage)
			return;

		setPlaySound(true);
		const timer = setTimeout(function () {
			console.log('--------------------SETTIMEOUT RESERVA------------------ => ');
			setPreenchendoFirestore();
			analytics.logEvent('queue_complete_' + farmaciaNome);
			history.replace({
				pathname: '/preencherReserva',
				state: { agendamentoID: agendamentoID, idfarmacia: idfarmacia, farmaciaNome: farmaciaNome, date: new Date(), iframe: iframe }
			});
		}, 5000);
		return () => clearTimeout(timer);

	}, [refreshPage]);


	function setPreenchendoFirestore() {
		// console.log("---------PREENCHENDO FIRESTORE---------");
		if (localStorage.getItem('key' + farmaciaNome) !== null) {
			let userStatusFirestoreRef = db.collection('status').doc(localStorage.getItem('key' + farmaciaNome));
			let userStatusDatabaseRef = firebase.database().ref('/status/' + localStorage.getItem('key' + farmaciaNome));

			let isOnlineForFirestore = {
				data_go_fill: new Date().toLocaleString('es'),
				preenchendo: true,
			};

			let isOnlineForDatabase = {
				data_go_fill: new Date().toLocaleString('es'),
				preenchendo: true,
			};

			firebase.database().ref('.info/connected').on('value', function (snapshot) {
				userStatusDatabaseRef.set(isOnlineForDatabase);
				userStatusFirestoreRef.set(isOnlineForFirestore, { merge: true });
			});
		}
	}

	useEffect(() => {
		db.collection("usuarios")
			.doc(idfarmacia)
			.get().then(function (doc) {
				if (!doc.empty) {
					let obj = {
						farmaciaID: doc.id,
						farmaciaNome: doc.data().nome,
						farmaciaLogo: doc.data().logo,
						farmaciaRua: doc.data().endereco.rua,
						farmaciaCidade: doc.data().endereco.cidade,
						farm: doc.data(),
						farmaciaValida: true
					};
					setFarmacia(obj);
				} else {
					setFarmacia({ farmacia: [], farmaciaValida: false });
				}

			});
	}, [idfarmacia]);

	useEffect(() => {
		db.collection("usuarios")
			.doc(idfarmacia)
			.collection("agendamentos")
			.where("agendamentoID", "==", agendamentoID)
			.onSnapshot((querySnapshot) => {
				let docs = querySnapshot.docs;
				for (let ag of docs) {
					console.log('DOCS => ', ag.data());

					let obj = {
						farmaciaID: idfarmacia,
						reservaID: ag.id,
						agendamentoID: ag.id,
						horaFim: new Date(ag.data().dataInicioRet.seconds * 1000),
						numeros: ag.data().numerosRes,
						horaFimRet: ("0" + (new Date(ag.data().dataInicioRet.seconds * 1000).getDate())).slice(-2) + "/" + ("0" + (new Date(ag.data().dataInicioRet.seconds * 1000).getMonth() + 1)).slice(-2)
							+ " y " + (new Date(ag.data().dataInicioRet.seconds * 1000).toLocaleTimeString("es-ES", { hour: '2-digit', minute: '2-digit' })) + "hrs"
							+ " de las " + ("0" + (new Date(ag.data().dataFimRet.seconds * 1000).getDate())).slice(-2) + "/" + ("0" + (new Date(ag.data().dataFimRet.seconds * 1000).getMonth() + 1)).slice(-2)
							+ " hasta " + new Date(ag.data().dataFimRet.seconds * 1000).toLocaleTimeString("es-ES", { hour: '2-digit', minute: '2-digit' }) + "hrs",
						observacao: ag.data().observacao,
						resAtiva: ag.data().resAtiva,
						horaInicioReserva: ag.data().startDate.toDate(),
						products: ag.data().products
					};
					const products = ag.data().products;
					const total = products.reduce((acumulador, produto) => {
						return acumulador + parseInt(produto.qtde);
					}, 0);
					const totalEstoque = products.reduce((acumulador, produto) => {
						return acumulador + parseInt(produto.estoque);
					}, 0);
					console.log('QTDE => ', total);
					console.log('QTDE ESTOQUE => ', totalEstoque);
					setTotalProdutosAgendamento(total);
					setNroReservas(ag.data().numerosRes);
					setDesativado(obj.resAtiva);
					setAgendamento(obj);
				}
			});

	}, [idfarmacia]);

	useEffect(() => {
		let isCancelled = false;
		if (totalProdutosAgendamento > 0) {
			db.collection('reservas')
				.where("reservaID", "==", agendamentoID)
				.onSnapshot(async (querySnapshot) => {
					console.log('ONSNAPSHOT  RESERVAS=> ', querySnapshot.docs);
					let docs = querySnapshot.docs;
					let can = 0;
					let res = 0;
					let ret = 0;
					let livres = 0;
					let counter = 0;
					let total = 0;
					docs.forEach(function (d) {

						if (d.data().status === 'reservado')
							res++;
						if (d.data().status === 'cancelado') {
							let cancelados = d.data().products.reduce((acumulador, produto) => {
								return acumulador + produto.qtde;
							}, 0);
							console.log('CANCELADOS => ', cancelados);
							can += cancelados;
						}
						if (d.data().status === 'retirado')
							ret++;

						total = total + d.data().products.reduce((acumulador, produto) => {
							return acumulador + produto.qtde;
						}, 0);
					});
					console.log('TOTAL DE PRODUTOS DO AGENDAMENTO => ', totalProdutosAgendamento);
					console.log('TOTAL DE PRODUTOS DAS RESERVAS => ', total);
					setTotalProdutosReserva(total);
					livres = totalProdutosAgendamento + can - total;
					console.log('COUNTER LIVRES => ', livres + can);
					if (!isCancelled)
						setReservasLivre(livres);

				});

			return () => isCancelled = true;
		}

	}, [totalProdutosAgendamento]);


	useEffect(() => {
		let isCancelled = false;
		db.collection("status")
			// .where("farmacia", "==", farmaciaNome)
			.where("state", "==", "online")
			.where("agendamentoID", "==", agendamentoID)
			// .orderBy("last_changed")
			.onSnapshot(function (doc) {
				let pessoasFila = 0;
				let lugar = 0;
				doc.docs.forEach(function (documents, index) {
					if (documents.id === localStorage.getItem('key' + farmaciaNome)) {
						lugar = index + 1;
					}
					pessoasFila++;
				});
				if (!isCancelled) {
					setPessoasNaFila(pessoasFila);
					setSeuLugar(lugar);
				}
			});
		return () => {
			isCancelled = true;
		};
	}, []);

	useEffect(() => {
		let isCancelled = false;
		db.collection("status")
			// .where("farmacia", "==", farmaciaNome)
			.where("state", "==", "online")
			.where("agendamentoID", "==", agendamentoID)
			.where("preenchendo", "==", true)
			// .orderBy("last_changed")
			.onSnapshot(function (doc) {
				let pessoas = 0;
				doc.docs.forEach(function (documents, index) {
					pessoas++;
				});
				if (!isCancelled)
					setPessoasReservando(pessoas);
			});
		return () => isCancelled = true;
	}, []);


	useEffect(() => {
		setRefreshPage(false);
		setSuavez(false);
		setEspera(false);
		setEsgotado(false);
		setLotado(false);
		setDesativado(false);
		setPlaySound(false);
		// if (agendamento.resAtiva) {
		// setDesativado(false);
		if (reservasLivre > 0) {
			if (pessoasReservando > 0 && pessoasReservando >= reservasLivre) {
				setLotado(true);

			} else {
				if (seuLugar <= reservasLivre) {
					if (pessoasReservando < reservasLivre) {
						setSuavez(true);
						setRefreshPage(true);
					} else {
						setEspera(true);
						setLotado(true);
					}
				} else {
					setEspera(true);
				}
				setLotado(false);
			}
		} else {
			setEsgotado(true);
		}
	}, [agendamento, pessoasReservando, pessoasNaFila, reservasLivre, seuLugar]);


	const normalise = value => (value) * 100 / (nroReservas);

	return (
		<div>
			{!iframe && <Header />}

			<div className="page- header-filter" style={{ backgroundImage: `url('/img/chamada-teste-1.jpg'` }}>
				<div className="container">
					<div className="row">
						{!safari && playSound && soundOnOff &&
							<Sound
								url={sound}
								playStatus={Sound.status.PLAYING}
								onFinishedPlaying={() => { setPlaySound(false); }}
								volume={50}
							/>
						}

						<div className="col-sm-12 col-md-8 col-lg-7 col-xl-6 ml-auto mr-auto farmacia">
							{farmacia.farmaciaValida && (
								<div className="card">

									<div className="card-header-3 text-center">
										<div className="title_farmacia">
											<p className="dias">{farmacia.farm.nome}</p>
											{soundOnOff &&
												<Fab size="small" color="default" aria-label="add" className={'sound_on_off'} onClick={() => handleIconSound(true)}>
													<VolumeUpIcon className={'sound_icon'} />
												</Fab>
											}
											{!soundOnOff &&
												<Fab size="small" color="default" aria-label="add" className={'sound_on_off'} onClick={() => handleIconSound(false)}>
													<VolumeOffIcon className={'sound_icon'} />
												</Fab>
											}
										</div>
										<div>
											<img width="200px" alt="Logo Farmácia" src={farmacia.farm.logo} />
											<br></br><br></br>
										</div>
									</div>
									{/* {console.log(farmacia)} */}
									{/* {console.log(agendamento)} */}
									<div className="card-header-description text-center">
										<div className="card-container-queue">
											<h4 className="card-title">{farmacia.farm.endereco.cidade}</h4>

											<h5 className="text-center">
												<strong>Cuando Retirar</strong>
												<br></br>
												{agendamento.horaFimRet}
												<br />
												{agendamento.observacao}
											</h5>
											{reservasLivre <= 20 && (
												<div>
													{/* <h5>Total de números: {totalProdutosAgendamento} números</h5> */}
													<h5>Hay disponible: {reservasLivre} números</h5>
												</div>
											)}
											<h5>Ahora tenemos {pessoasNaFila} persona(s) em la fila</h5>
											<h5>Su vez es la {seuLugar}</h5>

											<p className="description text-center">
												No actualize y ni cierre la pagina mientras haces la reserva.
											</p>

											<Tooltip title="Reservado y Reservando">
												<LinearProgress style={{ width: '70%', margin: 'auto' }} variant="buffer" value={normalise(2)} valueBuffer={normalise(0)} />
											</Tooltip>
											<br />


											{esgotado &&
												<div className="card-header-secundary text-center nohay">
													<h3><i className="material-icons">warning</i> No hay más números!</h3>
													Lamentablemente no tenemos más números, disculpe las molestias ocasionadas. Estamos en proceso de continua mejora para brindar a los usuarios de cannabis un acceso rápido y democrático.
												</div>

											}

											{lotado &&
												<div className="card-header-secundary text-center nohay">
													<h3><i className="material-icons">warning</i> Hay muchas personas reservando!</h3>
													Puede esperar, si alguien deja de reservar, su vez se activará automáticamente.
												</div>
											}

											{desativado &&
												<div className="card-header-secundary text-center nohay">
													<h3><i className="material-icons">warning</i> Desactivado!</h3>
													La reserva fue desactivada por la farmacia.
												</div>
											}
											<div>
												{suavez &&
													<div>
														<p className='suavez'>Es tu vez de hacer la reserva!</p>
														<p className='suavez'>Estamos direcionando al formulário</p>
														<br />
													</div>
												}
												{espera &&
													<div>
														<p className='esperando'>Esperando tu vez!</p>
														<br />
													</div>
												}
											</div>

											<br /><br />
											<small style={{ marginLeft: 20, marginRight: 20 }}>
												Cuando accedes a el formulário tienes 2 minutos para completar la reserva.
												<br />
												Si no pasamos para el próximo de la fila.
											</small>
											<br></br><br></br>
										</div>
										<button className="btn btn-block btn" data-toggle="modal" data-target="#direccion">
											<i className="material-icons">directions</i> Donde Queda?
											<div className="ripple-container"></div>
										</button>
										<button className="btn btn-block btn-info aviso" data-toggle="modal" data-target="#reglas">
											<i className="material-icons">library_books</i> Reglas
											<div className="ripple-container"></div>
										</button>
										<div className="card-header-primary text-center hogar">
											<strong>Aviso: Verifique la ubicación ya que lLa reserva es únicamente para esta farmacia!</strong>
										</div>
									</div>
								</div>
							)
							}
						</div>


					</div>
				</div>
				<Direccion farmacia={farmacia} />
				<Reglas />
			</div>
			{!iframe && <Footer />}

		</div>
	);
}


export default Reserva;