import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({

  resetContainer: {
    padding: theme.spacing(3),
  },
  gridroot: {
    flexGrow: 1,
  },
  gridList: {
    height: "350px",
    margin: '10px!important',
    // overflowX: 'auto',
    // display: 'flex',
    // flex: 1,
    // flexDirection: 'column',
    // background: 'cadetblue' 
  },
  cardroot: {
    minWidth: 220,
    maxWidth: 260,
    boxShadow: '0 6px 30px rgba(0,0,0,0.14)',
    border: '3px solid #fff',
    borderRadius: '10px',
    display: 'block',
    textAlign: 'center',
    background: '#fff',
    borderLeftStyle: 'solid',
    borderLeftWidth: '5px',
    borderLeftColor: '#2ecc71',
    '-webkit-transition': 'all .4s ease-out',
    transition: ' all .4s ease-out',
    '&:hover': {
      boxShadow: '0 6px 10px rgba(0,0,0,0.14)',
    }
  },
  proximosDias: {
    borderLeftColor: '#d0dfe7',
  },
  acabouNumeros: {
    borderLeftColor: '#ff0000',
  },
  aindaTem: {
    borderLeftColor: '#2ecc71',
  },
  parahoje: {
    borderLeftColor: '#f29b72',
  },
  avatar: {
    margin: 'auto',
  },
  heading: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    marginTop: 8,
    marginBottom: 0,
  },
  subheader: {
    fontSize: 14,
    color: '#808080',
    // marginBottom: '0.875em',
  },
  statLabel: {
    fontSize: 12,
    color: '#808080',
    fontWeight: 500,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    margin: 0,
  },
  statValue1: {
    fontSize: 13,
    fontWeight: 'bold',
    marginBottom: 0,
    lineHeight: 1,
    letterSpacing: '1px',
  },
  pos: {
    marginBottom: 12,
    textAlign: "center"
  },
  bairro: {
    fontSize: 12,
    color: '#808080',
    marginBottom: '0.875em',
  },
}));

export default useStyles;