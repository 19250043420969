import React, { useState, useEffect, useMemo } from 'react';
import Chip from '@material-ui/core/Chip';
import { green, grey, red, orange } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Search from '@material-ui/icons/Search';
import { LinearProgress } from '@material-ui/core';

import styles from "./styles.module.css";
import Pagination from './Pagination';
import moment from 'moment';
import { Typography } from '@mui/material';
import { DeleteForever } from '@material-ui/icons';

const TableScreen = ({ rows, onCancelReserve, onDownReserve, onDeleteReserve, onLoading, farmacyName }) => {
    const [todos, setTodos] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [filterCompleted, setFilterCompleted] = useState("");

    const [currentPage, setCurrentPage] = useState(1);
    const [totalTodos, setTotalTodos] = useState(0);
    const todosPerPage = 30;

    useEffect(() => {
        setTodos(rows);
        tableSort();
    }, [rows]);

    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalTodos / todosPerPage); i++) {
        pageNumbers.push(i);
    }

    const todosData = useMemo(() => {
        // console.log('FILTERCOMPLETED => ', filterCompleted);
        // console.log('SEARCHTERM', searchTerm);
        let computedTodos = todos;

        if (searchTerm) {
            computedTodos = computedTodos.filter((todo) =>
                todo.nome.toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .includes(
                        searchTerm.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                    )
            );
        };

        if (filterCompleted !== '') {
            computedTodos = computedTodos.filter(
                (todo) => filterCompleted === todo.status
            );
        }

        setTotalTodos(computedTodos.length);

        const slices = computedTodos.slice(
            (currentPage - 1) * todosPerPage,
            (currentPage - 1) * todosPerPage + todosPerPage
        );
        // console.log('SLICES => ', slices);
        return slices;
    }, [todos, currentPage, searchTerm, filterCompleted]);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const resetFilter = () => {
        setSearchTerm("");
        setFilterCompleted("");
        setCurrentPage(1);
    };

    const tableSort = () => {
        document.querySelectorAll('th.order').forEach(th_elem => {
            let asc = true;
            const span_elem = document.createElement('span');
            span_elem.style = "font-size:0.8rem; margin-left:0.5rem";
            span_elem.innerHTML = "";
            th_elem.appendChild(span_elem);
            th_elem.classList.add('order-inactive');

            const index = Array.from(th_elem.parentNode.children).indexOf(th_elem);
            th_elem.addEventListener('click', (e) => {
                // document.querySelectorAll('th.order').forEach(elem => {
                //     elem.classList.remove('order-active');
                //     elem.classList.add('order-inactive');
                // });
                // th_elem.classList.remove('order-inactive');
                // th_elem.classList.add('order-active');

                // if (!asc) {
                //     th_elem.querySelector('span').innerHTML = '▲';
                // } else {
                //     th_elem.querySelector('span').innerHTML = '▼';
                // }
                const arr = Array.from(th_elem.closest("table").querySelectorAll('tbody tr'));
                arr.sort((a, b) => {
                    const a_val = a.children[index].innerText;
                    const b_val = b.children[index].innerText;
                    return (asc) ? a_val.localeCompare(b_val) : b_val.localeCompare(a_val);
                });
                arr.forEach(elem => {
                    th_elem.closest("table").querySelector("tbody").appendChild(elem);
                });
                asc = !asc;
            });
        });
    };

    return (
        <div id={styles.custom_table}>
            <div className="row">
                <div className={`col-12 py-3 text-center`}>
                    <Typography variant="subtitle2">
                        Filtros
                    </Typography>
                </div>
                <div className={`col-6 py-3 ${styles.tools}`}>
                    <div className={styles.select}>
                        <select
                            className=""
                            value={filterCompleted}
                            onChange={(e) => {
                                setFilterCompleted(e.target.value);
                                setCurrentPage(1);
                            }}
                        >
                            <option value="">Todas Reservas</option>
                            <option value="reservado">Reservadas</option>
                            <option value="cancelado">Cancelada</option>
                            <option value="retirado">Retiradas</option>
                        </select>
                    </div>
                    <div>
                        {filterCompleted && (
                            <CancelIcon onClick={resetFilter} />
                        )}
                        {!filterCompleted && (
                            <ArrowDropDown />
                        )}
                    </div>
                </div>
                <div className="col-6 py-3">
                    <div className="input-group input-group-dynamic">
                        <input
                            className="form-control"
                            type="text" id="search_reserve"
                            value={searchTerm}
                            onChange={(e) => {
                                setSearchTerm(e.target.value);
                                setCurrentPage(1);
                            }}
                            placeholder="Buscar Nombre..." />
                        <span className="input-group-text">
                            <Search style={{ color: grey[500] }} />
                        </span>
                    </div>
                </div>
                <div className='col-12'>
                    {!!onLoading && (
                        <LinearProgress />
                    )}
                </div>
            </div>

            <table id="table_reserve" width="100%" className="table responsive">
                <thead>
                    <tr>
                        <th></th>
                        <th className="order">Nombre</th>
                        <th className="order">Status</th>
                        <th data-sort="">Variedad</th>
                        <th data-sort="">Reserva</th>
                        <th data-sort="">Fecha de Retirada</th>
                    </tr>
                </thead>
                {/* {console.log(todosData)} */}
                <tbody>
                    {todosData.map(((item, idx) => {
                        return (
                            <tr key={idx}>
                                <td>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ paddingRight: '10px' }} className="btn_action" onClick={() => onDownReserve(item)}>
                                            <CheckCircleIcon style={{ color: item.status == 'reservado' && !onLoading ? green[500] : grey[500], cursor: item.status == 'reservado' ? 'pointer' : 'not-allowed' }} />
                                        </div>
                                        <div style={{ paddingRight: '10px' }} className="btn_action" onClick={() => onCancelReserve(item)}>
                                            <CancelIcon style={{ color: item.status == 'reservado' && !onLoading ? red[500] : grey[500], cursor: item.status == 'reservado' ? 'pointer' : 'not-allowed' }} />
                                        </div>
                                        <div style={{ paddingRight: '10px' }} className="btn_action" onClick={() => onDeleteReserve(item)}>
                                            <DeleteForever style={{ color: item.status != 'retirado' && !onLoading ? orange[500] : grey[500], cursor: item.status != 'retirado' ? 'pointer' : 'not-allowed' }} />
                                        </div>
                                    </div>
                                </td>
                                <td>{item.nome}</td>
                                <td>{
                                    item.status === 'reservado' ? (
                                        <Chip
                                            label="Aguardando"
                                            color="default" />
                                    ) : item.status === 'retirado' ? (
                                        <Chip
                                            label="Retirado"
                                            color="primary" />
                                    ) : (
                                        <Chip
                                            label="Cancelado"
                                            color="secondary" />
                                    )}</td>
                                <td style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                                    {item.products && item.products.map(product => {
                                        if (product.qtde > 0) {
                                            return (
                                                <div
                                                    key={product.id}
                                                    style={{ borderStyle: 'solid', borderRadius: 20, borderWidth: 1, borderColor: product.color, marginInline: 2, paddingInline: 5, fontSize: 11, color: product.color }}>
                                                    {`${product.qtde} - ${product.text}`}
                                                </div>
                                            );
                                        }
                                    })}
                                </td>
                                <td>{moment.unix(item.horacadastro.seconds).format("DD/MM/YYYY HH:mm")}</td>
                                <td>{moment.unix(item.dataRetirar.seconds).format("DD/MM/YYYY HH:mm")}</td>
                            </tr>
                        );
                    }
                    ))}
                </tbody>
            </table>

            <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={rows.length}
                pageSize={todosPerPage}
                onPageChange={page => paginate(page)}
            />

        </div>
    );
};

export default TableScreen;