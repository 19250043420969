import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import { scannersListItems, mainListItems, secondaryListItems } from '../listItems';
import { logoutUser } from "../../../actions";
import useStyles from '../Styles';
import logo from '../../../img/reservarcannabis-logo_preto.png';
import AddToHomescreen from 'react-add-to-homescreen';
import { ListSubheader } from '@material-ui/core';
import { db, myFirebase } from '../../../firebase/firebase';

export function MenuAppBar(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const openMenu = Boolean(anchorEl);
  const farmaciaID = myFirebase.auth().currentUser.uid;
  const [data, setData] = useState(null);

  useEffect(() => {
    db.collection('usuarios').doc(farmaciaID)
      .onSnapshot((docRef) => {
        if (docRef.exists) {
          const result = docRef.data();
          setData(result);
        }
      });
  }, []);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  function handleAddToHomescreenClick() {
    alert(`
    1. Open Share menu
    2. Tap on "Add to Home Screen" button`);
  };

  return (
    <div className={classes.root}>

      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography  variant="h6" color="inherit" noWrap className={classes.title}>
            <a title="Dashboard" href={'/Dashboard'}><img alt='Ir para dashboard' style={{ width: '130px' }} src={logo} /></a>
          </Typography>
          <Typography align={'right'} style={{fontSize:'8px'}}>
            {data && data.nome}
          </Typography>

          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={openMenu}
            onClose={handleClose}
          >
            <MenuItem component={Link} to={'/MeusDados'} onClick={handleClose}>
              Mis Datos
            </MenuItem>
            <MenuItem to={'/login'} onClick={props.Deslogar}>Salir</MenuItem>
          </Menu>

        </Toolbar>
      </AppBar>

      <Drawer
        variant="temporary"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
        ModalProps={{ onClose: () => setOpen(!open) }}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
        <Divider />
        <List
          subheader={
            <ListSubheader component="div" color='primary' id="nested-list-subheader">
              Acciónes
            </ListSubheader>
          }
        >{scannersListItems}</List>
        <Divider />
        <List
          subheader={
            <ListSubheader component="div" color='default' id="nested-list-subheader">
              En desarollo
            </ListSubheader>
          }
        >{secondaryListItems}</List>
        <Divider />
      </Drawer>
    </div>
  );


}

function mapStateToProps(state) {
  return {
    isLoggingOut: state.auth.isLoggingOut,
    logoutError: state.auth.logoutError
  };
}

const mapDispatchToProps = dispatch => ({
  Deslogar() {
    dispatch(logoutUser());
  },
});



export default connect(mapStateToProps, mapDispatchToProps)(MenuAppBar);
