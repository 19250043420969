import React from 'react';
import { analytics } from "../firebase/firebase";
import Footer from './parts/footer';
import Header from './parts/header';
import '../css/material-kit.css?v=2020';
import styled from '@emotion/styled';

import QRGenerator from './QRGenerator';
import html2canvas from 'html2canvas';

import '../css/styles.css';

function QRCodeReserva(props) {
    console.log('PROPS => ', props);

    const { location } = props;
    const { state } = location;
    const iframe = location.state.iframe || false;

    function downloadURI(uri, name) {
        var link = document.createElement("a");
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
    }

    function printToFile(div) {
        console.log(document.querySelector("#qrcode"));
        analytics.logEvent('download_qrcode_' + state.farmaciaNome);
        html2canvas(document.querySelector("#pdf-get"), {
            width: 600,
            height: 900
        }).then(canvas => {
            // console.log(canvas);
            // canvas.setAttribute('width','600')
            var myImage = canvas.toDataURL("image/png");
            downloadURI(myImage, "reserva_" + state.reservaID + ".png");
        });
    }


    const ContainerProducts = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

    const ItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    padding: 5px;
`;
    const ItemText = styled.span`
    color: ${props => props.color};
`;

    const ProductComponent = ({ state }) => {
        const filteredProducts = state.products ? state.products.filter(item => item.qtde > 0) : [];
        return (
            <ContainerProducts>
                {filteredProducts.map((item, idx) => (
                    <ItemContainer key={idx}>
                        <ItemText color={item.color}>{`${item.qtde} paquete(s) de ${item.text}`}</ItemText>
                    </ItemContainer>
                ))}
            </ContainerProducts>
        );
    };

    return (
        <div>
            {!iframe && <Header />}
            <div className="page- header-filter" style={{ backgroundImage: `url('/img/chamada-teste-1.jpg'` }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 ml-auto mr-auto farmacia">

                            <div className="card">
                                <div className="card-header-3 text-center ">
                                    <p className="dias">Presentá este QRCode en la farmacia</p>
                                    <br />
                                </div>
                                <div className="card-header-description text-center">
                                    <div id="pdf-get">
                                        <div style={{ padding: 10 }}>
                                            <div style={{ width: '100%' }}>
                                                <QRGenerator value={state.reservaID} />
                                            </div>
                                            <br></br>
                                            <strong style={{ color: '#000000' }}>
                                                {state.nome}
                                            </strong>
                                            <br></br>

                                            <p style={{ color: '#0d95de' }}>{state.farmaciaNome}</p>

                                            <p style={{ color: '#000000', fontWeight: 'bold' }}>Información sobre la Reserva</p>
                                            <p style={{ color: '#0d95de', fontWeight: 'bold' }}>
                                                {state.horaInicioFim}
                                                <br />
                                                {state.observacao}
                                            </p>

                                            <p style={{ color: '#000', fontWeight: 'bold' }}>Variedades selecionadas para el dia</p>

                                            <ProductComponent state={state} />
                                            <p style={{ color: '#000000', fontWeight: 'bolder', marginTop: 3 }}><span role="img" aria-label='Warning'>⚠️</span> La reserva no da prioridad en la fila. </p>
                                            <p style={{ color: '#010101' }}>La fecha de retirada puede cambiar, revise siempre su correo eletrónico.</p>
                                            <p style={{ color: '#9b9b9b', fontStyle: 'italic' }}>Antes de venir a la farmacia baje la reserva y traiga cambio para facilitar la atención.</p>

                                            <br></br>
                                            <img src="/img/reservarcannabis-logo_preto.png" alt="Reserve Cannabis" width="130" />
                                        </div>
                                    </div>
                                    <br></br>
                                    <button className="btn btn-block btn-primary" onClick={printToFile}>Bajar Reserva</button>
                                    <br></br>
                                </div>

                                <button className="btn btn-block btn" data-toggle="modal" data-target="#direccion">
                                    <i className="material-icons">directions</i> Donde Queda?
                                    <div className="ripple-container"></div>
                                </button>
                                <button className="btn btn-block btn-info aviso" data-toggle="modal" data-target="#reglas">
                                    <i className="material-icons">library_books</i> Reglas
                                    <div className="ripple-container"></div>
                                </button>
                                <div className="card-header-primary text-center hogar">
                                    <strong>Aviso: Verifique la ubicación ya que la reserva es únicamente para esta farmacia!</strong>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="modal fade" id="direccion" tabIndex="-1" role="dialog" style={{ display: 'none' }} aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h2 className="modal-title">Donde queda?</h2>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <i className="material-icons">clear</i>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>Esta farmacia esta ubicada en {state.farmaciaRua} - {state.farmaciaCidade}<br></br>Fijate si estas cerca de la farmacia donde vas a reservar.</p>
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe title={state.farmaciaNome} width="350" height="250" src={"https://maps.google.com/maps?q=" + state.farmaciaNome +
                                        ", " + state.farmaciaRua + ", "
                                        + state.farmaciaCidade + "&t=&z=13&ie=UTF8&iwloc=&output=embed"}
                                        frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" />

                                </div>
                                <p></p>
                                Auspiciado por <a href="https://bit.ly/mapacannabis" target="_blank" rel="noopener noreferrer"><img src="https://prfvr.com/wp-content/uploads/2016/02/thumb_budmaps.png" width="120" alt="mapa cannabis" /></a>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-link"><div className="ripple-container"></div></button>
                                <button type="button" className="btn btn-danger btn-link" data-dismiss="modal">Cerrar
                                    <div className="ripple-container">
                                        <div className="ripple-decorator ripple-on ripple-out" style={{ left: '35.4821px', top: '21.05px', backgroundColor: 'rgb(244, 67, 54)', transform: 'scale(8.50976)' }}></div>
                                        <div className="ripple-decorator ripple-on ripple-out" style={{ left: '35.4821px', top: '21.05px', backgroundColor: 'rgb(244, 67, 54)', transform: 'scale(8.50976)' }}></div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="reglas" tabIndex="1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h2 className="modal-title">Reglas de la Reserva</h2>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <i className="material-icons">clear</i>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>
                                    <strong>Cuando haces una reserva significa que te involucraste y tomaste una decisión!</strong>
                                </p>
                                <p><strong>Reglas&nbsp; de Suspensión</strong> (Reserva sin retirar)<br></br>
                                    Si hiciste una reserva,<strong> no te olvides de venir hasta el plazo para retirar!<br></br>
                                    </strong>Esta aplicación fue hecha para las personas que <strong>necesitan y quieren</strong>,<br></br>
                                    si no logras venir <a href="https://reservarcannabis.com/cancelar-reserva/">haga el cancelamento de la reserva para evitar ser suspendido.</a>
                                </p>
                                <ul>
                                    <li><strong>1 reserva sin venir;</strong> Suspendido una semana;</li>
                                    <li><strong>2 reserva sin venir;</strong> Suspendido un mês;</li>
                                </ul>
                                <p><strong>Usamos QRCODE!<br></br>
                                </strong>Si tienes el ticket con la información de la reserva, significa que su reservación está garantizada! Si te quedaste sin bateria también podes retirar con tu nombre completo.
                                </p>
                                <p><strong>Convertir o cambiar reservas</strong><br></br>
                                    Las reservas no pueden ser intercambiados por dinero real.
                                    Las reservas para cannabis, por lo general, son intransferibles a otras personas y no se pueden cambiar por nada.
                                    Si se descubre estos casos pueden ser penados por ley.
                                    Fijate si estas cerca de la farmacia donde vas a reservar;
                                    Si no vas a venir a retirar, no haga su reserva; La reserva online no da prioridad en la fila;
                                    Cuando haces la reserva, vos aceptas las reglas establecidas aquí.
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger btn-link" data-dismiss="modal">Cerrar
                                    <div className="ripple-container"></div>
                                    <div className="ripple-decorator ripple-on ripple-out" style={{ left: '35.4821px', top: '21.05px', backgroundColor: 'rgb(244, 67, 54)', transform: 'scale(8.50976)' }}>
                                    </div>
                                    <div className="ripple-decorator ripple-on ripple-out" style={{ left: '35.4821px', top: '21.05px', backgroundColor: 'rgb(244, 67, 54)', transform: 'scale(8.50976)' }}>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {!iframe && <Footer />}
        </div>
    );

}

export default QRCodeReserva;