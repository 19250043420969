import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, Tooltip, CartesianGrid, BarChart, Bar, Cell, Pie, PieChart } from 'recharts';
import Title from './Title';
import { Legend } from '@devexpress/dx-react-chart';
import { Box, Grid, Paper } from '@mui/material';

const Chart = ({ data }) => {
  const theme = useTheme();

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Paper elevation={2} style={{ padding: 10 }}>
          <p style={{ color: payload[0].fill }}>{`${label} : ${payload[0].value}`}</p>
        </Paper>
      );
    }
    return null;
  };



  const ChartCustom = ({ data }) => {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          height={300}
          margin={{
            top: 25,
            right: 30,
            left: 30,
            bottom: 5,
          }}
          barSize={20}
        >
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis dataKey="text" fontSize={'.5em'}
            width={10} />
          <YAxis fontSize={'.6rem'}
            width={10} />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey="qtde" label={{ position: 'top', fontSize:12, fontWeight:600 }} radius={0} width={2}>
            {data && data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  };

  const ChartCustom1 = ({ data }) => {
    return (

      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <XAxis dataKey="text" stroke={theme.palette.text.secondary} />
          <YAxis stroke={theme.palette.text.secondary}>
            <Label
              angle={270}
              position="left"
              style={{ textAnchor: 'middle', fill: '#059033' }}
            >
              Reservas
            </Label>
          </YAxis>
          <Line type="monotone" dataKey="qtde" stroke="#059033" dot={true} />
          <Tooltip />
        </LineChart>
      </ResponsiveContainer>

    );
  };

  const ChartCustom2 = ({ data }) => {
    return (
      <PieChart width={800} height={400}>
        <Pie
          data={data}
          cx={120}
          cy={200}
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    );
  };
  return <ChartCustom data={data} />;
  return <ChartCustom1 data={data} />;
  return <ChartCustom2 data={data} />;


  return <ChartCustom data={data} />;
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} style={{ width: '100%', minHeight: 200, padding: 20 }}>
          <ChartCustom data={data.reservedNow} />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} md={4} lg={6} style={{ width: '100%', minHeight: 200, padding: 20 }}>
          <ChartCustom data={data.retiredNow} />
        </Grid>
        <Grid item xs={12} sm={8} md={4} lg={6} style={{ width: '100%', minHeight: 200, padding: 20 }}>
          <ChartCustom data={data.cancelledNow} />
        </Grid>
      </Grid>
    </>
  );
};

export default Chart;