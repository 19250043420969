import React from 'react';
import { Link } from "react-router-dom";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import MailIcon from '@material-ui/icons/Mail';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { FaBarcode, FaQrcode, FaCalendarAlt } from "react-icons/fa";

const main = [
  {
    title: "Home",
    label: "Home",
    text: "Inicio c/ Reportes",
    link: "/Dashboard",
    icon: <DashboardIcon />
  },
  {
    title: "Calendário",
    label: "Calendário c/ Reservas",
    text: "Calendário c/ Reservas",
    link: "/Calendario",
    icon: <FaCalendarAlt size={24} />
  },
  {
    title: "Enviar Aviso p/ Mail",
    label: "Enviar Aviso p/ Mail",
    text: "Enviar Aviso /p  Mail",
    link: "/avisos",
    icon: <MailIcon />
  },
  // {
  //   title: "Productos",
  //   label: "Productos",
  //   text: "Productos",
  //   link: "/Products",
  //   icon: <FaQrcode size={24} />
  // },
  // {
  //   title: "BarCode",
  //   label: "Escanear BarCode",
  //   text: "Lector/Scanner",
  //   link: "/BarCodeReader",
  //   icon: <FaBarcode size={24} />
  // },



];


export const mainListItems = (
  <div>
    {main.map((item, idx) => (
      <Tooltip key={idx} title={item.title} aria-label={item.label}>
        <ListItem component={Link} to={item.link} button>
          <ListItemIcon>
            {item.icon}
          </ListItemIcon>
          <ListItemText primary={item.text} />
        </ListItem>
      </Tooltip>
    ))}
  </div>
);

const secondary = [
  {
    title: "Reportes",
    label: "Reportes",
    text: "Reportes",
    link: "#",
    icon: <DescriptionOutlinedIcon />
  },
  {
    title: "Enviar Promoción",
    label: "Enviar Promoción",
    text: "Enviar Promoción",
    link: "#",
    icon: <ShoppingBasketIcon />
  },
];

export const secondaryListItems = (
  <div>
    {secondary.map((item, idx) => (
      <Tooltip key={idx} title={item.title} aria-label={item.label}>
        <ListItem component={Link} to={item.link} button>
          <ListItemIcon>
            {item.icon}
          </ListItemIcon>
          <ListItemText primary={item.text} />
        </ListItem>
      </Tooltip>
    ))}
  </div>
);

const scanners = [
  {
    title: "QRCode",
    label: "Escanerar c/ Movíl",
    text: "Escanear c/ Movil",
    link: "/QrCodeScreen",
    icon: <FaQrcode size={24} />
  },
  {
    title: "BarCode",
    label: "Escanear c/ Compu",
    text: "Escanear c/ Computadora",
    link: "/BarCodeScreen",
    icon: <FaBarcode size={24} />
  },
  {
    title: "Listado de Reservas",
    label: "Listado de Reservas",
    text: "Listado de Reservas",
    link: "/ListaReservas",
    icon: <PeopleIcon />
  },
];
export const scannersListItems = (
  <div>
    {scanners.map((item, idx) => (
      <Tooltip key={idx} title={item.title} aria-label={item.label}>
        <ListItem component={Link} to={item.link} button>
          <ListItemIcon>
            {item.icon}
          </ListItemIcon>
          <ListItemText primary={item.text} />
        </ListItem>
      </Tooltip>
    ))}
  </div>
);