import React from 'react';

const Direccion = (props) => {

    return (
        <div className="modal fade" id="direccion" tabIndex="-1" role="dialog" style={{ display: 'none' }} aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2 className="modal-title">Donde queda?</h2>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <i className="material-icons">clear</i>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>Esta farmacia esta ubicada en {props.farmacia.farmaciaRua} - {props.farmacia.farmaciaCidade}<br></br>Fijate si estas cerca de la farmacia donde vas a reservar.</p>
                        <div className="embed-responsive embed-responsive-16by9">
                            <iframe title={props.farmacia.farmaciaNome} width="350" height="250" src={"https://maps.google.com/maps?q=" + props.farmacia.farmaciaNome +
                                ", " + props.farmacia.farmaciaRua + ", "
                                + props.farmacia.farmaciaCidade + "&t=&z=13&ie=UTF8&iwloc=&output=embed"}
                                frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" />

                        </div>
                        <p></p>
                        Auspiciado por <a href="https://bit.ly/mapacannabis" target="_blank"><img src="https://prfvr.com/wp-content/uploads/2016/02/thumb_budmaps.png" width="120" /></a>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-link"><div className="ripple-container"></div></button>
                        <button type="button" className="btn btn-danger btn-link" data-dismiss="modal">Cerrar<div className="ripple-container"><div className="ripple-decorator ripple-on ripple-out" style={{ left: '35.4821px', top: '21.05px', backgroundColor: 'rgb(244, 67, 54)', transform: 'scale(8.50976)' }}></div><div className="ripple-decorator ripple-on ripple-out" style={{ left: '35.4821px', top: '21.05px', backgroundColor: 'rgb(244, 67, 54)', transform: 'scale(8.50976)' }}></div></div></button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Direccion;