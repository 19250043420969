import React from 'react';

const Header = (props) => {
   return (
      <div>
         <nav className="navbar navbar-default navbar-expand-lg" role="navigation-demo">
            <div className="container">
               <div className="navbar-translate">
                  <a className="navbar-brand" href="/"><img src="/img/reservarcannabis-logo_preto.png" alt="Reserve Cannabis" width="130" /></a>
                  <button id="navToggle" className="navbar-toggler" type="button" data-toggle="collapse" aria-expanded="false" aria-label="Toggle navigation">
                     <span className="sr-only">Toggle navigation</span>
                     <span className="navbar-toggler-icon"></span>
                     <span className="navbar-toggler-icon"></span>
                     <span className="navbar-toggler-icon"></span>
                  </button>
               </div>
               <div className="collapse navbar-collapse">
                  <ul className="navbar-nav ml-auto">
                     <a className="nav-item nav-link sr-only" id="home" href="/">Home <span className="sr-only">(current)</span></a>
                     <li className="nav-item demo-adsila">
                        <div className="menu menu--adsila">
                           <a className="menu__item" href="/#como">
                              <span className="menu__item-name">Como reservar</span>
                           </a>
                        </div>
                     </li>
                     <div className="menu menu--adsila">
                        <a className="menu__item" href="/cancelar-reserva">
                           <span className="menu__item-name">Cancelar reserva</span>
                        </a>
                     </div>
                     <li className="nav-item">
                        <a href="/#numeros" className="menu__item btn btn-rose btn-raised btn-round " data-toggle="">
                           Números Disponibles
                        </a>
                     </li>
                     <li className="nav-item sr-only sr-only-focusable">
                        <a href="https://clubescannabicos.com">Clubes Canabicos</a>
                     </li>
                  </ul>
               </div>
            </div>
         </nav>
      </div>
   );
};

export default (Header);