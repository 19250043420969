import React, { useEffect, useState } from 'react';
import { db } from '../firebase/firebase';
import Cards from '../components/Card';
// import sha512 from 'js-sha512'

export const LoadingComponent = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      {/* <Image style={{ width: 200, height: 100, marginVertical:'100%' }} source={require('../img/loading-webview.gif')} /> */}
      <img style={{ marginTop: 80 }} width="400px" alt="logo" src={require('../img/loading.gif')} /><br></br>Espere un momento, cargando las fechas disponibles.
      {/* <p style={{  paddingBottom: 50 }}>Aguarde o carregamento...</p> */}
      {/* <img width="400px" alt="logo" src="https://via.placeholder.com/250/EEEEEE/808080?text=Not+found!" /> */}
    </div>
  );
};

const Iframe = () => {
  const [show, setShow] = useState(false);
  const [params, setParams] = useState({});
  const [farmacia, setFarmacia] = useState({});
  const [agendamento, setAgendamento] = useState({});
  const [scheduledFixed, setScheduledFixed] = useState();

  useEffect(() => {
    let unmount = true;
    if (unmount) {
      const fetchData = async () => {
        const urlParams = await getAllUrlParams(params);
        const farmaciaData = await getFarmaciaData(urlParams);
        if (farmaciaData)
          await getAgendamentosData(farmaciaData);
        return () => {
          unmount = false;
        };
      };
      fetchData();
    }
  }, [params]);


  const getFarmaciaData = async (params) => {
    let obj = {};
    try {
      const farmaciaRef = db.collection('usuarios');
      const doc = await farmaciaRef.doc(params.id).get();
      if (!doc.exists) {
        return;
      } else {
        obj = {
          farmaciaID: doc.id,
          farmaciaNome: doc.data().nome,
          farmaciaLogo: doc.data().logo,
          farmaciaRua: doc.data().endereco.rua,
          farmaciaCidade: doc.data().endereco.cidade,
          farmaciaBairro: doc.data().endereco.bairro,
          farmaciaStatus: doc.data().mostrar
        };
      }
    } catch (error) {
      console.log('ERROR!', error);
    }
    return obj;
  };

  const getAgendamentosData = async (params) => {
    try {
      await db.collection("usuarios")
        .doc(params.farmaciaID)
        .collection("agendamentos")
        .where("dataFimRet", ">=", new Date())
        .orderBy("dataFimRet", "asc")
        .onSnapshot(async (querySnapshot) => {
          let dados = [];
          let docs = querySnapshot.docs;
          for (let ag of docs) {
            let res = await getReservasID(ag.id);
            dados.push({
              _id: params.farmaciaID,
              dataInicioRet: ag.data().dataInicioRet,
              dataFimRet: ag.data().dataFimRet,
              reservaID: ag.id,
              reservas: res,
              numeros: parseInt(ag.data().numerosRes),
              dataIni: ("0" + (new Date(ag.data().startDate.seconds * 1000).getDate())).slice(-2) + "/" + ("0" + (new Date(ag.data().startDate.seconds * 1000).getMonth() + 1)).slice(-2)
                + "\n " + new Date(ag.data().startDate.seconds * 1000).toLocaleTimeString("es-ES", { hour: '2-digit', minute: '2-digit' }),
              horarioFim: ("0" + (new Date(ag.data().dataInicioRet.seconds * 1000).getDate())).slice(-2) + "/" + ("0" + (new Date(ag.data().dataInicioRet.seconds * 1000).getMonth() + 1)).slice(-2)
                + "\n " + new Date(ag.data().dataInicioRet.seconds * 1000).toLocaleTimeString("es-ES", { hour: '2-digit', minute: '2-digit' })
                + "/" + new Date(ag.data().dataFimRet.seconds * 1000).toLocaleTimeString("es-ES", { hour: '2-digit', minute: '2-digit' }),
              data: ag.data().startDate.toDate(),
              ativa: ag.data().resAtiva,
              products: ag.data().products,
              fixed: ag.data().fixed
            });
          }
          console.log('DADOS => ', dados);
          setAgendamento(dados);
          setFarmacia(params);
          setShow(true);
        });
    } catch (error) {
      console.log('ERROR => ', error);
    }
  };

  const getReservasID = async (id) => {

    let reservas = new Promise((resolve) => {
      db.collection('reservas')
        .where("reservaID", "==", id)
        .onSnapshot(async (querySnapshot) => {
          let docs = querySnapshot.docs;
          let can = 0;
          let res = 0;
          let ret = 0;
          docs.forEach(function (d) {
            if (d.data().status === 'reservado')
              res++;
            if (d.data().status === 'cancelado')
              can++;
            if (d.data().status === 'retirado')
              ret++;
          });
          resolve(res + ret);
        });
    });
    reservas.then(function (result) {
      return result;

    });
    return reservas;
  };

  const getAllUrlParams = async () => {
    var queryString = window.location.search.slice(1);
    var obj = {};

    if (queryString) {
      queryString = queryString.split('#')[0];
      var arr = queryString.split('&');

      for (var i = 0; i < arr.length; i++) {
        var a = arr[i].split('=');
        var paramName = a[0];
        var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

        if (paramName.match(/\[(\d+)?\]$/)) {
          var key = paramName.replace(/\[(\d+)?\]/, '');
          if (!obj[key]) obj[key] = [];

          if (paramName.match(/\[\d+\]$/)) {
            var index = /\[(\d+)\]/.exec(paramName)[1];
            obj[key][index] = paramValue;
          } else {
            obj[key].push(paramValue);
          }
        } else {
          if (!obj[paramName]) {
            obj[paramName] = paramValue;
          } else if (obj[paramName] && typeof obj[paramName] === 'string') {
            obj[paramName] = [obj[paramName]];
            obj[paramName].push(paramValue);
          } else {
            obj[paramName].push(paramValue);
          }
        }
      }
    }

    return obj;
  };

  if (!farmacia.farmaciaStatus) {
    return (<p>La farmacia solicitada no se encuentra en funcionamiento en estos momentos, disculpe las molestias.</p>);
  }

  return show && farmacia ? (<Cards farm={farmacia} agend={agendamento} />) : (<LoadingComponent />);
  

};

export default Iframe;