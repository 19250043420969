import { makeStyles } from '@material-ui/styles';
import { Button, Container } from '@mui/material';
import React, { useState } from 'react';
import { ChromePicker } from 'react-color';

const useStyles = makeStyles({
    container: {
        paddingTop: 10,
        paddingBottom: 10
    },
    picker: {
        width: '100% !important',
    },
    cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
        with: '100%'
    },
    popover: {
        with: '100%'
    }
});

const ProductColorPicker = ({ color, onChange }) => {
    const classes = useStyles();
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker);
    };

    const handleClose = () => {
        setDisplayColorPicker(false);
    };


    const popover = {
        // position: 'absolute',
        // zIndex: '2',

    };
    return (
        <div className={classes.container}>
            <Button style={{ backgroundColor: color }} variant="contained" fullWidth onClick={handleClick}>Selecione una cor</Button>
            {displayColorPicker ? <div className={classes.popover}>
                <div className={classes.cover} onClick={handleClose} />
                <ChromePicker
                    className={classes.picker}
                    onChange={onChange}
                    color={color} />
            </div> : null}
        </div>
    );

};

export default ProductColorPicker;